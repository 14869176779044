import React from "react";
import { StAbout, StArrow, StSelect } from "../../aboutus/StAbout";
import arrow from "../../../img/icon/arrow-right-long-white.png";
import { styled } from "styled-components";
import { StNoshine } from "../StProduct";
import { useNavigate } from "react-router-dom";
import Common from "../../../components/product/Common";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../../recoil/transferAtom";

import check from "../../../img/products/techapply/data2/check-solid.svg";
import { transferLanguage_head } from "../../../components/SelectBox";

export const transferLanguage_multi = [
  [
    "다목적 복합 필터 기술",
    "다양한 소재의 필터에 WELTOUCH®를 적용(Coating)한 것을 WELTOUCH® Coating Filter라고 합니다.",
    "적용된 필터는 공기중에 떠다니는 바이러스 및 세균을 분해·제거하는 성능이 생기며, Filter의 기본적 성능 (탈취 및 미세먼지 제거)을 향상시켜 주는 기술입니다.",
    "다목적 복합 필터 기술 문의하기",
    "WELTOUCH® Coating Filter의 역할",
    "Pre filter",
    "큰먼지 제거",
    "HEPA filter",
    "미세먼지 제거",
    "活性炭",
    "악취제거",
    "UV-A[살균력 無]",
    "UV-C[살균력 有(O3 발생 위험)]",
    "Plasma 전기 방전",
    "WELTOUCH® coating filter 하나로 대체 가능",
    "WELTOUCH® Coating Filter(HEPA filter + WELTOUCH®",
    "[Virus 류(ex. COVID-19), Bacteria, VOCs, HCHO, Odor 제거]",
    "[O3 우려 없음]",
    "Filter 작동 원리 ",
    "바이러스 입자",
    "세균 입자", //20
    "미세먼지 입자",
    "라돈 입자",
    "악취, VOCs 등 유해물질",
    "바람의 방향",
    "사멸된 바이러스 입자",
    "분해된 세균 입자",
    "중화된 라돈",
    "오염된 공기",
    "깨끗한 공기",
    "특징",
    "기계적 구조 변경 없이 손쉬운 적용",
    "제한 없는 강력한 효과",
    "우수한 코팅력",
    "보장된 안전성",
    "용도",
    "프리 Filter, 미디엄 Filter, 활성탄 Filter, 세라믹 Filter, 허니컴 Filter 등 모든 종류의 Filter",
    "실제 공기질 비교 성적",
    "대전 D 센터에서 오직 Filter만 실제 적용 한 결과 실내 공기질 관리 규제 수치에 비해 WELTOUCH® Coating Filter를 설치한 이후 미세먼지, 포름알데히드 등 유해물질의 수치가 현저히 낮아졌습니다.",
    "측정항목",
    "기준수치", //40
    "WELTOUCH® Coating filter 설치 후",
    "미세먼지",
    "초미세먼지",
    "포름알데히드",
    "총부유세균",
    "일산화탄소",
    "이하",
    "미세먼지 제거 Test",
    "PP Film으로 막음(닫힌계)",
    "미세먼지 농도 측정", //50
    "무처리 Filter",
    "미세먼지 발생",
    "무처리 Filter 시험 모식도",
    "WELTOUCH® Coating Filter 시험 모식도",
    "입자크기",
    "무처리",
    "WELTOUCH® Coating Filter",
    "제거 효율",
    "WELTOUCH® Coating Filter의 미세먼지 제거 Test 결과 무처리 Filter 대비 Filter의 제거 효율이 큰폭으로 상승 하였습니다.",
    "부유낙하균 제거 Test", //60
    "시험 chamber",
    "MRSA에 의한 항균 시험",
    "3시간 가동 후 세균 감소율",
    "초기농도",
    "가동 후 농도",
    "폐렴균에 의한 항균 시험",
    "MRSA균과 폐렴균을 상대로 WELTOUCH® Coating Filter의 항균 Test를 진행한 결과 3시간 가동 후 99.9%의 세균 감소율을 보였습니다.",
    "탈취 Test",
    "시험 항목",
    "탈취 시험", //70
    "시험 가스",
    "암모니아, 트리메틸아민",
    "결과",
    "암모니아",
    "100ppm → 1ppm 이하",
    "트리메틸아민",
    "30ppm → 1ppm 이하",
    "시험 기관",
    "한국화학융합시험연구원(KTR)",
    "원단에 WELTOUCH®를 코팅하여 Test한 결과 악취의 원인물질인 암모니아나 트리메틸아민이 한시간 안에 1ppm 이하로 감소하는 결과를 보였습니다.",
  ],
  [
    "Multi-purpose composite filter",
    "WELTOUCH® Coating Filter is produced by applying (coating) WELTOUCH® to filters made with various materials.",
    "The applied filter decomposes, eliminates viruses and germs floating in the air, and improves the basic functions of filters (deodorization and elimination of particulate matter).",
    "Inquire about Multi-Purpose Filter Technology",
    "Roles of WELTOUCH® Coating Filter",
    "Pre filter",
    "Elimination of large dust",
    "HEPA filter",
    "Elimination of particulate matters",
    "Active carbon",
    "Elimination of offensive odors",
    "UV-A[no sterilizing ability]",
    "UV-C[with sterilizing ability(potential generation of O3)]",
    "Plasma electric discharge",
    "Replacement only by one WELTOUCH® coating filter",
    "WELTOUCH® Coating Filter(HEPA filter + WELTOUCH®)",
    "[Elimination of Viruses(ex. COVID-19), Bacteria, VOCs, HCHO, Odor]",
    "[No worries about O3]",
    "Operation Principles of Filter",
    "Virus particles",
    "Bacteria particles",
    "Particulate matters",
    "Radon particles",
    "Offensive odors, harmful substances including VOCs",
    "Wind direction",
    "Extinct virus particles",
    "Decomposed bacteria particles",
    "Neutralized Radon",
    "Polluted air",
    "Clean air",
    "Features",
    "Simple application without the change of mechanic structure",
    "Limitless, powerful effects",
    "Superior coating power",
    "Secured safety",
    "Applications",
    "All kinds of filters, including pre-filters, medium-filters, activated carbon filters, ceramic filters, and honeycomb filters",
    "Comparison of air quality",
    "The values of harmful substances, including particulate matter and formaldehyde, in Daejon D Center, were significantly reduced after the installation of a WELTOUCH® Coating Filter in accordance with indoor air quality control regulations.",
    "Measurement items",
    "Standard value",
    "After installation of WELTOUCH® Coating filter",
    "Particulater matters",
    "Ultrafine dust",
    "Formaldehyde",
    "Total floating bacteria",
    "Carbon monoxide",
    "Below",
    "Particulate matters elimination test",
    "Blocking with PP film (closed system)",
    "Concentration of particulate matters was measured",
    "Filter without treatment",
    "Generation of particulate matters",
    "Filter without Treatment test schematic diagram",
    "WELTOUCH® Coating Filter schematic diagram",
    "Particle size",
    "Filter without treatment",
    "WELTOUCH® Coating Filter",
    "Elimination efficiency",
    "In accordance with the test on the elimination of particulate matters by WELTOUCH® Coating Filter, the elimination efficiency of the WELTOUCH® Coating Filter was significantly improved compared to the filter without treatment.",
    "Test on the elimination of floating and falling bacteria",
    "Test chamber",
    "Anti-bacterial test on MRSA",
    "Reduction of germs after operation of 3 hours",
    "Beginning concentration",
    "Concentration after operation",
    "Anti-bacterial test on pneumococcus",
    "In accordance with the anti-bacterial test of WELTOUCH® Coating Filter on MRSA and pneumococcus, bacteria were reduced by 99.9% after operation for 3 hours. ",
    "Deodorization Test",
    "Test item",
    "Deodorization test",
    "Test gas",
    "Ammonia, Trimethylamine",
    "Results",
    "Ammonia",
    "100ppm → below 1ppm",
    "Trimethylamine",
    "30ppm → below 1ppm",
    "Test agency",
    "Korea Testing & Research Institute(KTR)",
    "In accordance with the anti-bacterial test of WELTOUCH® Coating Filter on MRSA and pneumococcus, bacteria were reduced by 99.9% after operation for 3 hours.",
  ],
  [
    "Teknologi filter multi fungsi",
    "Aplikasi (pelapisan) WELTOUCH® ke filter berbagai bahan disebut Filter pelapis WELTOUCH®.",
    "Filter yang diterapkan adalah teknologi yang menguraikan dan menghilangkan virus dan bakteri yang mengambang di udara, dan meningkatkan kinerja dasar filter (deodorisasi dan penghilangan debu halus).",
    "Pertanyaan tentang Teknologi filter multi fungsi",
    "Peran Filter Pelapis WELTOUCH®",
    "Pre filter",
    "Menghilangkan debu besar ",
    "HEPA filter",
    "Menghilangkan debu halus",
    "Karbon aktif",
    "Menghilangkan bau",
    "UV-A[Daya sterilisasi 0]",
    "UV-C[mempertahankan Daya sterilisasi (Risiko Terjadinya O3)]",
    "Plasma pelepasan listrik",
    "Dapat diganti dengan satu Filter Pelapis WELTOUCH®",
    "Filter Pelapis WELTOUCH®(HEPA filter + WELTOUCH®",
    "[Virus (mis. COVID-19), Bakteri, VOC, HCHO, Penghilang Bau]",
    "[Tidak ada kekhawatiran terjadinya O3]",
    "Cara kerja filter ",
    "Partikel Virus",
    "Partikel Bakteri",
    "Partikel Debu Halus",
    "Partikel Radon",
    "Bau, VOC, dll.",
    "Arah Angin",
    "Partikel Virus Mati",
    "Partikel Bakteri Terurai",
    "Radon Netral",
    "Udara Terkontaminasi",
    "Udara Bersih",
    "Fitur",
    "Aplikasi mudah tanpa perubahan struktural mekanis",
    "Efek yang kuat dan tidak terbatas",
    "Kekuatan pelapisan yang sangat baik",
    "Keamanan terjamin",
    "Tujuan",
    "Semua jenis filter seperti Filter gratis, Filter medium, Filter karbon aktif, Filter Keramik, Filter sarang lebah",
    "Hasil Perbandingan Kualitas Udara Sebenarnya",
    "Karena hanya menerapkan filter di Daejeon D Center, kadar zat berbahaya seperti debu halus dan formaldehida telah menurun secara signifikan sejak Filter Pelapis WELTOUCH® dipasang dibandingkan dengan peraturan kontrol kualitas udara dalam ruangan.",
    "Item pengukuran",
    "nilai yang ada ",
    "Setelah memasang Filter Pelapis WELTOUCH®",
    "Debu halus",
    "debu sangat halus",
    "formaldehida",
    "total bakteri di udara",
    "karbon monoksida",
    "kurang dari",
    "Tes penghilangan debu halus",
    "Diblokir dengan Film PP (sistem tertutup)",
    "Pengukuran konsentrasi debu halus",
    "Filter Tanpa Pengolahan",
    "Pembentukan debu halus",
    "Filter Tanpa Pengolahan Skema uji",
    "Filter Pelapis WELTOUCH® Skema uji",
    "Ukuran partikel",
    "Filter Tanpa Pengolahan r",
    "Filter Pelapis WELTOUCH®",
    "Efisiensi penghilangan",
    "Sebagai hasil dari uji penghapusan debu halus Filter Pelapisan WELTOUCH®, efisiensi penghapusan filter telah meningkat secara signifikan dibandingkan dengan filter yang tidak diobati.",
    "Tes penghapusan bakteri di udara",
    "Ruang uji",
    "Uji antibakteri pada MRSA",
    "Laju reduksi bakteri setelah 3 jam operasi",
    "Konsentrasi awal",
    "konsentrasi pasca operasi",
    "Uji antibakteri pada pneumonia",
    "Sebagai hasil dari uji antibakteri WELTOUCH® Coating Filter pada bakteri MRSA dan pneumonia, tingkat pengurangan bakteri adalah 99,9% setelah 3 jam operasi.",
    "Uji Deodorisasi",
    "Item tes",
    "Tes deodorisasi",
    "Gas uji",
    "Ammonia, trimetilamina",
    "hasil",
    "Amonia",
    "kurang dari 100ppm → 1ppm",
    "trimetilamina",
    "kurang dari 30ppm → 1ppm",
    "Lembaga pengujian",
    "Korea testing & Research Institue(KTR)",
    "Hasil pengujian dengan melapisi kain dengan WELTOUCH®, amonia atau trimetilamina, yang merupakan penyebab bau, berkurang hingga kurang dari 1 ppm dalam waktu satu jam.",
  ],
  [
    "Tecnología de filtro compuesto versátil",
    "La aplicación (recubrimiento) de WELTOUCH® a filtros de diversos materiales se denomina filtro de recubrimiento WELTOUCH®. El filtro aplicado genera la capacidad",
    "de descomponer y eliminar virus y bacterias que flotan en el aire, y es una tecnología que mejora el rendimiento básico del filtro (desodorización y eliminación de polvo fino).",
    "Contacto",
    "Función del filtro de revestimiento WELTOUCH®",
    "Pre filtro",
    "Eliminación de polvo de gran tamaño ",
    "HEPA filtro",
    "Eliminación de polvo fino",
    "carbón activado",
    "Eliminación de olores",
    "UV-A[Sin poder de esterilización]",
    "UV-C[Con poder de esterilización (riesgo de generación de O3)]",
    "Plasma Descarga eléctrica",
    "Se puede reemplazar solo con un solo WELTOUCH® filtro de revestimiento",
    "Filtro de revestimiento WELTOUCH®(filtro HEPA + WELTOUCH®",
    "[Virus (p.ej., COVID-19) / Bacterias / VOCs / HCHO / Eliminación de olores]",
    "[Sin preocupaciones de O3]",
    "Cómo funciona el filtro ",
    "Partículas de virus",
    "Partículas bacterianas",
    "Partículas de polvo fino",
    "Partículas de radón",
    "Sustancias nocivas como malos olores y VOCs",
    "Dirección del viento",
    "Partículas de virus muertos",
    "Partículas bacterianas descompuestas",
    "Radón neutralizado",
    "Aire contaminado",
    "Aire limpio",
    "Característica",
    "Fácil aplicación sin cambiar la estructura mecánica",
    "Potentes efectos sin límites",
    "Excelente poder de recubrimiento",
    "Seguridad garantizada",
    "Uso",
    "Todo tipo de filtros, incluido el filtro libre, el filtro medio, el filtro de carbón activado, el filtro de cerámica y el filtro de panal",
    "Comparación de la calidad del aire real",
    "Como resultado de aplicar solo el filtro en el centro D de Daejeon, el nivel de sustancias nocivas como el polvo fino y el formaldehído se redujo significativamente después de instalar el filtro de revestimiento WELTOUCH® en comparación con la normativa de gestión de la calidad del aire interior.",
    "Elementos de medición",
    "Nivel de referencia",
    "Después de instalar el filtro de revestimiento WELTOUCH®",
    "Polvo fino",
    "polvo ultrafino",
    "formaldehído",
    "bacterias flotantes totales ",
    "monóxido de carbono",
    "menos de",
    "Prueba de eliminación de polvo fino",
    "Bloqueado con película del PP (sistema cerrado)",
    "medición de concentración de polvo fino",
    "filtro sin tratar",
    "generación de polvo fino",
    "filtro sin tratar diagrama de prueba",
    "filtro de revestimiento WELTOUCH® diagrama de prueba",
    "tamaño de partícula",
    "filtro sin tratar",
    "filtro de revestimiento WELTOUCH®",
    "eficiencia de eliminación",
    "Como resultado de la prueba de eliminación de polvo fino del filtro de revestimiento WELTOUCH®, la eficiencia de eliminación del filtro ha aumentado significativamente en comparación con el filtro sin tratar.",
    "Prueba de eliminación de bacterias flotantes",
    "Cámara de prueba",
    "Prueba antibacteriana por MRSA",
    "Tasa de reducción bacteriana después de 3 horas de operación",
    "Concentración inicial",
    "Concentración después de la operación",
    "Prueba antibacteriana por microbio pulmoníaco",
    "Como resultado de la prueba antibacteriana del filtro de recubrimiento WELTOUCH® contra MRSA y neumococo, se mostró una tasa de reducción de bacterias del 99,9 % después de 3 horas de funcionamiento.",
    "Prueba de desodorización",
    "Elemento de prueba",
    "prueba de desodorización",
    "gas de prueba",
    "amoníaco, trietilamina",
    "resultado",
    "amoníaco",
    "100ppm → menos de 1ppm",
    "trietilamina",
    "30ppm → menos de 1ppm",
    "entidad de prueba",
    "Korea testing & Research Institue(KTR)",
    "Como resultado de las pruebas al recubrir la tela con WELTOUCH®, se encontró que el amoníaco y la trietilamina, que son la causa del olor, se redujeron a menos de 1 ppm en una hora.",
  ],
];

const Multi: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const [moredetailCategory, setMoreDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/product/${e.target.dataset.value}`);
  };
  return (
    <StMulti.Container
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
        setMoreDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/AdobeStock_423654356_2_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white"> {transferLanguage_head[language][1]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][5]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][1]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][5]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="noshine" onClick={detailClickHandler}>
                  {transferLanguage_head[language][4]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][5]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="redchec" onClick={detailClickHandler}>
                  Redchec®
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setMoreDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][6]}
            </StSelect.OptionValue>
            {moredetailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][6]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/fresh" onClick={detailClickHandler}>
                  {transferLanguage_head[language][7]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/air" onClick={detailClickHandler}>
                  {transferLanguage_head[language][8]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/water" onClick={detailClickHandler}>
                  {transferLanguage_head[language][9]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/build" onClick={detailClickHandler}>
                  {transferLanguage_head[language][10]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <div className="inner">
        <StMulti.Title>{transferLanguage_multi[language][0]}</StMulti.Title>
        <StMulti.TextBox>
          {transferLanguage_multi[language][1]}
          <br />
          {transferLanguage_multi[language][2]}
        </StMulti.TextBox>
        <StNoshine.Container bgcolor="white" pt="2.5rem">
          <StArrow.Box type="button" bgcolor="#00AFC8" onClick={() => navigate("/contact/stepOne")}>
            {transferLanguage_multi[language][3]} <div className="arrow"></div>
          </StArrow.Box>
        </StNoshine.Container>
        <StMulti.BoxContainer>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_multi[language][30]}</StMulti.Stitle>
            <StMulti.StextBox>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_multi[language][31]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_multi[language][32]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_multi[language][33]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_multi[language][34]}
              </StMulti.StextLine>
            </StMulti.StextBox>
          </StMulti.Box>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_multi[language][35]}</StMulti.Stitle>
            <StMulti.StextBox>{transferLanguage_multi[language][36]}</StMulti.StextBox>
          </StMulti.Box>
        </StMulti.BoxContainer>
      </div>
      <Common />
    </StMulti.Container>
  );
};

export default Multi;

export const StMulti = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  StextBox: styled.ul`
    height: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 50px;
    font-weight: 300;
    font-size: 1.25rem;
    color: var(--primary);
  `,
  StextLine: styled.li`
    display: flex;
    gap: 10px;
    align-items: center;
  `,
  Title: styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.4;
    letter-spacing: 0px;
    margin-top: 6.25rem;
    color: var(--darkgray);
  `,
  TextBox: styled.div`
    width: 100%;
    margin: 2.5rem auto;
    text-align: center;
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--primary);
  `,
  BoxContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 100px;

    @media screen and (max-width: 1200px) {
      gap: 30px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  Box: styled.div<{ radius?: string }>`
    display: flex;
    flex-direction: column;
    width: calc((100% - 100px) / 2);
    border-radius: ${({ radius }) => radius ?? "4px"};
    overflow: hidden;
    border: 2px solid #32bfd3;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  BoxTilte: styled.div`
    width: 100%;
    height: 50px;
    font-weight: 700;
    font-size: 1.5rem;
  `,
  Stitle: styled.div`
    width: 100%;
    height: 50px;
    font-weight: 700;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #32bfd3;
    color: white;
  `,
};
