import React from "react";
import { StAbout, StArrow, StSelect } from "../../aboutus/StAbout";
import { StNoshine } from "../StProduct";
import { useNavigate } from "react-router-dom";
import { StMulti } from "./Multi";
import { StFresh } from "./Fresh";
import { styled } from "styled-components";

import img1 from "../../../img/products/techapply/data4/img1.jpg";
import img2 from "../../../img/products/techapply/data4/img2.jpg";
import check from "../../../img/products/techapply/data2/check-solid.svg";
import WaterSecond from "../../../components/product/WaterSecond";
import { transferAtom } from "../../../recoil/transferAtom";
import { useRecoilValue } from "recoil";
import { transferLanguage_head } from "../../../components/SelectBox";
import { windowScrollTop } from "../../../components/Footer";

export const transferLanguage_water = [
  [
    "수질 개선 기술",
    "물에 포함되어 있는 인 성분 등을 분해·제거 하고, 유수분리를 하여 오일 성분을 분리하여 줍니다.",
    "또한, 양식장의 콘크리트 벽면이나 벽돌 소재에 WELTOUCH®를 적용하여 녹조 및 이끼가 생기지 않게 합니다.",
    "수질 개선 기술 문의 하기",
    "특징",
    "손쉽게 적용 가능",
    "우수한 코팅력",
    "보장된 안전성",
    "용도",
    "수처리 여재, 양식장 등",
    "총인 제거 성능", //10
    "샘플",
    "수(水)처리 시간",
    "총인량",
    "원수",
    "무처리",
    "WELTOUCH® 처리",
    "불검출",
    "총인제거 성능을 비교하기 위해 제작한 샘플모두 자연광하에서 방치후 시료를 체취한 결과 WELTOUCH®를 처리한 곳에서 10분만에 총인량 100% 제거 되었습니다.",
    "유수분리", //19
    "Filter의 폐오일 흡착정도",
    "Filter를 제거한 후 관찰한 폐오일 혼합액의 탁도",
    "결과",
    "전체적으로 색이 연하게 흡착",
    "매우 탁함",
    "전체적으로 색이 진하게 흡착",
    "맑아짐",
    "흡착도 매우 우수",
    "Filter의 폐오일 흡착정도와 폐오일 혼합액의 탁도를 확인한 결과 WELTOUCH®를 처리한 Filter의 우수한 흡착력으로 Filter를 통과한 폐오일 혼합액이 맑아졌습니다.",
    "유수분리 Test 영상",
    "분", //총인 제거 성능 수(水)처리 시간 //30
  ],
  [
    "Water Quality Improvement",
    "WELTOUCH® decomposes and eliminates phosphorous substances and separates oil from water.",
    "Moreover, WELTOUCH® prevents green algae and moss from growing on concrete walls or bricks in fish farms.",
    "Inquire about water quality improvement Technology",
    "Features",
    "Easily applicable",
    "Superior coating ability",
    "Secured safety",
    "Applications",
    "Water treatment medium, fish farm, etc.",
    "Total phosphorous removal performance",
    "Sample",
    "Water treatment duration",
    "Total phosphorous",
    "Crude water",
    "Non treatment",
    "WELTOUCH® treatment",
    "Not detected",
    "All samples made to compare the total phosphorous removal performance were kept under natural light and treated with WELTOUCH®. Total phosphorous was 100% removed from the samples in just 10 minutes.",
    "Oil-water separation",
    "Waste oil absorption by filter",
    "Turbidity of waste oil mixture observed after filter removal",
    "Result",
    "Light color in overall absorbed surface",
    "Very turbid",
    "Dark color in overall absorbed surface",
    "Clear",
    "Excellent absorption ratio",
    "In accordance with the analysis of waste oil absorption by the filter and the turbidity of the waste oil mixture, the waste oil mixture became clear after passing through the filter due to excellent absorption of the filter treated with WELTOUCH®.",
    "Oil-water separation test video",
    "minutes",
  ],
  [
    "Teknologi peningkatan kualitas air",
    "Mengurai dan menghilangkan fosfor yang terkandung dalam air dan memisahkan minyak dan air.",
    "Selain itu, WELTOUCH® diterapkan pada dinding beton atau bahan bata di peternakan ikan untuk mencegah ganggang hijau dan lumut.",
    "Pertanyaan tentang Teknologi peningkatan kualitas air",
    "Fitur",
    "Mudah diaplikasikan",
    "Daya pelapis luar biasa",
    "Keamanan terjamin",
    "Tujuan",
    "Media filter pengolahan air, peternakan ikan, dll.",
    "Performa penghilangan fosfor total",
    "Sampel",
    "Waktu pengolahan air",
    "Total fosfor",
    "Air baku",
    "Tanpa pengolahan",
    "Pengolahan WELTOUCH®",
    "Tanpa deteksi",
    "Untuk membandingkan kinerja penghilangan fosfor total, semua sampel dibiarkan dalam cahaya alami dan dikumpulkan, menghasilkan penghilangan fosfor total 100% dalam 10 menit di mana WELTOUCH® diperlakukan.",
    "Pemisahan minyak-air",
    "Derajat penyerapan limbah oli dalam filter",
    "kekeruhan campuran limbah oli diamati setelah melepas filter",
    "Hasil",
    "Secara keseluruhan, warnanya terserap ringan",
    "Sangat keruh",
    "Secara keseluruhan, warna terserap dalam ",
    "Menjadi Jelas",
    "Adsorpsi yang sangat baik",
    "Sebagai hasil dari pemeriksaan tingkat adsorpsi (penyerapan) oli bekas dan kekeruhan campuran limbah oli, adsorpsi yang sangat baik dari filter yang diolah dengan WELTOUCH® membersihkan campuran limbah oli yang melewati filter.",
    "Video pemisahan minyak-air",
    "menit",
  ],
  [
    "Tecnología de mejora de la calidad del agua",
    "Descompone y elimina el componente de fósforo contenido en el agua y separa el componente de aceite a través de la separación de aceite y agua.",
    "Además, al aplicar WELTOUCH® sobre el muro de hormigón o material de ladrillo de la piscifactoría, se evita la aparición de algas y musgo.",
    "contacto",
    "Característica",
    "Fácil de aplicar",
    "Excelente poder de recubrimiento",
    "Seguridad garantizada",
    "Uso",
    "Materiales filtrantes para tratamiento de agua, piscifactorías, etc.",
    "Rendimiento total de eliminación de fósforo",
    "Muestra",
    "Tiempo de tratamiento de agua",
    "Fósforo tota",
    "Agua cruda",
    "Sin tratamiento",
    "trató con WELTOUCH®",
    "Sin detección",
    "Como resultado de la toma de muestras después de dejar todas las muestras bajo luz natural para comparar el rendimiento de eliminación de fósforo total, se eliminó el 100% del fósforo total en 10 minutos en el lugar donde se trató con WELTOUCH®.",
    "Separación de agua-aceite",
    "Grado de adsorción del aceite usado por el filtro",
    "Turbidez de la mezcla de aceite residual observada después de quitar el filtro",
    "Resultado",
    "Color claro en general",
    "Muy turbio",
    "Color oscuro impregnado en general",
    "Claro",
    "Excelente nivel de adsorción",
    "Como resultado de comprobar el grado de adsorción del aceite usado por el filtro y la turbidez de la mezcla de aceite usado, la excelente capacidad de adsorción del filtro tratado con WELTOUCH® hizo que la mezcla de aceite usado que pasaba por el filtro fuera transparente.",
    "Video de prueba de separación de agua y aceite",
    "minutos",
  ],
];

const Water: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const [moredetailCategory, setMoreDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/product/${e.target.dataset.value}`);
  };
  const goContact = () => {
    navigate("/contact/stepOne");
    windowScrollTop();
  };
  return (
    <StMulti.Container
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
        setMoreDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/AdobeStock_495620064_2_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white"> {transferLanguage_head[language][1]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][5]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][1]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][5]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="noshine" onClick={detailClickHandler}>
                  {transferLanguage_head[language][4]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][5]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="redchec" onClick={detailClickHandler}>
                  Redchec®
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setMoreDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][9]}
            </StSelect.OptionValue>
            {moredetailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][6]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/fresh" onClick={detailClickHandler}>
                  {transferLanguage_head[language][7]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/air" onClick={detailClickHandler}>
                  {transferLanguage_head[language][8]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/water" onClick={detailClickHandler}>
                  {transferLanguage_head[language][9]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/build" onClick={detailClickHandler}>
                  {transferLanguage_head[language][10]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <div className="inner">
        <StMulti.Title>{transferLanguage_water[language][0]}</StMulti.Title>
        <StMulti.TextBox>
          {transferLanguage_water[language][1]}
          <br />
          {transferLanguage_water[language][2]}
        </StMulti.TextBox>
        <StNoshine.Container bgcolor="white" pt="2.5rem">
          <StArrow.Box type="button" bgcolor="#00AFC8" onClick={goContact}>
            {transferLanguage_water[language][3]}
            <div className="arrow"></div>
          </StArrow.Box>
        </StNoshine.Container>

        <StMulti.BoxContainer>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_water[language][4]}</StMulti.Stitle>
            <StMulti.StextBox>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_water[language][5]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_water[language][6]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_water[language][7]}
              </StMulti.StextLine>
            </StMulti.StextBox>
          </StMulti.Box>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_water[language][8]}</StMulti.Stitle>
            <StMulti.StextBox>{transferLanguage_water[language][9]}</StMulti.StextBox>
          </StMulti.Box>
        </StMulti.BoxContainer>
      </div>
      <WaterSecond />
    </StMulti.Container>
  );
};

export default Water;
const StWater = {
  Img1: styled.img`
    width: 420px;
    height: 315px;
    object-fit: contain;
    display: block;
  `,
  Img2: styled.img`
    width: 556px;
    height: 315px;
    object-fit: contain;
    display: block;
  `,
};
