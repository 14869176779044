import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import logo from "../img/logo/logo_header.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { transferAtom } from "../recoil/transferAtom";
import down from "../img/mobile/down.svg";
import up from "../img/mobile/up.svg";
import nexchem from "../img/about/partner/NexCHEM.png";
import SelectBox, { transferLanguage_head } from "./SelectBox";
import { windowScrollTop } from "./Footer";
import { styled } from "styled-components";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [language, setLanguages] = useRecoilState(transferAtom);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [whichOne, setWhichOne] = React.useState<"none" | "ABOUT" | "Product">("none");
  const [technicArea, setTechnicArea] = React.useState(false);

  return (
    <header id="header">
      <div className="header-wrap">
        <div className="inner">
          <div className="logo-wrap">
            <StLogoArea
              className="logo logo-nexchem"
              title="홈으로"
              onClick={() => {
                windowScrollTop();
                window.location.href = "http://www.weltouch.com";
              }}
            >
              <StNexchemLogo src={nexchem} alt="NexCHEM 로고" />
            </StLogoArea>
            <StLogoArea
              className="logo"
              title="홈으로"
              onClick={() => {
                windowScrollTop();
                navigate("/");
              }}
            >
              <img src={logo} alt="WELTOUCH 로고" />
            </StLogoArea>
          </div>
          <div className="nav-wrap">
            <nav>
              <ul>
                <li>
                  <div
                    className="nav-title"
                    onClick={() => {
                      windowScrollTop();
                      navigate("/about/brand");
                    }}
                  >
                    {transferLanguage_head[language][0]}
                  </div>
                  <div className="nav-depth">
                    <ul>
                      <li
                        onClick={() => {
                          windowScrollTop();
                          navigate("/about/brand");
                        }}
                      >
                        <a> {transferLanguage_head[language][11]}</a>
                      </li>
                      {/* <li
                        onClick={() => {
                          windowScrollTop();
                          navigate("/about/relationship");
                        }}
                      >
                        <a> {transferLanguage_head[language][12]}</a>
                      </li> */}
                      {/* <li
                        onClick={() => {
                          windowScrollTop();
                          navigate("/about/price");
                        }}
                      >
                        <a> {transferLanguage_head[language][13]}</a>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li>
                  <div
                    className="nav-title"
                    onClick={() => {
                      windowScrollTop();
                      navigate("/product/noshine");
                    }}
                  >
                    {transferLanguage_head[language][1]}
                  </div>
                  <div className="nav-depth">
                    <ul>
                      <li
                        onClick={() => {
                          windowScrollTop();
                          navigate("/product/noshine");
                        }}
                      >
                        <a> {transferLanguage_head[language][4]}</a>
                      </li>
                      <li>
                        <div> {transferLanguage_head[language][5]}</div>
                        <div className="nav-depth">
                          <ul>
                            <li
                              onClick={() => {
                                windowScrollTop();
                                navigate("/product/technical/multi");
                              }}
                            >
                              <a> {transferLanguage_head[language][6]}</a>
                            </li>
                            <li
                              onClick={() => {
                                windowScrollTop();
                                navigate("/product/technical/fresh");
                              }}
                            >
                              <a> {transferLanguage_head[language][7]}</a>
                            </li>
                            <li
                              onClick={() => {
                                windowScrollTop();
                                navigate("/product/technical/air");
                              }}
                            >
                              <a> {transferLanguage_head[language][8]}</a>
                            </li>
                            <li
                              onClick={() => {
                                windowScrollTop();
                                navigate("/product/technical/water");
                              }}
                            >
                              <a> {transferLanguage_head[language][9]}</a>
                            </li>
                            <li
                              onClick={() => {
                                windowScrollTop();
                                navigate("/product/technical/build");
                              }}
                            >
                              <a> {transferLanguage_head[language][10]}</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        onClick={() => {
                          windowScrollTop();
                          navigate("/product/redchec");
                        }}
                      >
                        <a>Redchec®</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  onClick={() => {
                    windowScrollTop();
                    navigate("/media");
                  }}
                >
                  <a className="nav-title"> {transferLanguage_head[language][2]}</a>
                </li>
                <li
                  onClick={() => {
                    windowScrollTop();
                    navigate("/contact/stepOne");
                  }}
                >
                  <a className="nav-title"> {transferLanguage_head[language][3]}</a>
                </li>
              </ul>
            </nav>
            <SelectBox />
          </div>
          <div
            className="m_nav_btn"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <FontAwesomeIcon icon={solid("bars")} />
          </div>
          {menuOpen && (
            <div className="mobile-nav-wrap">
              <div className="nav-header">
                <SelectBox />
                <div className="m_nav_btn" onClick={() => setMenuOpen(false)}>
                  <FontAwesomeIcon icon={solid("xmark")} />
                </div>
              </div>
              {menuOpen && (
                <nav className="nav-menu">
                  <ul>
                    <StTitleSelect
                      selected={whichOne === "ABOUT"}
                      // className="nav-title"
                      onClick={() => (whichOne === "ABOUT" ? setWhichOne("none") : setWhichOne("ABOUT"))}
                    >
                      {transferLanguage_head[language][0]} <StToggle src={whichOne === "ABOUT" ? up : down} />
                    </StTitleSelect>
                    {whichOne === "ABOUT" && (
                      <>
                        <StdetailSelect
                          onClick={() => {
                            setMenuOpen(false);
                            navigate("/about/brand");
                            windowScrollTop();
                            setWhichOne("none");
                            setTechnicArea(false);
                          }}
                        >
                          {transferLanguage_head[language][11]}
                        </StdetailSelect>

                        {/* <StdetailSelect
                          onClick={() => {
                            setMenuOpen(false);
                            navigate("/about/relationship");
                            windowScrollTop();
                            setWhichOne("none");
                            setTechnicArea(false);
                          }}
                        >
                          {transferLanguage_head[language][12]}
                        </StdetailSelect> */}

                        {/* <StdetailSelect
                          onClick={() => {
                            setMenuOpen(false);
                            navigate("/about/price");
                            windowScrollTop();
                            setWhichOne("none");
                            setTechnicArea(false);
                          }}
                        >
                          {transferLanguage_head[language][13]}
                        </StdetailSelect> */}
                      </>
                    )}

                    <StTitleSelect
                      selected={whichOne === "Product"}
                      // className="nav-title"
                      onClick={() => (whichOne === "Product" ? setWhichOne("none") : setWhichOne("Product"))}
                    >
                      {transferLanguage_head[language][1]} <StToggle src={whichOne === "Product" ? up : down} />
                    </StTitleSelect>

                    {whichOne === "Product" && (
                      <>
                        <StdetailSelect
                          onClick={() => {
                            setMenuOpen(false);
                            navigate("/product/noshine");
                            windowScrollTop();
                            setWhichOne("none");
                            setTechnicArea(false);
                          }}
                        >
                          {transferLanguage_head[language][4]}
                        </StdetailSelect>

                        <StdetailSelect
                          selected={technicArea}
                          style={{
                            borderBottom: " 1px solid var(--border)",
                            paddingBottom: "8px",
                          }}
                          onClick={() => {
                            setTechnicArea(!technicArea);
                          }}
                        >
                          {transferLanguage_head[language][5]}
                          <StToggle src={technicArea === true ? up : down} />
                        </StdetailSelect>
                        {technicArea && (
                          <>
                            <StdetailSelect
                              onClick={() => {
                                setMenuOpen(false);
                                navigate("/product/technical/multi");
                                windowScrollTop();
                                setWhichOne("none");
                                setTechnicArea(false);
                              }}
                            >
                              {transferLanguage_head[language][6]}
                            </StdetailSelect>
                            <StdetailSelect
                              onClick={() => {
                                setMenuOpen(false);
                                navigate("/product/technical/fresh");
                                windowScrollTop();
                              }}
                            >
                              {transferLanguage_head[language][7]}
                            </StdetailSelect>
                            <StdetailSelect
                              onClick={() => {
                                setMenuOpen(false);
                                navigate("/product/technical/air");
                                windowScrollTop();
                                setWhichOne("none");
                                setTechnicArea(false);
                              }}
                            >
                              {transferLanguage_head[language][8]}
                            </StdetailSelect>

                            <StdetailSelect
                              onClick={() => {
                                setMenuOpen(false);
                                navigate("/product/technical/water");
                                windowScrollTop();
                                setWhichOne("none");
                                setTechnicArea(false);
                              }}
                            >
                              {transferLanguage_head[language][9]}
                            </StdetailSelect>
                            <StdetailSelect
                              onClick={() => {
                                setMenuOpen(false);
                                navigate("/product/technical/build");
                                windowScrollTop();
                                setWhichOne("none");
                                setTechnicArea(false);
                              }}
                              style={{ borderBottom: " 1px solid var(--border)", paddingBottom: "8px" }}
                            >
                              {transferLanguage_head[language][10]}
                            </StdetailSelect>
                          </>
                        )}

                        <StdetailSelect
                          onClick={() => {
                            windowScrollTop();
                            setMenuOpen(false);
                            navigate("/product/redchec");
                            setWhichOne("none");
                            setTechnicArea(false);
                          }}
                        >
                          <a>Redchec®</a>
                        </StdetailSelect>
                      </>
                    )}

                    <StTitleSelect
                      selected={false}
                      onClick={() => {
                        windowScrollTop();
                        setMenuOpen(false);
                        navigate("/media");
                        setWhichOne("none");
                        setTechnicArea(false);
                      }}
                    >
                      {transferLanguage_head[language][2]}
                    </StTitleSelect>

                    <StTitleSelect
                      selected={false}
                      onClick={() => {
                        windowScrollTop();
                        navigate("/contact/stepOne");
                        setWhichOne("none");
                        setTechnicArea(false);
                      }}
                    >
                      {transferLanguage_head[language][3]}
                    </StTitleSelect>
                  </ul>
                </nav>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
const StToggle = styled.div<{ src?: any }>`
  background-image: ${({ src }) => `url(${src})`};
  width: 14px;
  height: 10px;
  padding: 10px;
  margin-top: 8px;
  display: flex;
  object-fit: cover;
  background-repeat: no-repeat;
  background-color: white;
  position: absolute;
  right: 16px;
`;
const StTitleSelect = styled.li<{ selected: boolean }>`
  height: 100%;
  /* padding: 0 30px; */
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding-bottom: 12px;
  color: ${({ selected }) => (selected === true ? "#00afc8" : "black")};
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
`;
const StdetailSelect = styled.li<{ selected?: boolean }>`
  margin-top: 6px;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: ${({ selected }) => (selected === true ? "#0B4FD4" : null)};
`;
const StLogoArea = styled.a`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const StNexchemLogo = styled.img`
  width: 180px;
  height: 100%;
  object-fit: cover;
  display: block;
`;
