import React from "react";
import { StCommon } from "./Common";
import { styled } from "styled-components";
import AirExample from "./AirExample";
// import AirCompany from "./AirCompany";

import img1_1 from "../../img/products/techapply/data3/img1_1.png";
import img1_2 from "../../img/products/techapply/data3/img1_2.jpg";
import img1_4 from "../../img/products/techapply/data3/img1_4.png";

import img2_1 from "../../img/products/techapply/data3/img2_1.png";

import img4_1 from "../../img/products/techapply/data3/img4_1.jpg";
import img4_2 from "../../img/products/techapply/data3/img4_2.jpg";
import img4_3 from "../../img/products/techapply/data3/img4_3.jpg";
import img4_4 from "../../img/products/techapply/data3/img4_4.jpg";
import img4_5 from "../../img/products/techapply/data3/img4_5.jpg";

import img5_1 from "../../img/products/techapply/data3/img5_1.jpg";
import img5_2 from "../../img/products/techapply/data3/img5_2.jpg";
import img5_3 from "../../img/products/techapply/data3/img5_3.jpg";
import img5_4 from "../../img/products/techapply/data3/img5_4.jpg";
import img5_5 from "../../img/products/techapply/data3/img5_5.jpg";

import img6_1 from "../../img/products/techapply/data3/img6_1.jpg";
import img6_2 from "../../img/products/techapply/data3/img6_2.jpg";
import img6_3 from "../../img/products/techapply/data3/img6_3.jpg";
import img6_4 from "../../img/products/techapply/data3/img6_4.jpg";
import img6_5 from "../../img/products/techapply/data3/img6_5.jpg";

import img7_1 from "../../img/products/techapply/data3/img7_1.jpg";
import img7_2 from "../../img/products/techapply/data3/img7_2.jpg";
import img7_3 from "../../img/products/techapply/data3/img7_3.jpg";
import img7_4 from "../../img/products/techapply/data3/img7_4.jpg";
import img7_5 from "../../img/products/techapply/data3/img7_5.jpg";

import img8_1 from "../../img/products/techapply/data3/img8_1.jpg";
import img8_2 from "../../img/products/techapply/data3/img8_2.jpg";
import img8_3 from "../../img/products/techapply/data3/img8_3.jpg";
import img8_4 from "../../img/products/techapply/data3/img8_4.jpg";
import img8_5 from "../../img/products/techapply/data3/img8_5.jpg";

import img9_1 from "../../img/products/techapply/data3/img9_1.jpg";
import img9_2 from "../../img/products/techapply/data3/img9_2.jpg";
import img9_3 from "../../img/products/techapply/data3/img9_3.jpg";
import img9_4 from "../../img/products/techapply/data3/img9_4.jpg";
import img9_5 from "../../img/products/techapply/data3/img9_5.jpg";

import img3_2 from "../../img/products/techapply/data3/img3_2.png";
import img3_2_en from "../../img/products/techapply/data3/Air3_2_en.png";
import img3_2_idn from "../../img/products/techapply/data3/Air3_2_idn.png";
import img3_2_es from "../../img/products/techapply/data3/Air3_2_es.png";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../recoil/transferAtom";
import { transferLanguage_air } from "../../pages/product/technical/Air";
const transferImg = [[img3_2], [img3_2_en], [img3_2_idn], [img3_2_es]];

const AirSecond = () => {
  const language = useRecoilValue(transferAtom);
  return (
    <StCommon.Container>
      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_air[language][11]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StAir.AirBox>
          <StCommon.AirText ta="center" style={{ order: "2" }}>
            {transferLanguage_air[language][16]}
          </StCommon.AirText>
          <StAir.ImgBox style={{ width: "100%" }} order="1" maxw="100%" ml="0">
            <StAir.Img12 src={img1_2} alt="" />
            <StAir.Img14 src={img1_4} alt="" />
          </StAir.ImgBox>
        </StAir.AirBox>
        <StCommon.PyoBox>
          <StAir.PyoRow columns="repeat(3, 1fr)">
            <StAir.PyoTitle bgcolor="#F1F1F1" column="1 / span 1" row="1 / span 2" br="true">
              {transferLanguage_air[language][12]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="#F1F1F1" column="2 / span 2" row="1 / span 1">
              {transferLanguage_air[language][13]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="#F1F1F1" column="2 / span 1" row="2 / span 1" bt="true">
              {transferLanguage_air[language][14]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="rgba(204, 239, 244, 1)" column="3 / span 1" row="2 / span 1">
              {transferLanguage_air[language][15]}
            </StAir.PyoTitle>
          </StAir.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true" br="true">
              HCHO
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true">
              33.2 ㎍/m³
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="33%" flex="1 1 0" bb="true" color="white">
              6.4 ㎍/m³
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true" br="true">
              TVOC
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true">
              359.0 ㎍/m³
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="33%" flex="1 1 0" bb="true" color="white">
              76.0 ㎍/m³
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true" br="true">
              Benzene
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true">
              4.6 ㎍/m³
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="33%" flex="1 1 0" bb="true" color="white">
              2.0 ㎍/m³
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true" br="true">
              Toluene
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true">
              137.1 ㎍/m³
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="33%" flex="1 1 0" bb="true" color="white">
              19.4 ㎍/m³
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true" br="true">
              Ethylbenzene
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true">
              21.5 ㎍/m³
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="33%" flex="1 1 0" bb="true" color="white">
              3.4 ㎍/m³
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true" br="true">
              Xylene
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true">
              93.3 ㎍/m³
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="33%" flex="1 1 0" bb="true" color="white">
              13.2 ㎍/m³
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true" br="true">
              Styrene
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" bb="true">
              2.8 ㎍/m³
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="33%" flex="1 1 0" bb="true" color="white">
              0.4 ㎍/m³
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>
            {/* VOCs 제거 성능 */}
            {transferLanguage_air[language][17]}
          </StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <StCommon.AirText style={{width: "100%", textAlign: "center"}}>{transferLanguage_air[language][26]}</StCommon.AirText>
        </StCommon.AirBox>
        <StCommon.PyoBox>
          <StAir.PyoRow columns="repeat(4, 1fr)">
            <StAir.PyoTitle bgcolor="#F1F1F1" column="1 / span 1" row="1 / span 2" br="true">
              {transferLanguage_air[language][18]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="#F1F1F1" column="2 / span 3" row="1 / span 1">
              {transferLanguage_air[language][19]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="#F1F1F1" column="2 / span 1" row="2 / span 1" bt="true" br="true">
              {transferLanguage_air[language][20]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="#F1F1F1" column="3 / span 1" row="2 / span 1" bt="true">
              {transferLanguage_air[language][21]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="rgba(204, 239, 244, 1)" column="4 / span 1" row="2 / span 1">
              {transferLanguage_air[language][22]}
            </StAir.PyoTitle>
          </StAir.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              Toulene
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              1,645.0
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              108.6
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="25%" flex="1 1 0" bb="true" color="white">
              93%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              Styrene
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              3.8
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              ND
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="25%" flex="1 1 0" bb="true" color="white">
              100%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              Formaldehyde
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              142.0
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              ND
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="25%" flex="1 1 0" bb="true" color="white">
              100%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>
            {/* 실제 적용 성적 */}
            {transferLanguage_air[language][27]}
          </StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <StCommon.AirText>
            {transferLanguage_air[language][40]}
            <br />
            {transferLanguage_air[language][41]}
          </StCommon.AirText>
          <StAir.ImgBox>
            <StAir.Img32 src={transferImg[language][0]} alt="" />
          </StAir.ImgBox>
        </StCommon.AirBox>
        <StCommon.PyoBox>
          <div className="caption">({transferLanguage_air[language][30]})</div>
          <StAir.PyoRow columns="repeat(3, 1fr)">
            <StAir.PyoTitle bgcolor="#F1F1F1" column="1 / span 1" row="1 / span 2" br="true">
              {transferLanguage_air[language][28]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="#F1F1F1" column="2 / span 3" row="1 / span 1">
              {transferLanguage_air[language][29]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="#F1F1F1" column="2 / span 1" row="2 / span 1" bt="true">
              {transferLanguage_air[language][31]}
            </StAir.PyoTitle>
            <StAir.PyoTitle bgcolor="rgba(204, 239, 244, 1)" column="3 / span 1" row="2 / span 1">
              {transferLanguage_air[language][32]}
            </StAir.PyoTitle>
          </StAir.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_air[language][33]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              20
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="25%" flex="1 1 0" bb="true" color="white">
              0
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_air[language][34]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              33
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="25%" flex="1 1 0" bb="true" color="white">
              5
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_air[language][35]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              25
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="25%" flex="1 1 0" bb="true" color="white">
              3
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_air[language][42]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <AirExample
          title={transferLanguage_air[language][43]}
          img2={img4_2}
          img3={img4_3}
          img4={img4_4}
          img5={img4_5}
        />
        <AirExample
          title={transferLanguage_air[language][44]}
          img2={img5_2}
          img3={img5_3}
          img4={img5_4}
          img5={img5_5}
          explain={transferLanguage_air[language][45]}
        />
        <AirExample
          title={transferLanguage_air[language][46]}
          img2={img6_2}
          img3={img6_3}
          img4={img6_4}
          img5={img6_5}
        />
        <AirExample
          title={transferLanguage_air[language][47]}
          img2={img7_2}
          img3={img7_3}
          img4={img7_4}
          img5={img7_5}
        />
        <AirExample
          title={transferLanguage_air[language][48]}
          img2={img8_2}
          img3={img8_3}
          img4={img8_4}
          img5={img8_5}
        />
        <AirExample
          title={transferLanguage_air[language][50]}
          img2={img9_2}
          img3={img9_3}
          img4={img9_4}
          img5={img9_5}
          explain={transferLanguage_air[language][51]}
        />
      </StCommon.CoatingBox>
      {/* <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_air[language][52]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <AirCompany />
      </StCommon.CoatingBox> */}
    </StCommon.Container>
  );
};

export default AirSecond;

const StAir = {
  AirBox: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3.75rem 30px;
    margin-top: 5rem;

    > * {
      width: calc((100% - 30px) / 2);
    }

    @media screen and (max-width: 768px) {
      > * {
        width: 100%;
      }
    }
  `,
  PyoRow: styled.div<{ columns: string }>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
  `,
  PyoTitle: styled.div<{
    bgcolor: string;
    bb?: string;
    color?: string;
    bt?: string;
    column: string;
    row: string;
    jc?: string;
    br?: string;
  }>`
    display: flex;
    justify-content: ${({ jc }) => jc ?? "center"};
    align-items: center;
    padding: 16px 20px;
    border-bottom: ${(props) => (props.bb === "true" ? "1px solid #dfdfdf" : "")};
    border-top: ${(props) => (props.bt === "true" ? "1px solid #dfdfdf" : "")};
    border-right: ${(props) => (props.br === "true" ? "1px solid #dfdfdf" : "")};
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color ?? "var(--darkgray)"};
    grid-column: ${({ column }) => column};
    grid-row: ${({ row }) => row};
    text-align: center;
  `,
  ImgBox: styled.div<{ gap?: string; order?: string; maxw?: string; ml?: string }>`
    max-width: ${(props) => props.maxw ?? "500px"};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.gap ?? "30px"};
    margin-top: 5rem;
    margin-left: ${(props) => props.ml ?? "auto"};
    order: ${(props) => props.order ?? "unset"};

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 0 auto;
      margin-top: 5rem;
    }
  `,
  Img11: styled.img`
    width: 100%;
    max-width: 400px;
    object-fit: contain;
    display: block;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      max-width: 300px;
    }
  `,
  Img12: styled.img`
    width: 46%;
    max-width: 500px;
    object-fit: cover;
    display: block;
    border-radius: 20px;
    box-shadow: var(--box-shadow0014);

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0 auto;
    }
  `,
  Img14: styled.img`
    width: 46%;
    max-width: 500px;
    object-fit: cover;
    display: block;
    border-radius: 20px;
    box-shadow: var(--box-shadow0014);

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0 auto;
    }
  `,
  Img21: styled.img`
    width: 360px;
    object-fit: contain;
    display: block;
    margin-left: auto;

    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
  `,
  Img32: styled.img`
    width: 100%;
    max-width: 487px;
    height: 321px;
    object-fit: contain;
    display: block;
  `,
};
