import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router";
import { styled } from "styled-components";
import Inquiry from "../../img/contact/coop/AdobeStock_442157068-scaled_tiny.jpg";
import { useRecoilState, useRecoilValue } from "recoil";
import { InquiryAtom } from "../../recoil/InquiryAtom";
import { StSelect } from "../../pages/aboutus/StAbout";
import { transferLanguage_head } from "../SelectBox";
import { transferAtom } from "../../recoil/transferAtom";
import { transferLanguage_contact } from "./InquiryPageFirst";

const InquiryPageSecond: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const [inquiryAtom, setInquiryAtom] = useRecoilState(InquiryAtom);
  const [businessType, setBusinessType] = React.useState<
    "publicInstitution" | "individual" | "corporation" | "corporate body"
  >(inquiryAtom.businessType);
  const [agreement, setAgreement] = React.useState<boolean>(false);

  const handleOptionChange = (event: any) => {
    setBusinessType(event.target.value);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInquiryAtom((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const saveHandler = () => {
    if (agreement === true) {
      setInquiryAtom((prev) => ({
        ...prev,
        businessType: businessType,
      }));

      navigate("/contact/stepThree");
    } else {
      switch (language) {
        case 0:
          alert("필수 동의사항에 동의해주셔야 문의서를 제출하실 수 있습니다.");
          break;
        case 1:
          alert(
            "You can submit this form only if you agree to collection of personal information agreement. please kindly check the agreements below."
          );
          break;
        case 2:
          alert(
            "Anda dapat mengirimkan formulir ini hanya jika Anda setuju untuk mengumpulkan perjanjian informasi pribadi. silakan periksa perjanjian di bawah ini."
          );
          break;
        case 3:
          alert(
            "Sólo puedes presentar este formulario si aceptas recoger información personal. por favor revisen los acuerdos siguientes."
          );
          break;
        default:
          alert("An error occurred. Please try again later.");
          break;
      }
    }
  };
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);

  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };

  return (
    <div>
      <StStepOne.TitleBgImg src={Inquiry}>
        <div className="title-area">
          <StStepOne.TitleText>{transferLanguage_head[language][3]}</StStepOne.TitleText>
        </div>
      </StStepOne.TitleBgImg>
      <div
        onClick={() => {
          setBigCategory(false);
        }}
      >
        <StSelect.SelectBox>
          <div className="inner">
            <StSelect.SelectOption>
              <StSelect.OptionValue
                onClick={(e: any) => {
                  e.stopPropagation();
                  setBigCategory((prev) => !prev);
                }}
              >
                {transferLanguage_head[language][3]}
              </StSelect.OptionValue>
              {bigCategory && (
                <StSelect.OptionBox>
                  <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                    {transferLanguage_head[language][0]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                    {transferLanguage_head[language][1]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                    {transferLanguage_head[language][2]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                    {transferLanguage_head[language][3]}
                  </StSelect.OptionOtherValue>
                </StSelect.OptionBox>
              )}
            </StSelect.SelectOption>
          </div>
        </StSelect.SelectBox>
        <StWholeDiv>
          <StSeqShapes>
            <StBar>
              <StCircleWrap seq="1">
                <StCircle bg="#0b4fd4;" />
                <StTxt>{transferLanguage_head[language][3]}</StTxt>
              </StCircleWrap>
              <StCircleWrap seq="2">
                <StCircle bg="#0b4fd4;" />
                <StTxt>{transferLanguage_contact[language][0]}</StTxt>
              </StCircleWrap>
              <StCircleWrap seq="3">
                <StCircle bg="white" />
                <StTxt>{transferLanguage_contact[language][1]}</StTxt>
              </StCircleWrap>
            </StBar>
          </StSeqShapes>
          <StInfo>
            {transferLanguage_contact[language][2]}
            <StLightTxt>{transferLanguage_contact[language][3]}</StLightTxt>
          </StInfo>
          <StInputContainer>
            <StRadioWrap>
              <StRadioArea>
                <input
                  type="radio"
                  id="public"
                  value="publicInstitution"
                  name="businessType"
                  onChange={handleOptionChange}
                  checked={businessType === "publicInstitution"}
                />
                <label htmlFor="public">{transferLanguage_contact[language][11]}</label>
              </StRadioArea>
              <StRadioArea>
                <input
                  name="businessType"
                  type="radio"
                  id="individual"
                  value="individual"
                  onChange={handleOptionChange}
                  checked={businessType === "individual"}
                />
                <label htmlFor="individual">{transferLanguage_contact[language][12]}</label>
              </StRadioArea>
              <StRadioArea>
                <input
                  type="radio"
                  id="corporation"
                  value="corporation"
                  name="businessType"
                  onChange={handleOptionChange}
                  checked={businessType === "corporation"}
                />
                <label htmlFor="corporation">{transferLanguage_contact[language][13]}</label>
              </StRadioArea>
              <StRadioArea>
                <input
                  type="radio"
                  name="businessType"
                  id="corporate_body"
                  value="corporate body"
                  onChange={handleOptionChange}
                  checked={businessType === "corporate body"}
                />
                <label htmlFor="corporate_body">{transferLanguage_contact[language][14]}</label>
              </StRadioArea>
            </StRadioWrap>
            <StinRow>
              <StCompanyName
                type="text"
                placeholder={transferLanguage_contact[language][15]}
                name="companyName"
                value={inquiryAtom.companyName}
                onChange={onChangeHandler}
              />
              <StCompanyName
                type="text"
                placeholder={transferLanguage_contact[language][16]}
                name="name"
                value={inquiryAtom.name}
                onChange={onChangeHandler}
              />
            </StinRow>

            <StinRow>
              <StCompanyName
                type="text"
                placeholder={transferLanguage_contact[language][17]}
                name="phoneNumber"
                value={inquiryAtom.phoneNumber}
                onChange={onChangeHandler}
              />
              <StCompanyName
                type="text"
                placeholder={transferLanguage_contact[language][18]}
                name="email"
                value={inquiryAtom.email}
                onChange={onChangeHandler}
              />
            </StinRow>

            <StinRow>
              <StCompanyName
                type="text"
                placeholder={transferLanguage_contact[language][19]}
                name="homePageAddress"
                value={inquiryAtom.homePageAddress}
                onChange={onChangeHandler}
              />

              <StFileArea>
                <StFileSelector
                  type="file"
                  name="file"
                  id="file"
                  placeholder={transferLanguage_contact[language][20]}
                  value={inquiryAtom.introduction}
                  onChange={onChangeHandler}
                />
                <StFilelabel htmlFor="file">Choose</StFilelabel>
                <StFileName
                  type="text"
                  id="file_name"
                  placeholder={transferLanguage_contact[language][8]}
                  value={inquiryAtom.file}
                  readOnly
                />
              </StFileArea>
            </StinRow>

            <StAgreementInfo>
              {transferLanguage_contact[language][21]}
              <StThinTxt>
                {transferLanguage_contact[language][22]}
                <br />
                {transferLanguage_contact[language][23]}
                <br />
                {transferLanguage_contact[language][24]}
              </StThinTxt>
            </StAgreementInfo>
            <StContainer>
              <StAgreementInput type="checkbox" />
              {agreement ? (
                <StChecked onClick={() => setAgreement(!agreement)}>
                  <FontAwesomeIcon icon={solid("square-check")} />
                  {transferLanguage_contact[language][25]}
                </StChecked>
              ) : (
                <StUnChecked onClick={() => setAgreement(!agreement)}>
                  <FontAwesomeIcon icon={solid("square-check")} />
                  {transferLanguage_contact[language][25]}
                </StUnChecked>
              )}
              <div>{transferLanguage_contact[language][26]}</div>
            </StContainer>
            <StNextBtn onClick={saveHandler} disabled={!inquiryAtom.name || !inquiryAtom.phoneNumber}>
              {transferLanguage_contact[language][28]}
              <FontAwesomeIcon icon={solid("angles-right")} />
            </StNextBtn>
          </StInputContainer>
        </StWholeDiv>
      </div>
    </div>
  );
};

export default InquiryPageSecond;
const StStepOne = {
  TitleBgImg: styled.div<{ src: string }>`
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: center;

    > .title-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    > .title-area::before {
      content: "";
      width: 4px;
      height: 24px;
      border-radius: 999px;
      display: block;
      background-color: var(--highlight);
      margin-bottom: 8px;
    }
  `,
  TitleText: styled.div`
    font-weight: 700;
    font-size: 3rem;
    color: #fff;
    text-align: center;
  `,
};
const StWholeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 9.375rem;
`;
const StSeqShapes = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 113px;
`;
const StBar = styled.div`
  width: 600px;
  position: relative;
  border-top: 4px solid var(--border);
  height: 30px;

  @media screen and (max-width: 768px) {
    width: 65vw;
  }
`;
const StCircleWrap = styled.div<{ seq: string }>`
  width: 30px;
  height: 30px;
  position: absolute;
  left: ${({ seq }) => {
    switch (seq) {
      case "1":
        return "0%";
      case "2":
        return "50%";
      case "3":
        return "100%";
    }
  }};
  transform: translate(-50%, -50%);
`;
const StCircle = styled.div<{ bg: string }>`
  width: 30px;
  height: 30px;
  background-color: ${({ bg }) => bg};
  border: 1px solid #0b4fd4;
  border-radius: 50%;
`;
const StTxt = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25rem;
  color: var(--primary);
  font-weight: 500;
  white-space: nowrap;
`;
const StInfo = styled.div`
  margin: auto;
  padding-top: 5rem;
  font-size: 24px;
  color: #555555;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  padding-bottom: 5rem;
`;
const StLightTxt = styled.div`
  font-weight: 400;
`;
const StCompanyName = styled.input`
  padding: 14px 0 14px 20px;
  border: var(--border-solid);
  height: 44px;
`;
const StInputContainer = styled.div`
  width: 100%;
  max-width: var(--inner-width-1440);
  padding: var(--inner-padding-1440);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  gap: 16px;
`;
const StFileArea = styled.div`
  display: -webkit-flex;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 16px;
`;
const StFileSelector = styled.input`
  padding: 14px 0 14px 20px;
  border: var(--border-solid);
  display: none;
`;
const StFilelabel = styled.label`
  min-width: 100px;
  height: 44px;
  padding: 10px 14px;
  background: var(--highlight);
  font-weight: 600;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  order: 2;
`;
const StFileName = styled.input`
  width: 100%;
  height: 44px;
  padding: 14px 0 14px 20px;
  background: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  order: 1;
`;
const StNextBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  border: 0;
  background-color: var(--highlight);
  color: white;
  cursor: pointer;
  padding: 11px 2.5rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0 auto;
  margin-top: 5rem;

  > i,
  > svg {
    font-size: 1rem;
  }
`;
const StRadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  color: #555555;
  align-items: center;
`;
const StRadioArea = styled.div`
  position: relative;
  display: -webkit-flex;
  display: flex;
  justify-content: start;
  align-items: center;

  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    cursor: pointer;
    margin-left: 22px;
  }
  input[type="radio"] + label::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: inline-block;
    content: "";
    width: 12px;
    height: 12px;
    border: 1px solid var(--highlight);
    border-radius: 50%;
    text-align: center;
  }
  input[type="radio"] + label::after {
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%) scale(0);
    display: inline-block;
    content: "";
    width: 6px;
    height: 6px;
    border: 1px solid var(--highlight);
    border-radius: 50%;
    background: var(--highlight);
    transition: transform 0.3s ease;
  }
  input[type="radio"]:checked + label::after {
    transform: translateY(-50%) scale(1);
  }
`;
const StinRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 30px;

  @media screen and (max-width: 1200px) {
    gap: 16px 24px;
  }

  @media screen and (max-width: 992px) {
    gap: 16px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: unset;
  }
`;
const StAgreementInput = styled.input`
  display: none;
`;
const StChecked = styled.label`
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: var(--primary);
  cursor: pointer;

  > i,
  > svg {
    font-size: 1.125rem;
    color: var(--highlight);
  }
`;
const StUnChecked = styled.label`
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: var(--primary);
  cursor: pointer;

  > i,
  > svg {
    font-size: 1.125rem;
    color: #999999;
  }
`;
const StAgreementInfo = styled.div`
  border: var(--border-solid);
  border-radius: 8px;
  height: 120px;
  padding: 20px;
  font-weight: bold;
  color: #555555;
  overflow: auto;
`;
const StThinTxt = styled.div`
  font-weight: 400;
  margin-top: 30px;
`;
const StContainer = styled.div`
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: end;
  row-gap: 10px;
`;
