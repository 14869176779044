import React from "react";
import { useNavigate } from "react-router-dom";
import { StAbout, StBrand, StSelect } from "./StAbout";
import img from "../../img/about/ABOUT US/AdobeStock_350484199_2_tiny.jpg";
import logo from "../../img/logo/logo_topRight.svg";
import up from "../img/demo/up.svg";
import down from "../img/demo/down.svg";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../recoil/transferAtom";
import { transferLanguage_head } from "../../components/SelectBox";

const transferLanguage = [
  [
    "ABOUT US",
    "WELTOUCH® brand",
    "WELTOUCH®는 지난 10여년간 우리들의 보다 나은 건강한 생활환경에 필요한 새로운 화학제품의 개발에 노력을 경주하여 왔습니다.",
    "그 결과 WELTOUCH®의 고성능 기술은 일상생활과 산업 전반에 적용되었습니다.",
    "",
    "세계 유수의 브랜드들은 WELTOUCH® 와의 협력을 통해 유해 물질로부터 당신을 보호하는 기능을 갖춘 고성능 제품을 탄생시킵니다.",
    "세계 최고의 브랜드들은 WELTOUCH®의 기술이 훌륭하며, 늘 엄격한 기준으로 성능을 검수하고 있다는 것을 알고 우리를 신뢰합니다.",
    "WELTOUCH®는 흔들리지 않는 믿음을 바탕으로 우리의 삶의 공간에 건강한 환경을 만들어주겠다는 영원한 과제이자 목표를 향해 이순간에도 최선을 다합니다.",
  ],
  [
    "ABOUT US",
    "WELTOUCH® BRAND",
    "WELTOUCH® has devoted itself to developing new chemical products required for a healthier living environment for about 10 years.",
    "As a result, the highly functional technologies of have been applied to daily life and industrial fields.",
    "",
    "Leading global brands have been creating high-performance products protecting us from harmful substances through cooperation with WELTOUCH®.",
    "World-best brands fully trust us because they know WELTOUCH® has been inspecting the performances of technologies with strict criteria while constantly presenting superb technologies.",
    "Based on undisturbed belief, WELTOUCH® thoroughly endeavors to overcome the seemingly permanent challenge and achieve our goal of creating a healthy environment even at this moment.",
  ],
  [
    "INFORMASI UMUM",
    "WELTOUCH® BRAND",
    "WELTOUCH® telah berupaya mengembangkan bahan kimia baru untuk lingkungan hidup kita yang lebih baik dan lebih sehat selama sekitar satu dekade terakhir.",
    "Hasilnya, teknologi kinerja tinggi dapat diterapkan di seluruh kehidupan sehari-hari dan industri.",
    "",
    "Merek terkemuka dunia bekerjasama dengan WELTOUCH® untuk menciptakan produk berkinerja tinggi dengan kemampuan untuk melindungi Anda dari zat berbahaya.",
    "Merek-merek top dunia mempercayai kami dalam pengetahuan bahwa teknologi WELTOUCH® sangat baik dan kinerja selalu diperiksa secara ketat .",
    "Saat ini WELTOUCH® melakukan yang terbaik menuju peran dan tujuan yang konsisten untuk menciptakan lingkungan yang sehat di ruang hidup kita berdasarkan kepercayaan yang tak tergantikan.",
  ],
  [
    "SOBRE NOSOTROS",
    "Marca WELTOUCH®",
    "WELTOUCH® se ha esforzado por desarrollar nuevos productos químicos necesarios para nuestro entorno con una vida mejor y más saludable durante los últimos 10 años.",
    "Como resultado, la tecnología de alto rendimiento de se ha aplicado en la vida diaria y en la industria general.",
    "",
    "Las marcas líderes en el mundo se asocian con WELTOUCH® para crear productos de alto rendimiento con características que le protegen de sustancias nocivas.",
    "Las principales marcas del mundo confían en nosotros sabiendo que la tecnología de WELTOUCH® es excelente y que su rendimiento siempre se prueba con estrictos estándares.",
    "WELTOUCH® está haciendo todo lo posible, incluso en este momento, hacia la tarea y el objetivo eterno de crear un entorno saludable en nuestro espacio de vida diaria basado en una fe inquebrantable.",
  ],
];

const AboutBrand: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/about/${e.target.dataset.value}`);
  };
  return (
    <div
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/AdobeStock_495620064_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white"> {transferLanguage_head[language][0]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][11]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][0]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][11]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="brand" onClick={detailClickHandler}>
                  {transferLanguage_head[language][11]}
                </StSelect.OptionOtherValue>
                {/* <StSelect.OptionOtherValue data-value="relationship" onClick={detailClickHandler}>
                  {transferLanguage_head[language][12]}
                </StSelect.OptionOtherValue> */}
                {/* <StSelect.OptionOtherValue data-value="price" onClick={detailClickHandler}>
                  {transferLanguage_head[language][13]}
                </StSelect.OptionOtherValue> */}
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <StBrand.Container>
        <div className="inner">
          <StBrand.Article>
            <StAbout.Font20 ta="justify">{transferLanguage[language][2]}</StAbout.Font20>
            <StAbout.Font24 ta="justify">
              {transferLanguage[language][3]}
            </StAbout.Font24>
            <StAbout.Font20 ta="justify">{transferLanguage[language][5]}</StAbout.Font20>
            <StAbout.Font20 ta="justify">
              {transferLanguage[language][6]}
              <br />
              {transferLanguage[language][7]}
            </StAbout.Font20>
            <StAbout.FontBold ta="justify">
              All livings cared by <StBrand.Logo src={logo} alt="로고" />
            </StAbout.FontBold>
          </StBrand.Article>
          <StBrand.Img src={img} alt="행복" />
        </div>
      </StBrand.Container>
    </div>
  );
};

export default AboutBrand;
