import { atom } from "recoil";

interface InquiryState {
  interestedField: string;
  detail: string;
  file: string;
  sample: boolean;
  businessType: "publicInstitution" | "individual" | "corporation" | "corporate body";
  companyName: string;
  name: string;
  phoneNumber: string;
  email: string;
  homePageAddress: string;
  introduction: string;
}

export const InquiryAtom = atom<InquiryState>({
  key: "guestInquiry", // 유니크한 이름 짓기
  default: {
    interestedField: "",
    detail: "",
    file: "",
    sample: false,
    businessType: "individual",
    companyName: "",
    name: "",
    phoneNumber: "",
    email: "",
    homePageAddress: "",
    introduction: "",
  }, // default value (aka initial value) 이니셜스테이트
});
