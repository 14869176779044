import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLocation, useNavigate } from "react-router-dom";
import { transferLanguage_head } from "./SelectBox";
import { transferAtom } from "../recoil/transferAtom";
import { useRecoilValue } from "recoil";
import nexchem from "../img/logo/NexCHEM_bottom.png";
import logo from "../img/logo/logo_footer.svg";
import { styled } from "styled-components";
import Inquiry from "./Inquiry";

export const windowScrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const transferLanguage = [
  [
    "Contact us",
    "Contact Information",
    "충북 청주시 흥덕구 봉명로 31 T1타워 402~405호",
    "weltouch@weltouch.net",
    "Tel : +82 43-224-8825",
    "제휴 제안",
  ],
  [
    "Contact us",
    "Contact Information",
    "T1 tower 402~405-ho, 31, Bongmyeong-ro, Heungdeok-gu, Cheongju-si, Chungcheongbuk-do, Republic of Korea",
    "weltouch@weltouch.net",
    "Tel : +82 43-224-8825",
    "Affiliate / Proposal",
  ],
  [
    "menghubungi kami",
    "informasi kontak",
    "T1 tower 402~405-ho, 31, Bongmyeong-ro, Heungdeok-gu, Cheongju-si, Chungcheongbuk-do, Republic of Korea",
    "weltouch@weltouch.net",
    "Tel : +82 43-224-8825",
    "Afiliasi / Proposal",
  ],
  [
    "Contacten con nosotros",
    "información de contacto",
    "T1 tower 402~405-ho, 31, Bongmyeong-ro, Heungdeok-gu, Cheongju-si, Chungcheongbuk-do, Republic of Korea",
    "weltouch@weltouch.net",
    "Tel : +82 43-224-8825",
    "PROPUESTA / ASOCIACIÓN",
  ],
];
const Footer: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [show, setShow] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (pathname !== "/" && !pathname.includes("contact") && !pathname.includes("media")) {
      setShow(true);
    }
  }, []);
  return (
    <>
      {show && <Inquiry></Inquiry>}
      <footer id="footer">
        <div className="inner">
          <div className="footer-content">
            <div className="info">
              <div className="logo-wrap">
                <div className="logo logo-nexchem">
                  <StLogoImg src={nexchem} alt="NexCHEM 로고" />
                </div>
                <div className="logo">
                  <img src={logo} alt="WELTOUCH 로고" />
                </div>
              </div>
              <ul className="ul-info">
                {/* <li>
                <FontAwesomeIcon icon={solid("location-dot")} /> <span>{transferLanguage[language][2]}</span> 
              </li> */}
                <li>
                  <FontAwesomeIcon icon={solid("envelope")} />
                  <a href="mailto:weltouch@weltouch.net">{transferLanguage[language][3]}</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={solid("phone")} />
                  <a href="tel:+82 43-224-8825">{transferLanguage[language][4]}</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={solid("fax")} />
                  Fax : +82 43-224-8826
                </li>
              </ul>
            </div>
            <div className="gnb">
              <ul>
                <li>
                  <a
                    className="gnb_title"
                    onClick={() => {
                      navigate("/about/brand");
                      windowScrollTop();
                    }}
                  >
                    {transferLanguage_head[language][0]}
                  </a>
                  <ul>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/about/brand");
                          windowScrollTop();
                        }}
                      >
                        {" "}
                        {transferLanguage_head[language][11]}
                      </a>
                    </li>
                    {/* <li
                    onClick={() => {
                      navigate("/about/relationship");
                      windowScrollTop();
                    }}
                  >
                    <a> {transferLanguage_head[language][12]}</a>
                  </li> */}
                    {/* <li>
                      <a
                        onClick={() => {
                          navigate("/about/price");
                          windowScrollTop();
                        }}
                      >
                        {" "}
                        {transferLanguage_head[language][13]}
                      </a>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <a
                    className="gnb_title"
                    onClick={() => {
                      navigate("/product/noshine");
                      windowScrollTop();
                    }}
                  >
                    {transferLanguage_head[language][1]}
                  </a>
                  <ul>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/product/noshine");
                          windowScrollTop();
                        }}
                      >
                        {" "}
                        {transferLanguage_head[language][4]}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/product/technical/multi");
                          windowScrollTop();
                        }}
                      >
                        {" "}
                        {transferLanguage_head[language][5]}
                      </a>
                      <ul className="sub-menu-wrap">
                        <li>
                          <a
                            onClick={() => {
                              navigate("/product/technical/multi");
                              windowScrollTop();
                            }}
                          >
                            {" "}
                            {transferLanguage_head[language][6]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate("/product/technical/fresh");
                              windowScrollTop();
                            }}
                          >
                            {" "}
                            {transferLanguage_head[language][7]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate("/product/technical/air");
                              windowScrollTop();
                            }}
                          >
                            {" "}
                            {transferLanguage_head[language][8]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate("/product/technical/water");
                              windowScrollTop();
                            }}
                          >
                            {" "}
                            {transferLanguage_head[language][9]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate("/product/technical/build");
                              windowScrollTop();
                            }}
                          >
                            {" "}
                            {transferLanguage_head[language][10]}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/product/redchec");
                          windowScrollTop();
                        }}
                      >
                        Redchec®
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="gnb_title"
                    onClick={() => {
                      navigate("/media");
                      windowScrollTop();
                    }}
                  >
                    {" "}
                    {transferLanguage_head[language][2]}
                  </a>
                </li>
                <li>
                  <a
                    className="gnb_title"
                    onClick={() => {
                      navigate("/contact/stepOne");
                      windowScrollTop();
                    }}
                  >
                    {" "}
                    {transferLanguage_head[language][3]}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            ©2023 NexCHEM. All rights reserved. <br /> WELTOUCH®, WELTOUCH® INSIDE, and all trademarks and service marks
            are owned by NexCHEM. unless otherwise noted.
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

const StLogoImg = styled.img`
  width: 150px;
  height: 100%;
  margin-right: 24px;
  object-fit: contain;
  display: block;
`;
