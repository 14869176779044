import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import { StEmpty } from "../aboutus/StAbout";
import Footer from "../../components/Footer";

const Product: React.FC = () => {
  return (
    <>
      <Header />
      <StEmpty />
      <div style={{ overflow: "hidden auto", margin: "0 auto" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Product;
