import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { mediaAtom } from "../../recoil/mediaAtom";
import { client } from "../../axios/axios";
import Header from "../../components/Header";
import { StAbout, StEmpty, StSelect } from "../aboutus/StAbout";
import Footer from "../../components/Footer";
import { styled } from "styled-components";
import { transferLanguage_head } from "../../components/SelectBox";
import { transferAtom } from "../../recoil/transferAtom";
import { StBottomArrow } from "../../components/Inquiry";

const transferLanguage = [
  ["제목", "내용"],
  ["Title", "Content"],
  ["Judul", "Konten"],
  ["Título", "Contenido"],
];

const DetailMedia = () => {
  const [data, setData] = useRecoilState(mediaAtom);
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const { id } = useParams();
  const detaildata = data.filter((el) => el?.mnum === +id!)[0];
  const [bigCategory, setBigCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  React.useEffect(() => {
    if (data.length === 0) {
      const fetching = async () => {
        const result = await client.get("/media");
        setData(result.data);
      };
      fetching();
    }
  }, []);
  return (
    <>
      <Header />
      <StEmpty />
      <div onClick={() => setBigCategory(false)}>
        <StAbout.MainImg src="/assets/AdobeStock_285091710_2.jpg">
          <div className="title-area">
            <StAbout.FontBigBold color="white"> {transferLanguage_head[language][2]}</StAbout.FontBigBold>
          </div>
        </StAbout.MainImg>
        <StSelect.SelectBox>
          <div className="inner">
            <StSelect.SelectOption>
              <StSelect.OptionValue
                onClick={(e: any) => {
                  e.stopPropagation();
                  setBigCategory((prev) => !prev);
                }}
              >
                {transferLanguage_head[language][2]}
              </StSelect.OptionValue>
              {bigCategory && (
                <StSelect.OptionBox>
                  <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                    {transferLanguage_head[language][0]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                    {transferLanguage_head[language][1]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                    {transferLanguage_head[language][2]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                    {transferLanguage_head[language][3]}
                  </StSelect.OptionOtherValue>
                </StSelect.OptionBox>
              )}
            </StSelect.SelectOption>
          </div>
        </StSelect.SelectBox>
        <StDeatailMedia.ContentsWrap>
          <div className="inner">
            <StDeatailMedia.TitleBox>
              <span>{detaildata?.title}</span>
            </StDeatailMedia.TitleBox>
            <StDeatailMedia.ContentContainer
              dangerouslySetInnerHTML={{ __html: detaildata?.main_text }}
            ></StDeatailMedia.ContentContainer>
          </div>
        </StDeatailMedia.ContentsWrap>
        <StBottomArrow.Box style={{ marginTop: "-40px" }} onClick={() => window.history.back()}>
          목록으로
        </StBottomArrow.Box>
      </div>
      <Footer />
    </>
  );
};

export default DetailMedia;

const StDeatailMedia = {
  ContentsWrap: styled.main`
    padding: 6.25rem 0 9.375rem;
  `,
  TitleBox: styled.div`
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    font-weight: 600;
    font-size: 1.25rem;
    border-top: 2px solid var(--dark);
    border-bottom: 1px solid var(--border);
  `,
  ContentLabel: styled.p`
    margin: 10px auto;
    font-weight: 500;
    line-height: 1.5;
  `,
  ContentContainer: styled.div`
    padding: 20px 0;
    border-bottom: 1px solid var(--border);

    img {
      max-width: 100%;
    }
  `,
};
