import React from "react";
import { useRecoilState } from "recoil";
import { transferAtom } from "../recoil/transferAtom";
import ko from "../img/icon/kor.png";
import en from "../img/icon/en.png";
import id from "../img/icon/id.png";
import es from "../img/icon/es.png";

import up from "../img/demo/up.svg";
import down from "../img/demo/down.svg";
import { styled } from "styled-components";
export const transferLanguage_head = [
  [
    "회사 소개", //0
    "제품",
    "미디어",
    "문의하기",
    "자기구동형 무광촉매",
    "기술 응용 분야",
    "다목적 복합 필터 기술",
    "신선도 유지 기술",
    "상시 방역 & 공기질 개선 기술",
    "수질 개선 기술",
    "건축 자재 적용 기술", //10

    "WELTOUCH® brand",
    "주요 관계사",
    "인증 & 수상내역",
  ],
  [
    "ABOUT US",
    "Products",
    "Media",
    "Contact Us",
    "Self-actuated Photocatalyst",
    "APPLICATION FIELDS",
    "Multi-purpose composite filter",
    "Maintenance of freshness",
    "Ordinary disease control & air quality improvement",
    "Water quality improvement",
    "Application to construction materials",

    "WELTOUCH® brand",
    "Related Parties",
    "CERTIFICATION & AWARD",
  ],
  [
    "INFORMASI UMUM",
    "Produk",
    "Media",
    "Hubungi kami",
    "Fotokatalis yang digerakkan sendiri",
    "Aplikasi Teknis",
    "Filter multi fungsi",
    "Teknologi Pemeliharaan Kesegaran",
    "Teknologi pencegahan penyakit menular & peningkatan kualitas udara secara konstan.",
    "Teknologi peningkatan kualitas air",
    "Aplikasi pada teknologi bahan konstruksi",

    "WELTOUCH® brand",
    "Afiliasi ( Kerjasama )",
    "Sertifikasi & Penghargaan",
  ],
  [
    "SOBRE NOSOTROS",
    "Productos",
    "Medios",
    "Contacto",
    "Fotocatalizador auto accionado",
    "Campo de aplicaciones tecnológicas",
    "Filtro compuesto de usos múltiples",
    "Tecnología de conservación de la frescura.",
    "Tecnología de mejora de calidad del aire y cuarentena constante",
    "Tecnología de mejora de la calidad del agua",
    "Tecnología de aplicación en materiales de construcción.",

    "WELTOUCH® brand",
    "Afiliados principals",
    "Premios y certificación",
  ],
];

const SelectBox: React.FC = () => {
  const [modal, setModal] = React.useState(false);
  const [language, setLanguages] = useRecoilState(transferAtom);
  return (
    <StSelect.Wrap>
      <StSelect.Container className="lang" onClick={() => setModal((prev) => !prev)}>
        {+language === 0 ? (
          <StSelect.SelectBox>
            <img src={ko} alt="한국어" />
            한국어
          </StSelect.SelectBox>
        ) : +language === 1 ? (
          <StSelect.SelectBox>
            <img src={en} alt="English" />
            English
          </StSelect.SelectBox>
        ) : +language === 2 ? (
          <StSelect.SelectBox>
            <img src={id} alt="Bahasa Indonesia" />
            Bahasa Indonesia
          </StSelect.SelectBox>
        ) : (
          <StSelect.SelectBox>
            <img src={es} alt="Español" />
            Español
          </StSelect.SelectBox>
        )}
        {modal ? <img src={up} alt="up" /> : <img src={down} alt="down" />}
      </StSelect.Container>
      {modal && (
        <StSelect.Modal>
          <StSelect.SelectBox
            onClick={() => {
              setLanguages(0);
              setModal(false);
            }}
          >
            <img src={ko} alt="한국어" />
            한국어
          </StSelect.SelectBox>
          <StSelect.SelectBox
            onClick={() => {
              setLanguages(1);
              setModal(false);
            }}
          >
            <img src={en} alt="English" />
            English
          </StSelect.SelectBox>
          <StSelect.SelectBox
            onClick={() => {
              setLanguages(2);
              setModal(false);
            }}
          >
            <img src={id} alt="Bahasa Indonesia" />
            Bahasa Indonesia
          </StSelect.SelectBox>
          <StSelect.SelectBox
            onClick={() => {
              setLanguages(3);
              setModal(false);
            }}
          >
            <img src={es} alt="Español" />
            Español
          </StSelect.SelectBox>
        </StSelect.Modal>
      )}
    </StSelect.Wrap>
  );
};

export default SelectBox;

const StSelect = {
  Wrap: styled.div`
    height: var(--header-height);
    position: relative;

    @media (max-width: 768px) {
      padding: 0 16px;
    }
  `,
  Container: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  SelectBox: styled.div`
    display: flex;
    align-items: center;
    min-width: 120px;
    line-height: 14px;
    cursor: pointer;
  `,
  Modal: styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background-color: white;
    border: 1px solid var(--border);
  `,
};
