import React from "react";
import { styled } from "styled-components";
interface props {
  title: string;
  img2: string;
  img3: string;
  img4: string;
  img5: string;
  explain?: string;
}
const AirExample: React.FC<props> = ({ title, img2, img3, img4, img5, explain }) => {
  return (
    <StAirExample.Container>
      <StAirExample.BoxContainer>
        <span className="case">{title}</span>
        <StAirExample.Box>
          <img src={img2} alt="이미지 2" />
          <img src={img3} alt="이미지 3" />
          <img src={img4} alt="이미지 4" />
          <img src={img5} alt="이미지 5" />
        </StAirExample.Box>
      </StAirExample.BoxContainer>
      {explain && <StAirExample.Explain>{explain}</StAirExample.Explain>}
    </StAirExample.Container>
  );
};

export default AirExample;

const StAirExample = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 3.75rem;
  `,
  BoxContainer: styled.div`
    text-align: center;

    .case {
      display: inline-block;
      font-family: "Pretendard";
      background-color: #ddd;
      text-align: center;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.5;
      margin-bottom: 12px;
      box-shadow: -5px 0 0 #ddd, 5px 0 0 #ddd;
    }
  `,
  Box: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;

    img {
      width: calc((100% - 120px)/5);
      height: 286px;
      object-fit: cover;
      border-radius: 20px;
      box-shadow: var(--box-shadow0014);
    }

    @media screen and (max-width:768px){
      img {
        width: 46%;
      }
    }

    @media screen and (max-width:480px){
      img {
        width: 100%;
      }
    }
  `,
  Explain: styled.p`
    font-weight: 300;
    font-size: 1.25rem;
    color: var(--primary);
    text-align: center;
    margin-top: 2.5rem;
  `,
};
