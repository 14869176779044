import React from "react";
import { StCommon } from "./Common";
import { styled } from "styled-components";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../recoil/transferAtom";
import { transferLanguage_redchec } from "../../pages/product/Redchec";
import { StWaterSecond } from "./WaterSecond";

import img1_kr from "../../img/products/Redchec/redchec_kr.png";
import img1_en from "../../img/products/Redchec/redchec_en.png";
import img1_ind from "../../img/products/Redchec/redchec_ind.png";
import img1_es from "../../img/products/Redchec/redchec_es.png";
import check from "../../img/products/techapply/data2/check-solid.svg";

const transferImg = [img1_kr, img1_en, img1_ind, img1_es];
const RedchecSecond = () => {
  const language = useRecoilValue(transferAtom);
  return (
    <StCommon.Container>
      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>
            {/* Redchec® 란? */}
            {transferLanguage_redchec[language][4]}
          </StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <ul>
            <StRedchecSecond.StextLine>
              <img src={check} alt="체크" />
              {transferLanguage_redchec[language][10]}
            </StRedchecSecond.StextLine>
            <StRedchecSecond.StextLine>
              <img src={check} alt="체크" />
              {transferLanguage_redchec[language][11]}
            </StRedchecSecond.StextLine>
          </ul>
          <StRedchecSecond.Img3 src={transferImg[language]} alt="" />
        </StCommon.AirBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          {/* Redchec® 시연 영상 */}
          <StCommon.Title>{transferLanguage_redchec[language][12]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StWaterSecond.VideoWrap>
          <StWaterSecond.VideoArea>
            {language === 0 && (
              <video controls width="1000" height="562.5" src="/assets/RedchecVideo/Redchec.mp4" typeof="video/mp4" />
            )}
            {language === 1 && (
              <video
                controls
                width="1000"
                height="562.5"
                src="/assets/RedchecVideo/Redchec_en.mp4"
                typeof="video/mp4"
              />
            )}
            {language === 2 && (
              <video
                controls
                width="1000"
                height="562.5"
                src="/assets/RedchecVideo/Redchec_ind.mp4"
                typeof="video/mp4"
              />
            )}
            {language === 3 && (
              <video
                controls
                width="1000"
                height="562.5"
                src="/assets/RedchecVideo/Redchec_es.mp4"
                typeof="video/mp4"
              />
            )}
          </StWaterSecond.VideoArea>
        </StWaterSecond.VideoWrap>
      </StCommon.CoatingBox>
    </StCommon.Container>
  );
};

export default RedchecSecond;

const StRedchecSecond = {
  Box2: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 3.75rem;
    p {
      padding: 0 2.5rem;
      width: 1015px;
      font-weight: 600;
      font-size: 1.25rem;
    }
  `,
  Box2Inner: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    p {
      padding: 0 2.5rem;
      font-weight: 600;
      font-size: 1.25rem;
    }
    li {
      width: 745px;
      list-style: disc;
      margin-top: 2.5rem;
    }
  `,
  Img1: styled.img`
    width: 645px;
    height: 400px;
    object-fit: contain;
    display: block;
  `,
  Img3: styled.img`
    width: 458px;
    height: 318px;
    object-fit: contain;
    display: block;
  `,
  StextLine: styled.li`
    display: flex;
    gap: 10px;
    align-items: start;
    font-weight: 300;
    font-size: 1.25rem;
    color: var(--primary);
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    > img {
      margin-top: calc((1.25rem * 1.4 - 14px) / 2);
    }
  `,
};
