import React from "react";
import { useNavigate } from "react-router-dom";
import { StAbout, StPrice, StSelect } from "./StAbout";
import clock from "../../img/icon/fa-solid fa-clock.png";
import { transferLanguage_head } from "../../components/SelectBox";
import { transferAtom } from "../../recoil/transferAtom";
import { useRecoilValue } from "recoil";
const AboutPrize: React.FC = () => {
  const navigate = useNavigate();
  const language = useRecoilValue(transferAtom);
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/about/${e.target.dataset.value}`);
  };
  return (
    <div
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/CK_tip034b14080351_2_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white"> {transferLanguage_head[language][0]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][13]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][0]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][13]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="brand" onClick={detailClickHandler}>
                  {transferLanguage_head[language][11]}
                </StSelect.OptionOtherValue>
                {/* <StSelect.OptionOtherValue data-value="relationship" onClick={detailClickHandler}>
                  {transferLanguage_head[language][12]}
                </StSelect.OptionOtherValue> */}
                <StSelect.OptionOtherValue data-value="price" onClick={detailClickHandler}>
                  {transferLanguage_head[language][13]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <StPrice.Container>
        <StPrice.ComingImg src={clock} alt="time" />
        <span>Coming Soon</span>
      </StPrice.Container>
    </div>
  );
};

export default AboutPrize;
