import React from "react";
import { StCommon } from "./Common";
import { styled } from "styled-components";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../recoil/transferAtom";

import blockKr from "../../img/products/techapply/data1/block.png";
import blockEn from "../../img/products/techapply/data1/block_eng.png";
import blockInd from "../../img/products/techapply/data1/block_IND.png";
import blockEs from "../../img/products/techapply/data1/block_ES.png";

import pannelKr from "../../img/products/techapply/data1/panel.png";
import pannelEn from "../../img/products/techapply/data1/panel_eng.png";
import pannelInd from "../../img/products/techapply/data1/panel_IND.png";
import pannelEs from "../../img/products/techapply/data1/panel_ES.png";

import img5 from "../../img/products/techapply/data1/Super-hydrophile.jpg";
import { transferLanguage_build } from "../../pages/product/technical/Build";
const transferImg = [
  [blockKr, pannelKr],
  [blockEn, pannelEn],
  [blockInd, pannelInd],
  [blockEs, pannelEs],
];
const BuildSecond = () => {
  const language = useRecoilValue(transferAtom);
  return (
    <StCommon.Container>
      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>
            {/* WELTOUCH® Self cleaning 효과 */}
            {transferLanguage_build[language][12]}
          </StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <StCommon.AirText>{transferLanguage_build[language][15]}</StCommon.AirText>
          <StCommon.AirImg src={transferImg[language][0]} alt="" />
        </StCommon.AirBox>
        <StCommon.AirBox>
          <StCommon.AirText>{transferLanguage_build[language][16]}</StCommon.AirText>
          <StCommon.AirImg src={transferImg[language][1]} alt="" />
        </StCommon.AirBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_build[language][17]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <StCommon.AirText>{transferLanguage_build[language][18]}</StCommon.AirText>
          <StCommon.AirImg src={img5} alt="" />
        </StCommon.AirBox>
      </StCommon.CoatingBox>
    </StCommon.Container>
  );
};

export default BuildSecond;

const StBuildSecond = {
  Box1: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3.75rem;
    margin-top: 3.75rem;
    
    >* {width: calc((100% - 30px)/2);}
    
    @media screen and (max-width:768px){
      flex-direction: column;

      >* {width: 100%;}
    }
  `,
  Box2: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 3.75rem;

    p {
      padding: 0 2.5rem;
      width: 1015px;
      font-weight: 600;
      font-size: 1.25rem;
    }
  `,
  Box2Inner: styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;

    p {
      padding: 0 2.5rem;

      font-weight: 600;
      font-size: 1.25rem;
    }
  `,
  Img1: styled.img`
    max-width: 500px;
    height: 400px;
    object-fit: cover;
    display: block;
    border-radius: 20px;
    box-shadow: var(--box-shadow0014);
  `,
  Img3: styled.img`
    max-width: 500px;
    height: 300px;
    object-fit: cover;
    display: block;
    border-radius: 20px;
    box-shadow: var(--box-shadow0014);
  `,
};
