import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { StAbout, StArrow, StSelect } from "../aboutus/StAbout";
import { useNavigate } from "react-router-dom";
import { StNoshine, StProduct } from "./StProduct";
import img from "../../img/products/data6/AdobeStock_300766954.jpg";
import arrow from "../../img/icon/arrow-right-long-white.png";
//effect
import effectKo from "../../img/products/data6/effect_ko.png";
import effectEn from "../../img/products/data6/effect_en.png";
import effectIn from "../../img/products/data6/effect_ind.png";
import effectSp from "../../img/products/data6/effect_es.png";
import effectKo_2x from "../../img/products/data6/effect_ko@2x.png";
import effectEn_2x from "../../img/products/data6/effect_en@2x.png";
import effectIn_2x from "../../img/products/data6/effect_ind@2x.png";
import effectSp_2x from "../../img/products/data6/effect_es@2x.png";

import effectKoM from "../../img/products/data6/effect_ko_m.png";
import effectEnM from "../../img/products/data6/effect_en_m.png";
import effectInM from "../../img/products/data6/effect_ind_m.png";
import effectSpM from "../../img/products/data6/effect_es_m.png";
import effectKoM_2x from "../../img/products/data6/effect_ko_m@2x.png";
import effectEnM_2x from "../../img/products/data6/effect_en_m@2x.png";
import effectInM_2x from "../../img/products/data6/effect_ind_m@2x.png";
import effectSpM_2x from "../../img/products/data6/effect_es_m@2x.png";

import multi from "../../img/products/data6/AdobeStock_423654356_tiny.jpg";
import fresh from "../../img/products/data6/AdobeStock_270639335-scaled.jpg";
import air from "../../img/products/data6/AdobeStock_370722965_tiny.jpg";
import build from "../../img/products/data6/AdobeStock_273796762_tiny.jpg";
import water from "../../img/products/data6/AdobeStock_261344590.jpg";
import { transferAtom } from "../../recoil/transferAtom";
import { useRecoilValue } from "recoil";

import ban_g from "../../img/products/data6/ban_g.png";
import ban_w from "../../img/products/data6/ban_w.png";
import light_w from "../../img/products/data6/ligth_w.png";
import light_g from "../../img/products/data6/light_g.png";
import moon_w from "../../img/products/data6/moon_w.png";
import { windowScrollTop } from "../../components/Footer";
import { transferLanguage_head } from "../../components/SelectBox";

const transferLanguage = [
  [
    "자기구동형 무광촉매", //0
    "광촉매 초격차(Super gap) 기술 “자기구동형 무광촉매 WELTOUCH®”",
    "20년이 넘는 시간동안 다양한 분야에 적용되고 있는 WELTOUCH®는 초격차(Super gap) 광촉매 기술입니다. 빛의 유·무와 상관없이 공기중의 산소(O₂)와 습도(H₂O)만 있다면, 조건의 제약없이 최고의 광촉매 성능을 발휘합니다.",
    "자기구동형 무광촉매 WELTOUCH®는 일반적인 광촉매와 달리 응집된 이산화티타늄 입자를 단순히 분산시킨 형태가 아닌 망상구조로 수분산시켜 생산한 제품으로 광촉매 기술 초격차(Super gap)를 이끌고 있습니다.",
    "햇빛이나 형광등 불빛과 같은 광에너지는 물론 활용 가능하며 추가적인 에너지(적외선, 열에너지)까지 활용하고 이를 이용하여 포름알데히드와 VOCs (휘발성유기화합물)과 같은 오염물질을 산화·분해시켜 무해한 물과 탄산가스로 변화시켜 실내 공기를 정화시킬 뿐만 아니라 강력한 산화·환원반응으로 항균, 살균, 탈취, 방오 등의 다양한 분야에 적용할 수 있는 기술입니다.",
    "제품 문의",
    "WELTOUCH® 특징",
    "1세대",
    "2세대",
    "3세대",
    "광촉매", //10
    "가시광촉매",
    "자기구동형 무광촉매",
    "Driving force",
    "자외선",
    "가시광선",
    "열에너지",
    "적외선",
    "사용 범위",
    "Outdoor",
    "Indoor", //20
    "조명(visible light)",
    "무광",
    "WELTOUCH®는 기존 광촉매들이 사용하던 에너지(자외선, 가시광)는 물론 활용 가능하며, 새로운 에너지(적외선·열에너지)까지 활용하는 새로운 차원의 무광(無光)촉매입니다.",
    "WELTOUCH® 효과",
    "항바이러스, 항균",
    "병원성 세균, 바이러스, 곰팡이의 제거 및 예방",
    "미세먼지 제거",
    "NOx, SOx 등 미세먼지 원인 물질 제거",
    "탈취",
    "5대 생활 악취제거, 공기 정화 기능, 찌든 담배냄새 제거, 에틸렌 가스 제거, 악취 원인 물질 제거",
    "방오", //30
    "오염물질 부착 방지, 산화 변색 방지",
    "유해물질",
    "VOCs, 포름알데히드 등 난분해성 유해물질의 산화•분해",
    "기술 응용 분야",
    "다목적 복합 필터",
    "WELTOUCH®를 Filter에 적용하여 각종 유해물질 부터 악취, 세균 및 바이러스까지 차단해주는 WELTOUCH® Coating Filter로 재탄생 시킵니다.",
    "신선도 유지 기술",
    "WELTOUCH®는 종이, 박스 등과 같은 소재에도 다양하게 적용이 가능해 음식의 패키지 용기(트레이)에 사용하여 음식의 부패방지 효과를 부여합니다.",
    "상시 방역 & 공기질 개선 기술",
    "건축 내장재 및 자동차 내장재에서 발생하여 새집증후군, 새가구증후군이나 새차증후군을 유발하는 포름알데히드, VOCs등을 빠르게 분해·제거하여 새집증후군, 새가구증후군이나 새차증후군을 예방하는 기술 입니다.",
    "수질 개선 기술",
    "수질분야에 사용되는 수처리 여재에 WELTOUCH®를 적용하여 물에 포함되어 있는 인 성분을 분해·제거 하고, 유수분리를 하여 오일 성분을 분리하여 줍니다.",
    "건축 자재 적용 기술",
    "건축자재 및 가구 소재에 WELTOUCH®를 적용하면 부가적인 기능이 발휘되어 제품을 업그레이드 시켜줍니다. 습하고 더운 환경에서 건축자재 표면에 생기는 곰팡이균을 억제하여 건축자재의 오염을 막아주고 , 수명을 늘려줍니다.",
    "더 알아보기",
  ],
  [
    "Self-actuated Photocatalyst",
    "“Self-actuated Photocatalyst WELTOUCH®”, the super gap photocatalyst technology",
    "WELTOUCH® has been applied in various fields for about 20 years and is the super gap photocatalyst technology. With only oxygen (O₂) and humidity (H₂O) in the air with or without light, WELTOUCH® shows the best photocatalyst performance under any conditions.",
    "WELTOUCH®, the self-actuated photocatalyst, is the leading super gap photocatalyst technology as the product is manufactured not by simply dispersing aggregated titanium dioxide particles, unlike general photocatalyst, but through aqueous dispersion in a web formation.",
    "WELTOUCH® uses optical energy like sunlight or fluorescent light or diverse energies (infrared rays, thermal energy) and purifies the indoor air by oxidating and dissolving pollutants, such as formaldehyde and VOCs (volatile organic compounds) into harmless water and carbon dioxide. Moreover, WELTOUCH® can be applied in a wide range of fields, including anti-bacterial application, sterilization, deodorization, and anti-staining function using the powerful oxidation-reduction reaction.",
    "Product inquiry",
    "Features of WELTOUCH®",
    "First generation",
    "Second generation",
    "third generation",
    "Photocatalyst",
    "Visible-light responsive photocatalyst",
    "Self-actuated photocatalyst",
    "Driving force",
    "Ultraviolet rays",
    "Visible rays",
    "Thermal energy",
    "Infrared rays",
    "Scope of use",
    "Outdoor",
    "Indoor",
    "Illumination (visible light)",
    "No light",
    "WELTOUCH® is the photocatalyst of a new dimension, using energies (ultraviolet rays, visible rays) used by existing photocatalysts and new energies (infrared rays, thermal energy).",
    "Effects of WELTOUCH®",
    "Anti-viral and anti-bacterial functions",
    "Elimination and prevention of pathogenic germs, viruses, and fungi.",
    "Clearance of particulate matters",
    "Elimination of causative substances of particulate matter, including NOx and SOx.",
    "Deodorization",
    "Clearance of five offensive odors in daily life, air purification, elimination of ingrained odors of cigarette/ethylene gas/odor-causing substances",
    "Anti-staining function",
    "Prevention of adhesion of pollutants/oxidative stains",
    "Harmful substances",
    "Oxidation-dissolution of non-degradable harmful substances, including VOCs and formaldehyde",
    "Application Fields",
    "Multi-purpose composite filter",
    "WELTOUCH® Coating Filter is created by applying WELTOUCH® to a filter. It blocks offensive odors, germs, viruses, and a variety of other harmful substances.",
    "Maintenance of freshness",
    "WELTOUCH® can be applied to diverse surfaces, including paper and boxes. When WELTOUCH® is applied to food containers (trays), it prevents food spoilage.",
    "Ordinary disease control & air quality improvement",
    "WELTOUCH® prevents sick house syndrome, sick furniture syndrome, or sick car syndrome by quickly decomposing and eliminating formaldehyde and VOCs that cause sick house syndrome, sick furniture syndrome, or sick car syndrome from the interior materials.",
    "Water quality improvement",
    "WELTOUCH®is applied to water treatment mediums and used in the water quality field, separates oil-based substances, and decomposes and eliminates phosphorous from water.",
    "Application to construction materials",
    "WELTOUCH® applied to construction materials or furniture upgrades the products as additional functions are activated. WELTOUCH® inhibits mold formation on the surface of construction materials in humid and hot environments, prevents construction materials pollution, and extends the life of construction materials.",
    "Discover more",
  ],
  [
    "Fotokatalis yang digerakkan sendiri",
    "Teknologi fotokatalis supergap “WELTOUCH® fotokatalis yang digerakkan sendiri”",
    "WELTOUCH® adalah teknologi supergap yang telah diterapkan diberbagai bidang selama lebih dari 20 tahun. Dengan atau tanpa cahaya, selama ada oksigen (O₂) dan kelembaban (H₂O) di udara, ia mencapai kinerja fotokatalitik terbaik tanpa batasan kondisi.",
    "Tidak seperti fotokatalis konvensional, fotokatalis self-actuated WELTOUCH® adalah produk yang dihasilkan dengan mendispersikan partikel titanium dioksida agregat ke dalam struktur jaringan daripada hanya menyebarkannya, yang mengarah ke supergap dalam teknologi fotokatalis.",
    "Ini menggunakan energi cahaya seperti sinar matahari dan lampu neon, serta energi tambahan (energi inframerah dan panas), dan menggunakannya untuk mengoksidasi dan menguraikan polutan seperti formaldehida dan VOC (senyawa organik yang mudah menguap) untuk memurnikan udara dalam ruangan menjadi air dan karbon yang tidak berbahaya. dioksida, serta untuk diterapkan pada berbagai bidang seperti antibakteri, sterilisasi, penghilang bau, dan pencegahan.",
    "Pertanyaan produk",
    "Fitur WELTOUCH®",
    "Generasi pertama",
    "Generasi kedua",
    "Generasi ketiga",
    "Fotokatalis",
    "Fotokatalis yang cahaya tampak",
    "Fotokatalis yang digerakkan sendiri",
    "Driving force",
    "Ultraviolet",
    "Cahaya tampak",
    "Energi panas",
    "inframerah",
    "Rentang penggunaan",
    "Dalam ruangan",
    "Di luar ruangan",
    "Pencahayaan(visible light)",
    "Tidak ada cahaya",
    "WELTOUCH® adalah tingkat baru fotokatalis yang digerakkan sendiri yang memanfaatkan energi (ultraviolet, cahaya tampak) yang digunakan oleh fotokatalis yang ada serta energi baru (energi inframerah dan panas).",
    "Efek WELTOUCH®",
    "Anti virus, Anti bakteri",
    "Eliminasi dan pencegahan bakteri patogen, virus, dan jamur",
    "Menghilangkan debu halus",
    "penghapusan zat halus penyebab debu seperti NOx dan Sox",
    "menghilangkan bau",
    "5 penghilangan bau kehidupan utama, fungsi pemurnian udara, penghilangan bau rokok, penghilangan gas etilen, penghilangan zat penyebab bau",
    "Anti Tanah",
    "Pencegahan adhesi kontaminan dan pencegahan perubahan warna oksidatif",
    "Zat berbahaya ",
    "Oksidasi/dekomposisi zat berbahaya yang tidak dapat terurai seperti VOC dan formaldehida dll",
    "Aplikasi Teknis",
    "Filter multi fungsi",
    "Oleskan WELTOUCH® ke filter dan lakukan ulang dengan filter lapisan WELTOUCH®, yang memblokir bau , bakteri, dan virus",
    "Teknologi Pemeliharaan Kesegaran",
    "WELTOUCH® juga dapat diaplikasikan pada bahan-bahan seperti kertas, kotak, dll, dan dapat digunakan dalam wadah kemasan (tray) makanan untuk memberikan efek anti pembusukan.",
    "Teknologi pencegahan penyakit menular & peningkatan kualitas udara secara konstan.",
    "Ini adalah teknologi untuk mencegah sindrom rumah baru, sindrom furnitur baru, dan sindrom mobil baru dengan cepat menguraikan dan menghilangkan formaldehida dan VOC yang menyebabkan sindrom rumah baru, sindrom furnitur baru, atau sindrom mobil baru.",
    "Teknologi peningkatan kualitas air",
    "WELTOUCH® diterapkan pada media filter pengolahan air yang digunakan di bidang kualitas air untuk menguraikan dan menghilangkan fosfor yang terkandung dalam air serta memisahkan minyak dan air.",
    "Aplikasi pada teknologi bahan konstruksi",
    "Ketika WELTOUCH® diterapkan pada bahan bangunan dan bahan furnitur, fitur tambahan diterapkan untuk meningkatkan produk. Ini mencegah kontaminasi bahan bangunan dan memperpanjang umurnya dengan menekan bakteri jamur pada permukaan bahan bangunan di lingkungan yang lembab dan panas.",
    "Pelajari lebih lanjut",
  ],
  [
    "Fotocatalizador auto accionado",
    "“Fotocatalizador auto accionado WELTOUCH®” con Tecnología de Fotocatalizador súper brecha",
    "WELTOUCH®, que se ha aplicado en varios campos durante más de 20 años, es una tecnología de fotocatalizador de súper brecha. Independientemente de la presencia o ausencia de luz, siempre que haya oxígeno (O₂) y humedad (H₂O) en el aire, el fotocatalizador presenta el mejor rendimiento independientemente de las condiciones.",
    "A diferencia de los fotocatalizadores generales, El WELTOUCH® fotocatalizador auto accionado es un producto producido mediante la dispersión acuosa de partículas de dióxido de titanio agregadas en una estructura de red en lugar de simplemente dispersarlas, lo que encabeza la gran brecha en la tecnología de fotocatalizadores.",
    "Se puede utilizar energía luminosa, como la luz solar o la luz fluorescente, así como energía adicional (rayos infrarrojos, energía térmica) y, al usarla, los contaminantes como el formaldehído y los COV (compuestos orgánicos volátiles) se oxidan y se descomponen en agua inofensiva y dióxido de carbono y es una tecnología que no solo purifica el aire interior al cambiarlo, sino que también se puede aplicar en varios campos, como antibacteriano, esterilización, desodorización y antiincrustante con fuertes reacciones de oxidación y reducción, etc.",
    "Consulta del producto",
    "CARACTERÍSTICAS DE WELTOUCH®",
    "1generación",
    "2generación",
    "3generación",
    "Fotocatalizador",
    "Fotocatalizador de luz visible",
    "Fotocatalizador auto accionado",
    "Driving force",
    "rayos ultravioletas",
    "rayo visible",
    "energía térmica",
    "rayos infrarrojos",
    "Rango de uso",
    "Interior",
    "exterior",
    "iluminación (luz visible)",
    "sin luz",
    "WELTOUCH® es un nuevo nivel de catalizador no luminoso que utiliza no solo la energía (rayos ultravioletas y luz visible) utilizada por los fotocatalizadores existentes, sino también nueva energía (rayos infrarrojos y energía térmica).",
    "EFECTO DE WELTOUCH®",
    "Antiviral, antibacteriano",
    "Eliminación y prevención de bacterias, virus y hongos patógenos",
    "Eliminación de polvo fino",
    "Eliminación de sustancias que generan polvo fino como NOx y Sox, etc.",
    "Desodorante",
    "Eliminación de 5 olores de la vida diaria, función de purificación de aire, eliminación de olores de cigarrillos apestosos, eliminación de gas etileno, eliminación de sustancias que causan olores",
    "Antiincrustante",
    "Prevención de la adhesión de contaminantes, prevención de la decoloración oxidativa",
    "Sustancias tóxicas",
    "Oxidación y descomposición de sustancias nocivas difíciles de descomponer como COV y formaldehído",
    "Ámbito de aplicaciones tecnológicas",
    "Filtro compuesto de usos múltiples",
    "Aplicando WELTOUCH® al filtro, renace como un filtro de revestimiento WELTOUCH® que bloquea diversas sustancias nocivas y hasta olores, gérmenes y virus.",
    "Tecnología de conservación de la frescura.",
    "WELTOUCH® se puede aplicar de varias maneras a materiales como papel y cajas, por lo que se utiliza para envases de alimentos (bandejas) para dar efectos de prevención del deterioro de los alimentos.",
    "Tecnología de mejora de calidad del aire y cuarentena constante",
    "Es una tecnología para prevenir el síndrome de la casa nueva, el síndrome del mueble interior o el síndrome del automóvil nuevo al descomponer y eliminar rápidamente el formaldehído y VOCs que se producen en los materiales del interior de la construcción y los materiales del interior del automóvil.",
    "Tecnología de mejora de la calidad del agua",
    "Al aplicar WELTOUCH® a los medios filtrantes de tratamiento de agua utilizados en el campo de la calidad del agua, se descompone y elimina el componente de fósforo contenido en el agua y separa los componentes del aceite a través de la separación de aceite y agua.",
    "Tecnología de aplicación en materiales de construcción.",
    "Cuando WELTOUCH® se aplica a materiales de construcción y muebles, se muestran funciones adicionales y actualizan los productos. Previene la contaminación de los materiales de construcción al suprimir los hongos que se forman en la superficie de los materiales de construcción en un ambiente húmedo y caluroso y extiende su vida útil.",
    "Leer más",
  ],
];

const Noshine: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
    windowScrollTop();
  };
  const detailClickHandler = (e: any) => {
    navigate(`/product/${e.target.dataset.value}`);
    windowScrollTop();
  };
  const goContact = () => {
    navigate("/contact/stepOne");
    windowScrollTop();
  };
  const goFilter = () => {
    navigate("/product/technical/multi");
    windowScrollTop();
  };
  const goFresh = () => {
    navigate("/product/technical/fresh");
    windowScrollTop();
  };
  const goAir = () => {
    navigate("/product/technical/air");
    windowScrollTop();
  };
  const goWater = () => {
    navigate("/product/technical/water");
    windowScrollTop();
  };
  const goBuild = () => {
    navigate("/product/technical/build");
    windowScrollTop();
  };
  return (
    <div
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/AdobeStock_286304472_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white">{transferLanguage_head[language][1]}</StAbout.Font20>
          <StAbout.FontBigBold color="white">{transferLanguage[language][0]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][1]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][4]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="noshine" onClick={detailClickHandler}>
                  {transferLanguage_head[language][4]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][5]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="redchec" onClick={detailClickHandler}>
                  Redchec®
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <StProduct.Container>
        <StProduct.FlexCenter>
          <StAbout.FontBigBold inner="var(--inner-padding-1440)">{transferLanguage[language][0]}</StAbout.FontBigBold>
        </StProduct.FlexCenter>
        <StProduct.Article>
          <StProduct.Img500 src={img} alt="사진" />
          <StProduct.Gap60>
            <StAbout.FontBold ta="right">{transferLanguage[language][1]}</StAbout.FontBold>
            <StAbout.Font20 ta="right">{transferLanguage[language][2]}</StAbout.Font20>
            <StAbout.Font20 ta="right">{transferLanguage[language][3]}</StAbout.Font20>
          </StProduct.Gap60>
        </StProduct.Article>
        <StNoshine.Container bgcolor="white" pt="60px">
          <div className="inner">
            <StAbout.Font20 ta="center">
              <div style={{ width: "80%", margin: "0 auto" }}>{transferLanguage[language][4]}</div>
            </StAbout.Font20>
            <StArrow.Box type="button" bgcolor="#00AFC8" onClick={goContact}>
              {transferLanguage[language][5]} <div className="arrow"></div>
            </StArrow.Box>
          </div>
        </StNoshine.Container>
        <StNoshine.Container bgcolor="#F9F9F9">
          <div className="inner">
            <StAbout.FontBigBold ta="center">{transferLanguage[language][6]}</StAbout.FontBigBold>
            <StNoshine.GenerationBox>
              <StNoshine.Box bgcolor="white" width="20%">
                <StAbout.FontMidium ta="center" color="var(--highlight)">
                  {transferLanguage[language][7]}
                  <br />
                  {transferLanguage[language][10]}
                </StAbout.FontMidium>
                <StNoshine.IconBox>
                  <img src={light_g} alt="" />
                </StNoshine.IconBox>
                <StNoshine.TextBox>
                  <p>
                    {transferLanguage[language][13]}
                    <br />
                    {transferLanguage[language][14]}
                  </p>
                  <br />
                  <p>
                    {transferLanguage[language][18]}
                    <br />
                    {transferLanguage[language][19]}
                  </p>
                </StNoshine.TextBox>
              </StNoshine.Box>
              <StNoshine.Arrow>
                <FontAwesomeIcon icon={solid("angle-right")} />
              </StNoshine.Arrow>
              <StNoshine.Box bgcolor="white" width="25%">
                <StAbout.FontMidium ta="center" color="var(--highlight)">
                  {transferLanguage[language][8]}
                  <br />
                  {transferLanguage[language][11]}
                </StAbout.FontMidium>
                <StNoshine.IconBox>
                  <img src={light_g} alt="" />
                  <img src={ban_g} alt="" />
                </StNoshine.IconBox>
                <StNoshine.TextBox>
                  <p>
                    {transferLanguage[language][13]}
                    <br />
                    {transferLanguage[language][14]}, {transferLanguage[language][15]}
                  </p>
                  <br />
                  <p>
                    {transferLanguage[language][18]}
                    <br />
                    {transferLanguage[language][19]}, {transferLanguage[language][20]},
                    <br />
                    {transferLanguage[language][21]}
                  </p>
                </StNoshine.TextBox>
              </StNoshine.Box>
              <StNoshine.Arrow>
                <FontAwesomeIcon icon={solid("angle-right")} />
              </StNoshine.Arrow>
              <StNoshine.Box bgcolor="#00AFC8" color="white" width="30%">
                <StAbout.FontMidium ta="center" color="#fff">
                  {transferLanguage[language][9]}
                  <br />
                  {transferLanguage[language][12]}
                </StAbout.FontMidium>
                <StNoshine.IconBox>
                  <img src={light_w} alt="" />
                  <img src={ban_w} alt="" />
                  <img src={moon_w} alt="" />
                </StNoshine.IconBox>
                <StNoshine.TextBox>
                  <p>
                    {transferLanguage[language][13]}
                    <br />
                    {transferLanguage[language][14]}, {transferLanguage[language][15]}, {transferLanguage[language][16]}
                    , {transferLanguage[language][17]}
                  </p>
                  <br />
                  <p>
                    {transferLanguage[language][18]}
                    <br />
                    {transferLanguage[language][19]}, {transferLanguage[language][20]},
                    <br />
                    {transferLanguage[language][21]}, {transferLanguage[language][22]}
                  </p>
                </StNoshine.TextBox>
              </StNoshine.Box>
            </StNoshine.GenerationBox>
            <StAbout.Font20 ta="center">{transferLanguage[language][23]}</StAbout.Font20>
          </div>
        </StNoshine.Container>

        <StNoshine.Container bgcolor="white">
          <div className="inner">
            <StAbout.FontBigBold ta="center">{transferLanguage[language][24]}</StAbout.FontBigBold>
            {language === 0 && <StNoshine.GroupImg srcSet={`${effectKo} 1x, ${effectKo_2x} 2x`} src={effectKo} alt="effectKo" className="pc" />}
            {language === 1 && <StNoshine.GroupImg srcSet={`${effectEn} 1x, ${effectEn_2x} 2x`} src={effectEn} alt="effectEn" className="pc" />}
            {language === 2 && <StNoshine.GroupImg srcSet={`${effectIn} 1x, ${effectIn_2x} 2x`} src={effectIn} alt="effectIn" className="pc" />}
            {language === 3 && <StNoshine.GroupImg srcSet={`${effectSp} 1x, ${effectSp_2x} 2x`} src={effectSp} alt="effectSp" className="pc" />}

            {language === 0 && <StNoshine.GroupImg srcSet={`${effectKoM} 1x, ${effectKoM_2x} 2x`} src={effectKoM} alt="effectKo" className="mobile" />}
            {language === 1 && <StNoshine.GroupImg srcSet={`${effectEnM} 1x, ${effectEnM_2x} 2x`} src={effectEnM} alt="effectEn" className="mobile" />}
            {language === 2 && <StNoshine.GroupImg srcSet={`${effectInM} 1x, ${effectInM_2x} 2x`} src={effectInM} alt="effectIn" className="mobile" />}
            {language === 3 && <StNoshine.GroupImg srcSet={`${effectSpM} 1x, ${effectSpM_2x} 2x`} src={effectSpM} alt="effectSp" className="mobile" />}
          </div>
        </StNoshine.Container>

        {/* 기술 응용 분야 */}
        <StNoshine.Container bgcolor="#006371" pl="0" pb="0" pt="80px">
          {/* 다목적 복합 필터 */}
          <StAbout.FontBigBold color="white" ta="center">
            {transferLanguage[language][35]}
          </StAbout.FontBigBold>
          <StAbout.MB mb="80px" />
          <StNoshine.ContentArticle style={{ height: language !== 0 ? "480px" : "" }}>
            <StNoshine.Explain>
              <StAbout.FontMidiumBold color="white" ta="right">
                {transferLanguage[language][36]}
              </StAbout.FontMidiumBold>
              <StAbout.MB mb="24px" />
              <StAbout.Font20 color="white" ta="right">
                {transferLanguage[language][37]}
              </StAbout.Font20>
              <StArrow.Box
                type="button"
                bgcolor="transparent"
                border="1px solid white"
                margin="0 0 0 auto"
                onClick={goFilter}
              >
                {transferLanguage[language][46]} <div className="arrow"></div>
              </StArrow.Box>
            </StNoshine.Explain>
            <StNoshine.ImgBox>
              <StNoshine.ExplainImg src={multi} alt="" />
            </StNoshine.ImgBox>
          </StNoshine.ContentArticle>
          {/* 신선도 유지기술 */}
          <StNoshine.ContentArticle style={{ height: language !== 0 ? "480px" : "" }}>
            <StNoshine.ImgBox>
              <StNoshine.ExplainImg src={fresh} alt="" />
            </StNoshine.ImgBox>
            <StNoshine.Explain>
              <StAbout.FontMidiumBold color="white">{transferLanguage[language][38]}</StAbout.FontMidiumBold>
              <StAbout.MB mb="24px" />
              <StAbout.Font20 color="white">{transferLanguage[language][39]}</StAbout.Font20>
              <StArrow.Box
                type="button"
                bgcolor="transparent"
                border="1px solid white"
                margin="0 auto 0 0"
                onClick={goFresh}
              >
                {transferLanguage[language][46]} <div className="arrow"></div>
              </StArrow.Box>
            </StNoshine.Explain>
          </StNoshine.ContentArticle>
          {/* 상시 방역 & 공기질 개선 */}
          <StNoshine.ContentArticle style={{ height: language !== 0 ? "480px" : "" }}>
            <StNoshine.Explain>
              <StAbout.FontMidiumBold color="white" ta="right">
                {transferLanguage[language][40]}
              </StAbout.FontMidiumBold>
              <StAbout.MB mb="24px" />
              <StAbout.Font20 color="white" ta="right">{transferLanguage[language][41]}</StAbout.Font20>
              <StArrow.Box
                type="button"
                bgcolor="transparent"
                border="1px solid white"
                margin="0 0 0 auto"
                onClick={goAir}
              >
                {transferLanguage[language][46]} <div className="arrow"></div>
              </StArrow.Box>
            </StNoshine.Explain>
            <StNoshine.ImgBox>
              <StNoshine.ExplainImg src={air} alt="" />
            </StNoshine.ImgBox>
          </StNoshine.ContentArticle>
          {/* 수질 개선 기술 */}
          <StNoshine.ContentArticle style={{ height: language !== 0 ? "480px" : "" }}>
            <StNoshine.ImgBox>
              <StNoshine.ExplainImg src={water} alt="" />
            </StNoshine.ImgBox>
            <StNoshine.Explain>
              <StAbout.FontMidiumBold color="white">{transferLanguage[language][42]}</StAbout.FontMidiumBold>
              <StAbout.MB mb="24px" />
              <StAbout.Font20 color="white">{transferLanguage[language][43]}</StAbout.Font20>
              <StArrow.Box
                type="button"
                bgcolor="transparent"
                border="1px solid white"
                margin="0 auto 0 0"
                onClick={goWater}
              >
                {transferLanguage[language][46]} <div className="arrow"></div>
              </StArrow.Box>
            </StNoshine.Explain>
          </StNoshine.ContentArticle>
          {/* 건축 자재 적용기술 */}
          <StNoshine.ContentArticle style={{ height: language !== 0 ? "480px" : "" }}>
            <StNoshine.Explain>
              <StAbout.FontMidiumBold color="white" ta="right">
                {transferLanguage[language][44]}
              </StAbout.FontMidiumBold>
              <StAbout.MB mb="24px" />
              <StAbout.Font20 color="white" ta="right">{transferLanguage[language][45]}</StAbout.Font20>
              <StAbout.MB mb="30px" />
              <StArrow.Box
                type="button"
                bgcolor="transparent"
                border="1px solid white"
                margin="0 0 0 auto"
                onClick={goBuild}
              >
                {transferLanguage[language][46]} <div className="arrow"></div>
              </StArrow.Box>
            </StNoshine.Explain>
            <StNoshine.ImgBox>
              <StNoshine.ExplainImg src={build} alt="" />
            </StNoshine.ImgBox>
          </StNoshine.ContentArticle>
        </StNoshine.Container>
      </StProduct.Container>
    </div>
  );
};

export default Noshine;
