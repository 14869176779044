import React from "react";
import { StAbout, StArrow, StSelect } from "../../aboutus/StAbout";
import arrow from "../../../img/icon/arrow-right-long-white.png";
import { StNoshine } from "../StProduct";
import { useNavigate } from "react-router-dom";
import { StMulti } from "./Multi";
import { styled } from "styled-components";
import FreshSecond from "../../../components/product/FreshSecond";
import { transferAtom } from "../../../recoil/transferAtom";
import { useRecoilValue } from "recoil";

import img1 from "../../../img/products/techapply/data5/AdobeStock_308413499_tiny.jpg";
import img2 from "../../../img/products/techapply/data5/AdobeStock_291490013_.jpg";
import check from "../../../img/products/techapply/data2/check-solid.svg";
import { transferLanguage_head } from "../../../components/SelectBox";

export const transferLanguageFresh = [
  [
    "신선도 유지 기술",
    "WELTOUCH®는 종이, 박스, 원단 등과 같은 소재에도 다양하게 적용이 가능해 음식의 패키지 및 용기(트레이)에 사용하여 음식의 부패 방지 효과를 부여합니다.",
    "신선도 유지 기술 문의 하기",
    "특징",
    "모든 자재에 손쉽게 적용 가능",
    "강력한 부패 지연 효과",
    "우수한 코팅력",
    "보장된 안전성",
    "용도",
    "각종 tray, box, pp plastic 등",
    "농,식품을 보관할 때 사용하는 모든 자재", //10
    "항곰팡이 성능",
    "시험 항목",
    "항곰팡이",
    "결과",
    "무처리 Tray box에서 시험편의 60%이상 균사의 번식이 관찰된 반면 WELTOUCH®를 처리한 tray box에서는 균사의 번식이 인지되지 않았습니다.",
    "시험 기관",
    "WELTOUCH®를 처리한 Tray box와 아무것도 처리하지 않은 Tray box에서 곰팡이균의 번식을 관찰한 결과 아무것도 처리하지 않은 Tray box는 시험편의 60%이상 균사의 번식이 관찰된 반면 WELTOUCH®를 처리한 Tray box에서는 균사의 번식이 전혀 관찰되지 않았습니다.",
    "실제 적용 Test",
    "WELTOUCH®가 처리된 상자",
    "WELTOUCH®가 처리된 원단",
    "WELTOUCH®가 처리된 포장백",
    "WELTOUCH®가 처리된 곳의 바나나의 숙성이 처리하지 않은 곳에 비해 바나나의 숙성 속도가 현저히 느려졌습니다.",
  ],
  [
    "Maintenance of freshness",
    "WELTOUCH® can be applied to a wide range of materials, including paper, box, or fabric. It helps prevent food spoilage when applied to food containers(trays) or packages.",
    "Inquire about Maintenance of Freshness Technology",
    "Features",
    "Simple application to all kinds of materials",
    "Strong effect delaying spoilage",
    "Superior coating ability",
    "Secured safety",
    "Applications",
    "Applicable for all materials used to keep",
    "agricultural products or food, including trays, boxes, and pp plastics.",
    "Anti-fungal functions",
    "Test item",
    "Anti-fungal",
    "Result",
    "While mycelia proliferation was observed on over 60% of specimens in the untreated tray box, the tray box treated with WELTOUCH® showed no mycelia proliferation.",
    "Test Agency",
    "In accordance with the analysis on the proliferation of mold on WELTOUCH®-applied tray boxes against untreated tray boxes, the untreated tray boxes showed the proliferation of mycelia on over 60% of specimens. However, WELTOUCH®-applied tray box had no mycelia proliferation.",
    "Actual Application Test",
    "WELTOUCH® applied Box",
    "WELTOUCH® applied Fabric",
    "WELTOUCH® applied Packing Bag",
    "The ripening of a banana on the part applying WELTOUCH® was significantly slower than on the untreated part.",
  ],
  [
    "Teknologi Pemeliharaan Kesegaran",
    "WELTOUCH® juga dapat diterapkan pada bahan seperti kertas, kotak, dan kain, serta digunakan dalam kemasan dan wadah makanan (tray) untuk memberikan efek efisiensi.",
    "Pertanyaan tentang Teknologi Pemeliharaan Kesegaran",
    "Fitur",
    "Mudah diaplikasikan ke semua bahan",
    "Efek efisiensi yang kuat",
    "Pelapisan yang sangat baik",
    "keamanan terjamin",
    "Tujuan",
    "Semua bahan yang digunakan untuk menyimpan makanan",
    "dan produk pertanian seperti nampan, kotak, dan plastik pp",
    "Kinerja anti-jamur",
    "Item tes",
    "Kinerja anti-jamur",
    "hasil",
    "Reproduksi miselium diamati pada lebih dari 60% spesimen uji di kotak nampan yang tidak diberi perlakuan, sedangkan reproduksi miselium tidak dikenali di kotak nampan yang diberi WELTOUCH®",
    "Lembaga pengujian",
    "Dalam kotak baki yang diberi perlakuan WELTOUCH® dan di dalam kotak baki yang tidak diberi perlakuan, kotak baki yang tidak diberi perlakuan menunjukkan lebih dari 60% reproduksi miselium pada benda uji, sedangkan kotak baki yang diberi perlakuan WELTOUCH® tidak menunjukkan reproduksi miselium.",
    "Tes kinerja aplikasi yang sebenarnya",
    "Kotak yang dirawat dengan WELTOUCH® ",
    "kain yang dirawat dengan WELTOUCH®",
    "tas kemasan yang dirawat",
    "Pematangan pisang di tempat-tempat di mana WELTOUCH® diperlakukan secara signifikan lebih lambat daripada di tempat-tempat di mana WELTOUCH® tidak diperlakukan.",
  ],
  [
    "Tecnología de conservación de la frescura",
    "WELTOUCH® se puede aplicar a diversos materiales como papel, cajas y telas, por lo que se utiliza para envases y recipientes (bandejas) de alimentos para dar efectos de prevención del deterioro de los alimentos.",
    "Contacto",
    "Característica",
    "Fácilmente adaptable a todos los materiales",
    "Fuerte efecto de retardo de caída",
    "Excelente poder de recubrimiento",
    "Seguridad garantizada",
    "Uso",
    "Todos los materiales utilizados para almacenar productos",
    "agrícolas y alimenticios, como varias bandejas, cajas y PP de plásticos",
    "Rendimiento antifúngico",
    "Elkemento de prueba",
    "Rendimiento antifúngico",
    "Resultado",
    "Se observó propagación del micelio en más del 60 % de los especímenes en la caja de la bandeja sin tratar, mientras que no se reconoció la propagación del micelio en la caja de la bandeja tratada con WELTOUCH®",
    "Entidad de prueba",
    "Como resultado de observar el crecimiento de hongos en la caja de Bandeja tratada con WELTOUCH® y la caja de bandeja sin tratamiento, más del 60% de los especímenes de prueba mostraron crecimiento de micelio en la caja de bandeja sin tratamiento, mientras que en la caja de bandeja tratados con WELTOUCH® no se observó crecimiento de micelio.",
    "Prueba de aplicación real",
    "Caja tratada de WELTOUCH®",
    "Tejido tratado de WELTOUCH®",
    "Bolsa de embalaje tratada de WELTOUCH®",
    "La tasa de maduración de las bananas tratadas con WELTOUCH® fue significativamente más lenta que la de los plátanos no tratados.",
  ],
];

const Fresh: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const [moredetailCategory, setMoreDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/product/${e.target.dataset.value}`);
  };

  return (
    <StFresh.Container
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
        setMoreDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/AdobeStock_270639335_2_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white"> {transferLanguage_head[language][1]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][5]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][1]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][5]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="noshine" onClick={detailClickHandler}>
                  {transferLanguage_head[language][4]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][5]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="redchec" onClick={detailClickHandler}>
                  Redchec®
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setMoreDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][7]}
            </StSelect.OptionValue>
            {moredetailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][6]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/fresh" onClick={detailClickHandler}>
                  {transferLanguage_head[language][7]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/air" onClick={detailClickHandler}>
                  {transferLanguage_head[language][8]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/water" onClick={detailClickHandler}>
                  {transferLanguage_head[language][9]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/build" onClick={detailClickHandler}>
                  {transferLanguage_head[language][10]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <div className="inner">
        <StMulti.Title>{transferLanguageFresh[language][0]}</StMulti.Title>
        <StMulti.TextBox>{transferLanguageFresh[language][1]}</StMulti.TextBox>
        <StNoshine.Container bgcolor="white" pt="2.5rem">
          <StArrow.Box type="button" bgcolor="#00AFC8" onClick={() => navigate("/contact/stepOne")}>
            {transferLanguageFresh[language][2]} <div className="arrow"></div>
          </StArrow.Box>
        </StNoshine.Container>
        <StMulti.BoxContainer>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguageFresh[language][3]}</StMulti.Stitle>
            <StMulti.StextBox>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguageFresh[language][4]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguageFresh[language][5]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguageFresh[language][6]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguageFresh[language][7]}
              </StMulti.StextLine>
            </StMulti.StextBox>
          </StMulti.Box>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguageFresh[language][8]}</StMulti.Stitle>
            <StMulti.StextBox>
              {transferLanguageFresh[language][9]}
              <br />
              {transferLanguageFresh[language][10]}
            </StMulti.StextBox>
          </StMulti.Box>
        </StMulti.BoxContainer>
      </div>
      <FreshSecond />
    </StFresh.Container>
  );
};

export default Fresh;
export const StFresh = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  ImgBoxContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 100px;

    @media screen and (max-width: 1200px) {
      gap: 30px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  ImgBox: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 235px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  `,
};
