import React from "react";
import Header from "../../components/Header";
import { StAbout, StEmpty, StSelect } from "../aboutus/StAbout";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import search from "../../img/icon/search.svg";
import { styled } from "styled-components";
import { client } from "../../axios/axios";
import MediaArticle from "../../components/media/MediaArticle";

import left from "../../img/icon/left.png";
import right from "../../img/icon/right.png";
import dleft from "../../img/icon/doubleleft.png";
import dright from "../../img/icon/doubleright.png";
import { useRecoilState, useRecoilValue } from "recoil";
import { mediaAtom } from "../../recoil/mediaAtom";
import { transferLanguage_head } from "../../components/SelectBox";
import { transferAtom } from "../../recoil/transferAtom";

const pageCnt = 12;

const transferLanguage = [
  ["최신순", "등록순", "아직 등록된 게시글이 없습니다."],
  ["Latest", "Oldest", "No registered posts yet."],
  ["Terbaru", "Terlama", "Tidak ada postingan yang terdaftar."],
  ["Más reciente", "Más antiguo", "Aún no hay publicaciones registradas."],
];

const Media: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const [bigCategory, setBigCategory] = React.useState(false);
  const [data, setData] = React.useState<any[]>();
  // const [allData, setAllData] = React.useState<any[]>();
  const [allData, setAllData] = useRecoilState(mediaAtom);
  const [totalPage, setTotalPage] = React.useState<any[]>();
  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState<0 | 1>(0);
  const [searchInput, setSearchInput] = React.useState("");
  const navigate = useNavigate();

  const changeHandler = (e: any) => {
    setSearchInput(e.target.value);
  };

  const searchHandler = async () => {
    if (searchInput !== "") {
      const result = [...allData]?.filter((el: any) => el?.title.includes(searchInput)) || [];
      const paging = [];
      for (let i = 0; i < Math.ceil(result?.length / 20); i++) {
        paging.push(i + 1);
      }
      setTotalPage(paging);
      const list = result?.filter((el: any, i: number) => i >= 0 + (page - 1) * pageCnt && i < page * pageCnt);
      setData(list);
      setSearchInput("");
    } else {
      setData(allData);
    }
  };
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  React.useEffect(() => {
    if (sort === 0) {
      const sortedByDateList = [...allData]?.sort((a: any, b: any) => {
        const dateA = new Date(a?.regDate);
        const dateB = new Date(b?.regDate);
        return dateB.getTime() - dateA.getTime();
      });
      setData(sortedByDateList?.filter((el: any, i: number) => i >= 0 + (page - 1) * pageCnt && i < page * pageCnt));
    } else {
      const sortedByDateList = [...allData]?.sort((a: any, b: any) => {
        const dateA = new Date(a?.regDate);
        const dateB = new Date(b?.regDate);
        return dateA.getTime() - dateB.getTime();
      });
      setData(sortedByDateList?.filter((el: any, i: number) => i >= 0 + (page - 1) * pageCnt && i < page * pageCnt));
    }
  }, [sort, allData]);

  React.useEffect(() => {
    if (allData?.length === 0) return;
    const list = allData?.filter((el: any, i: number) => i >= 0 + (page - 1) * pageCnt && i < page * pageCnt);
    setData(list);
  }, [page, allData]);

  React.useEffect(() => {
    const fetching = async () => {
      const result = await client.get("/media");
      setAllData(result.data);
      const paging = [];
      for (let i = 0; i < Math.ceil(result?.data.length / pageCnt); i++) {
        paging.push(i + 1);
      }
      setTotalPage(paging);
    };
    fetching();
  }, []);

  return (
    <>
      <Header />
      <StEmpty />
      <div onClick={() => setBigCategory(false)}>
        <StAbout.MainImg src="/assets/AdobeStock_285091710_2.jpg">
        <div className="title-area">
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][2]}</StAbout.FontBigBold>
        </div>
        </StAbout.MainImg>
        <StSelect.SelectBox>
          <div className="inner">
            <StSelect.SelectOption>
              <StSelect.OptionValue
                onClick={(e: any) => {
                  e.stopPropagation();
                  setBigCategory((prev) => !prev);
                }}
              >
                {transferLanguage_head[language][2]}
              </StSelect.OptionValue>
              {bigCategory && (
                <StSelect.OptionBox>
                  <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                    {transferLanguage_head[language][0]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                    {transferLanguage_head[language][1]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                    {transferLanguage_head[language][2]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                    {transferLanguage_head[language][3]}
                  </StSelect.OptionOtherValue>
                </StSelect.OptionBox>
              )}
            </StSelect.SelectOption>
          </div>
        </StSelect.SelectBox>
        <div className="inner">
          <StMedia.UpBar>
            <StMedia.SortBox>
              <StMedia.RadioBox>
                <input type="radio" name="sort" id="new" value="최신순" defaultChecked onChange={() => setSort(0)} />
                <label htmlFor="new">{transferLanguage[language][0]}</label>
              </StMedia.RadioBox>
              <StMedia.RadioBox>
                <input type="radio" name="sort" id="old" value="등록순" onChange={() => setSort(1)} />
                <label htmlFor="old">{transferLanguage[language][1]}</label>
              </StMedia.RadioBox>
            </StMedia.SortBox>
            <StMedia.InputBox>
              <StMedia.Input type="text" value={searchInput} onChange={changeHandler} />
              <StMedia.SearchIcon src={search} alt="" onClick={searchHandler} />
            </StMedia.InputBox>
          </StMedia.UpBar>
          <StMedia.ArticleList>
            {data?.length === 0 && <p>{transferLanguage[language][2]}</p>}
            {data?.length !== 0 && (
              <>
                {data?.map((el, i) => (
                  <MediaArticle key={`media게시글${i}`} el={el} />
                ))}
              </>
            )}
          </StMedia.ArticleList>
          <StMedia.PageBox>
            <StMedia.BtnBox onClick={() => setPage(1)}>
              <StMedia.PageImg src={dleft} alt="first" />
            </StMedia.BtnBox>
            <StMedia.BtnBox
              onClick={() => {
                if (page > 1) setPage((prev) => prev - 1);
              }}
            >
              <StMedia.PageImg src={left} alt="prev" />
            </StMedia.BtnBox>
            {totalPage?.map((el, i) => (
              <StMedia.Pagenation key={`페이징${i}`} onClick={() => setPage(el)}>
                {el}
              </StMedia.Pagenation>
            ))}
            <StMedia.BtnBox
              onClick={() => {
                if (page < totalPage?.length!) setPage((prev) => prev + 1);
              }}
            >
              <StMedia.PageImg src={right} alt="next" />
            </StMedia.BtnBox>
            <StMedia.BtnBox onClick={() => setPage(totalPage?.length!)}>
              <StMedia.PageImg src={dright} alt="last" />
            </StMedia.BtnBox>
          </StMedia.PageBox>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Media;
export const StMedia = {
  UpBar: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-top: 6.25rem;

    @media screen and (max-width:480px){
      flex-direction: column;
    }
  `,
  SortBox: styled.div`
    display: flex;
    gap: 26px;
  `,
  RadioBox: styled.div`
    position: relative; display: -webkit-flex; display: flex; justify-content: start; align-items: center;

    input[type=radio] {display: none;}
    input[type=radio] + label {cursor: pointer; margin-left: 19px;}
    input[type=radio] + label::before {position: absolute; top: 50%; left: 4.5px; transform: translateY(-50%); display: inline-block; content: ''; width: 4px; height: 4px; background-color: var(--primary); border-radius: 50%; text-align: center;}
    input[type=radio]:checked + label::before {display: none;}
    input[type=radio]:checked + label::after {display: inline-block; position: absolute; top: 50%; left: 0; transform: translateY(-50%); display: inline-block; content: ''; width: 13px; height: 13px; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%2300AFC8'%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E"); background-size: 13px 13px;}
  `,
  InputBox: styled.div`
    width: 330px;
    position: relative;
    height: 2.5rem;

    @media screen and (max-width:480px){
      width: 100%;
    }
  `,
  Input: styled.input`
    width: 100%;
    height: 100%;
  `,
  SearchIcon: styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    object-fit: cover;
    display: block;
  `,
  ArticleList: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    padding: 6.25rem 0 3.75rem 0;
    
    @media screen and (max-width:1200px){
      gap: 24px;
    }

    @media screen and (max-width:992px){
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }

    @media screen and (max-width:768px){
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width:480px){
      gap: 12px;
    }
  `,
  PageBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9.375rem;
    margin-top: 30px;
    gap: 10px;
  `,
  Pagenation: styled.div`
    cursor: pointer;
    width: 34px;
    height: 34px;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  BtnBox: styled.div`
    width: 34px;
    height: 34px;
    border: 1px solid var(--darkgray);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  PageImg: styled.img`
    width: 16px;
    height: 22px;
    object-fit: contain;
    display: block;
  `,
};
