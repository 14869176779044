import React from "react";
import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { cookie } from "../../constant/cookie";
import { useNavigate } from "react-router-dom";
import { transferAtom } from "../../recoil/transferAtom";
import { useRecoilValue } from "recoil";
const checked = cookie.get("popup");
const transferLanguage = [
  [
    "샘플 코팅 신청",
    "WELTOUCH®에서는 고객사에서 성능을 직접 확인해보실 수 있게 고객사 분들의 제품에 WELTOUCH®를 샘플 코팅해드리고 있습니다.",
    "",
    "",
    "지금 WELTOUCH® 샘플 코팅을 신청해 귀사의 제품에서 광촉매 초격차 기술 WELTOUCH®의 성능을 확인해보세요.",
    "(제휴제안에서 샘플코팅에 체크해 주세요.)",
    "하루 동안 다시 열지 않습니다.",
    "샘플 신청 하러 가기",
  ],
  [
    "Sample Coating Request",
    "WELTOUCH® provides a sample coating service for your products to enable you to directly check the performance of WELTOUCH® technologies.",
    "",
    "",
    "Check the performance of WELTOUCH® technologies, the super gap technologies for photocatalysts, with your products by requesting the sample coating service.",
    "(Please tick Sample Coating on the Partnership Proposal Form.)",
    "Check later",
    "Apply for a sample",
  ],
  [
    "Sample Coating berlaku",
    "WELTOUCH® menyediakan sampel pelapis WELTOUCH® pada produkAnda sehingga Anda dapat melihat sendiri kinerjanya.",
    "",
    "",
    "Terapkan lapisan WELTOUCH® untuk sample sekarang untuk melihat kinerja teknologi fotokatalis supergap WELTOUCH® dalam produk Anda.",
    "(Silakan periksa sampel pelapisan di afiliasi/proposal)",
    "Konfirmasi Nanti",
    "Mendaftar untuk sampl",
  ],
  [
    "Solicitud para un recubrimiento de muestra",
    "WELTOUCH® proporciona una muestra de WELTOUCH® a los productos de los clientes para que los clientes puedan comprobar directamente el rendimiento.",
    "",
    "",
    "Solicite una muestra WELTOUCH® hoy para ver el rendimiento de la tecnología foto catalítica de super espacios WELTOUCH® en sus productos.",
    "(Consulte muestra de revestimiento en la propuesta de asociación)",
    "más tarde",
    "solicitud de muestra",
  ],
];
const Popup = () => {
  const language = useRecoilValue(transferAtom);

  const [isPopup, setIsPopup] = React.useState(() => (checked === "true" ? false : true));
  const navigate = useNavigate();
  const checkHandler = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    cookie.set(`popup`, "true", { path: "/", expires });
    setIsPopup(false);
  };

  return (
    <StPopup>
      {isPopup && (
        <div className="pop-content">
          <div className="pop-body">
            <div className="pop-text-l">{transferLanguage[language][0]}</div>
            <div className="pop-text-r">
              {transferLanguage[language][1]}
              <br />
              <br />
            </div>
            <div className="pop-text-sb">{transferLanguage[language][4]}</div>
            <a className="pop-link" onClick={() => navigate("/contact/stepOne")}>
              {transferLanguage[language][7]} <FontAwesomeIcon icon={solid("angles-right")} />
            </a>
          </div>
          <div className="pop-footer">
            <div>
              <label>
                <input type="checkbox" id="myCheckbox" name="" onClick={checkHandler} style={{ marginRight: "6px" }} />
                {transferLanguage[language][6]}
              </label>
            </div>
            <div
              className="popClose"
              onClick={() => {
                setIsPopup(false);
              }}
            >
              <FontAwesomeIcon icon={solid("xmark")} />
            </div>
          </div>
        </div>
      )}
    </StPopup>
  );
};

export default Popup;

const StPopup = styled.div`
  position: fixed;
  z-index: 20;
  width: 100%;
  max-width: 480px;

  @media screen and (min-width:993px){
    top: 120px;
    left: 40px;
  }

  @media screen and (max-width:992px){
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width:480px){
    width: calc(100% - 32px);
  }
`;
