import React from "react";
import styled from "styled-components";
import arrowGray from "../img/icon/arrow-right-long-gray.png";
import { useNavigate } from "react-router-dom";
import { windowScrollTop } from "./Footer";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../recoil/transferAtom";

const Inquiry = () => {
  const navigate = useNavigate();
  const transferLanguage = ["문의하기", "Contact us", "Hubungi kami", "Contacto"];
  const language = useRecoilValue(transferAtom);
  return (
    <StBottomArrow.Box
      type="button"
      onClick={() => {
        navigate("/contact/stepOne");
        windowScrollTop();
      }}
    >
      {transferLanguage[language]} <div className="arrowGray" />
    </StBottomArrow.Box>
  );
};

export default Inquiry;

export const StBottomArrow = {
  Box: styled.button`
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 11px 2.5rem;
    margin: 9.375rem auto;
    background-color: transparent;
    transition: all 300ms ease;
    border: 1px solid var(--primary);
    border-radius: 4px;
    color: var(--primary);
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;

    img {
      margin-left: 16px;
      width: 16px;
      transition: all 300ms ease;
    }

    .arrowGray {
      position: relative;
      margin-left: 16px;
      width: 16px;
      height: 11px;
      transition: width var(--transition-duration-300) ease;
      background-image: url(${arrowGray});
      background-repeat: no-repeat;
      background-position: right center;
    }
    &:hover {
      img {
        width: 46px;
      }

      .arrowGray {
        width: 46px;
      }
    }

    @media screen and (max-width: 768px) {
      margin: 6.25rem auto;
    }
  `,
};