import React from "react";
import { StAbout, StArrow, StSelect } from "../../aboutus/StAbout";
import arrow from "../../../img/icon/arrow-right-long-white.png";
import { StNoshine } from "../StProduct";
import { useNavigate } from "react-router-dom";
import { StMulti } from "./Multi";
import { StFresh } from "./Fresh";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../../recoil/transferAtom";
import BuildSecond from "../../../components/product/BuildSecond";

import img1 from "../../../img/products/techapply/data1/img1.jpg";
import img2 from "../../../img/products/techapply/data1/img2.jpg";
import check from "../../../img/products/techapply/data2/check-solid.svg";
import { StAir } from "./Air";
import { transferLanguage_head } from "../../../components/SelectBox";
import { windowScrollTop } from "../../../components/Footer";

export const transferLanguage_build = [
  [
    "건축 자재 적용 기술",
    "건축자재 적용 시 부가적인 기능이 발휘되어 제품을 업그레이드 시켜줍니다.",
    "습하고 더운 환경에서 건축자재 표면에 생기는 곰팡이균을 억제하여 건축자재의 오염을 막아주고, 수명을 늘려줍니다.",
    "또한, 건축 외장재에 적용 시 최근에 문제가 되고 있는 미세먼지 전구물질인 NOx, SOx, VOCs 등을 분해·제거해줍니다.",
    "건축 자재 적용 문의 하기",
    "특징",
    "손쉬운 적용",
    "제한없는 강력한 효과",
    "우수한 코팅력",
    "보장된 안전성",
    "용도",
    "돌, 시멘트, 건물 외벽, 건축 자재 등",
    "WELTOUCH® Self cleaning 효과", //12
    "무처리",
    "WELTOUCH® 처리",
    "블록에 WELTOUCH®를 코팅하고 장기간 관찰한 결과 검은색 오염이 사라지고 더 이상 발생되지 않았습니다.",
    "샌드위치 판넬에 WELTOUCH®를 처리하고 장기간 관찰한 결과 기존 바탕 상태 그대로 깨끗함을 유지했습니다.",
    "초신수성의 성질",
    "WELTOUCH®는 초친수성 물질로 코팅된 건축 외장재에 기름먼지나 유기물이 달라 붙는 것을 방지하며, 빗물만으로도 오염물을 제거합니다.",
  ],
  [
    "Application to construction materials",
    "The products are upgraded by additional functions when WELTOUCH® is applied to construction materials.",
    "WELTOUCH® prevents pollution and extends the life of construction materials by inhibiting mold, which may be formed on the surface of construction materials used in a humid and hot environment.",
    "Furthermore, WELTOUCH® decomposes and eliminates the precursors of particulate matter, including NOx, SOx, and VOCs, which have emerged as a serious issue after being applied to exterior materials for buildings.",
    "Inquire about application to construction materials",
    "Features",
    "Simple application",
    "Powerful effects without restriction",
    "Excellent coating ability",
    "Secured safety",
    "Applications",
    "Stone, cement, exterior wall of a building, construction materials, etc.",
    "Self-cleaning effects of WELTOUCH®",
    "Non-treated",
    "WELTOUCH® treated",
    "In accordance with the long-term observation after coating WELTOUCH® on a block, black stains disappeared, and no further formation occurred.",
    "The long-term observation after the application of WELTOUCH® to a sandwich panel showed that the surface maintained its clean status.",
    "Super-hydrophile property",
    "WELTOUCH®, the super-hydrophilic substance, prevents oily dust or organic matter from sticking to the exterior surface of a building and gets rid of pollutants only with rain.",
  ],
  [
    "Aplikasi pada teknologi bahan konstruksi",
    "Saat menerapkan bahan bangunan, fungsi tambahan diaktifkan untuk meningkatkan produk.",
    "Ini mencegah kontaminasi bahan bangunan dan memperpanjang umurnya dengan menekan bakteri jamur pada permukaan bahan bangunan di lingkungan yang lembab dan panas.",
    "Selain itu, ketika diterapkan pada bahan eksterior konstruksi, ia menguraikan dan menghilangkan NOx, SOx, dan VOC, yang merupakan prekursor debu halus yang belakangan ini menjadi masalah.",
    "Pertanyaan tentang Aplikasi pada teknologi bahan konstruksi",
    "Fitur",
    "Aplikasi mudah",
    "Efek kuat tak terbatas",
    "Daya pelapis luar biasa",
    "Keamanan terjamin",
    "Tujuan",
    "Batu, semen, dinding luar bangunan, bahan bangunan, dll.",
    "WELTOUCH® Efek pembersihan diri.",
    "Tanpa pengolahan",
    "PENGOLAHAN WELTOUCH®",
    "Setelah melapisi WELTOUCH® pada blok, dan sebagai hasil pengamatan jangka panjang, kontaminasi hitam menghilang dan tidak terjadi lagi.",
    "Setelah melapisi WELTOUCH® pada panel sandwich, dan sebagai hasil pengamatan jangka panjang, tetap bersih seperti semula",
    "Sifat-sifat super hidrofilik",
    "WELTOUCH® adalah bahan super-hidrofilik yang mencegah debu minyak dan bahan organik menempel pada bangunan dan menghilangkan kontaminan dari air hujan saja.",
  ],
  [
    "Tecnología de aplicación de materiales de construcción",
    "Al aplicar materiales de construcción, se muestran funciones adicionales para actualizar el producto.",
    "Previene la contaminación de los materiales de construcción al suprimir los hongos que se forman en la superficie de los materiales de construcción en un ambiente húmedo y caluroso, y extiende su vida útil.",
    "Además, cuando se aplica a materiales exteriores de edificios, descompone y elimina precursores de polvo fino como NOx, SOx y VOCs, que recientemente se han convertido en un problema.",
    "Contacto",
    "Característica",
    "Fácil aplicación",
    "Potentes efectos sin límite",
    "Excelente poder de recubrimiento",
    "Seguridad garantizada",
    "Uso",
    "Piedra, cemento, paredes exteriores de edificios, materiales de construcción, etc.",
    "Efecto auto limpiante de WELTOUCH®",
    "Sin tratamiento",
    "Tratamiento WELTOUCH®",
    "Después de recubrir el bloque con WELTOUCH® y observarlo durante mucho tiempo, la mancha negra desapareció y ya no se presentó.",
    "Los paneles sándwich se trataron con WELTOUCH® y, como resultado de una observación a largo plazo, permanecieron limpios tal y como estaba.",
    "Propiedad de super-hidrofilia",
    "WELTOUCH® evita que el polvo de aceite o las sustancias orgánicas se adhieran a los materiales exteriores del edificio revestidos con materiales de super-hidrofilia y elimina los contaminantes solo con agua de lluvia.",
  ],
];

const Build: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const [moredetailCategory, setMoreDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/product/${e.target.dataset.value}`);
  };
  const goContact = () => {
    navigate("/contact/stepOne");
    windowScrollTop();
  };
  return (
    <StMulti.Container
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
        setMoreDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/CK_cm26014559_2_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white"> {transferLanguage_head[language][1]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][5]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][1]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][5]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="noshine" onClick={detailClickHandler}>
                  {transferLanguage_head[language][4]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][5]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="redchec" onClick={detailClickHandler}>
                  Redchec®
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setMoreDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][10]}
            </StSelect.OptionValue>
            {moredetailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][6]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/fresh" onClick={detailClickHandler}>
                  {transferLanguage_head[language][7]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/air" onClick={detailClickHandler}>
                  {transferLanguage_head[language][8]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/water" onClick={detailClickHandler}>
                  {transferLanguage_head[language][9]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/build" onClick={detailClickHandler}>
                  {transferLanguage_head[language][10]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <div className="inner">
        {/* data1 */}
        <StMulti.Title>{transferLanguage_build[language][0]}</StMulti.Title>
        <StMulti.TextBox>
          {transferLanguage_build[language][1]}
          <br />
          {transferLanguage_build[language][2]}
          <br />
          {transferLanguage_build[language][3]}
        </StMulti.TextBox>
        <StNoshine.Container bgcolor="white" pt="2.5rem">
          <StArrow.Box type="button" bgcolor="#00AFC8" onClick={goContact}>
            {transferLanguage_build[language][4]} <div className="arrow"></div>
          </StArrow.Box>
        </StNoshine.Container>

        <StMulti.BoxContainer>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_build[language][5]}</StMulti.Stitle>
            <StMulti.StextBox>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_build[language][6]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_build[language][7]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_build[language][8]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_build[language][9]}
              </StMulti.StextLine>
            </StMulti.StextBox>
          </StMulti.Box>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_build[language][10]}</StMulti.Stitle>
            <StMulti.StextBox>{transferLanguage_build[language][11]}</StMulti.StextBox>
          </StMulti.Box>
        </StMulti.BoxContainer>
      </div>
      <BuildSecond />
    </StMulti.Container>
  );
};

export default Build;
