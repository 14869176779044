import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import emptyThumb from "../../img/no_img.jpg";

const MediaArticle: React.FC<{ el: any }> = ({ el }) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(`/media/${el?.mnum}`);
  };
  return (
    <StMediaArticle.Container onClick={onClickHandler}>
      {el?.main_image? 
        <StMediaArticle.Thumbnail 
        dangerouslySetInnerHTML={{ __html: el?.main_image ||"" }}
        /> : 
        <StMediaArticle.Thumbnail>
          <img src={emptyThumb} alt=""/>
        </StMediaArticle.Thumbnail>
      }
      <StMediaArticle.Summary>{el?.title}</StMediaArticle.Summary>
    </StMediaArticle.Container>
  );
};

export default MediaArticle;

export const StMediaArticle = {
  Container: styled.div`
    border-radius: 20px;
    border: 1px solid var(--border);
    overflow: hidden;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: 0.3s;
    }
  `,
  Thumbnail: styled.div`
    width: 100%;
    padding-bottom: 56.25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--border);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      object-fit: cover;
      object-position: center;
    }
  `,
  Summary: styled.div`
    width: 100%;
    padding: 16px 20px;
    font-size: 1rem;
    color: var(--primary);
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  `,
};
