import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router";
import { styled } from "styled-components";
import Inquiry from "../../img/contact/coop/AdobeStock_442157068-scaled_tiny.jpg";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { InquiryAtom } from "../../recoil/InquiryAtom";
import { client } from "../../axios/axios";
import { StSelect } from "../../pages/aboutus/StAbout";
import { transferAtom } from "../../recoil/transferAtom";
import { transferLanguage_head } from "../SelectBox";
import { transferLanguage_contact } from "./InquiryPageFirst";

const InquiryPageThird = () => {
  const language = useRecoilValue(transferAtom);
  const data = useRecoilValue(InquiryAtom);

  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const resetInquiryAtom = useResetRecoilState(InquiryAtom);
  const sendHandler = async () => {
    const result = await client.post("/", { data });
    if (result.status === 200) {
      alert("문의가 완료되었습니다.");
      navigate("/contact/stepOne");
      resetInquiryAtom();
    }
  };
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const result =
    data?.businessType === "publicInstitution"
      ? transferLanguage_contact[language][11]
      : data?.businessType === "corporate body"
      ? transferLanguage_contact[language][14]
      : data?.businessType === "corporation"
      ? transferLanguage_contact[language][13]
      : data?.businessType === "individual"
      ? transferLanguage_contact[language][12]
      : "";

  return (
    <div>
      <StStepOne.TitleBgImg src={Inquiry}>
        <div className="title-area">
          <StStepOne.TitleText>{transferLanguage_head[language][3]}</StStepOne.TitleText>
        </div>
      </StStepOne.TitleBgImg>
      <div
        onClick={() => {
          setBigCategory(false);
        }}
      >
        <StSelect.SelectBox>
          <div className="inner">
            <StSelect.SelectOption>
              <StSelect.OptionValue
                onClick={(e: any) => {
                  e.stopPropagation();
                  setBigCategory((prev) => !prev);
                }}
              >
                {transferLanguage_head[language][3]}
              </StSelect.OptionValue>
              {bigCategory && (
                <StSelect.OptionBox>
                  <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                    {transferLanguage_head[language][0]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                    {transferLanguage_head[language][1]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                    {transferLanguage_head[language][2]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                    {transferLanguage_head[language][3]}
                  </StSelect.OptionOtherValue>
                </StSelect.OptionBox>
              )}
            </StSelect.SelectOption>
          </div>
        </StSelect.SelectBox>
        <StWholeDiv>
          <StSeqShapes>
            <StBar>
              <StCircleWrap seq="1">
                <StCircle bg="#0b4fd4;" />
                <StTxt>{transferLanguage_head[language][3]}</StTxt>
              </StCircleWrap>
              <StCircleWrap seq="2">
                <StCircle bg="#0b4fd4;" />
                <StTxt>{transferLanguage_contact[language][0]}</StTxt>
              </StCircleWrap>
              <StCircleWrap seq="3">
                <StCircle bg="#0b4fd4;" />
                <StTxt>{transferLanguage_contact[language][1]}</StTxt>
              </StCircleWrap>
            </StBar>
          </StSeqShapes>
          <StInfo>
            {transferLanguage_contact[language][2]}
            <StLightTxt>{transferLanguage_contact[language][3]}</StLightTxt>
          </StInfo>
          <StFinalPage>
            <StInputContainer>
              <StTitleDiv>
                {transferLanguage_head[language][3]}
                <StEditBtn onClick={() => navigate("/contact/stepOne")}>
                  {transferLanguage_contact[language][32]}
                </StEditBtn>
              </StTitleDiv>
              <StContentDiv>
                {transferLanguage_contact[language][4]} : {data?.interestedField}
                <br /> {transferLanguage_contact[language][6]}: {data?.detail}
                <br />
                {transferLanguage_contact[language][8]}: {data?.file}
                <br />
                {transferLanguage_contact[language][7]}:{" "}
                {data?.sample ? transferLanguage_contact[language][30] : transferLanguage_contact[language][31]}
              </StContentDiv>
            </StInputContainer>

            <StInputContainer>
              <StTitleDiv>
                {transferLanguage_contact[language][0]}
                <StEditBtn onClick={() => navigate("/contact/stepTwo")}>
                  {transferLanguage_contact[language][32]}
                </StEditBtn>
              </StTitleDiv>
              <StContentDiv>
                {result}
                <br /> {transferLanguage_contact[language][15]}: {data?.companyName}
                <br />
                {transferLanguage_contact[language][17]}: {data?.phoneNumber}
                <br />
                {transferLanguage_contact[language][18]}: {data?.email}
                <br />
                {transferLanguage_contact[language][19]}: {data?.homePageAddress} <br />
                {transferLanguage_contact[language][20]}: {data?.introduction}
              </StContentDiv>
            </StInputContainer>
          </StFinalPage>
          <StNextBtn type="submit" onClick={sendHandler}>
            {transferLanguage_contact[language][27]}
            <FontAwesomeIcon icon={solid("paper-plane")} />
          </StNextBtn>
        </StWholeDiv>
      </div>
    </div>
  );
};

export default InquiryPageThird;
const StStepOne = {
  TitleBgImg: styled.div<{ src: string }>`
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: center;

    > .title-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    > .title-area::before {
      content: "";
      width: 4px;
      height: 24px;
      border-radius: 999px;
      display: block;
      background-color: var(--highlight);
      margin-bottom: 8px;
    }
  `,
  TitleText: styled.div`
    font-weight: 700;
    font-size: 3rem;
    color: #fff;
    text-align: center;
  `,
};
const StWholeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 9.375rem;
`;
const StSeqShapes = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 113px;
`;
const StBar = styled.div`
  width: 600px;
  position: relative;
  border-top: 4px solid var(--border);
  height: 30px;

  @media screen and (max-width: 768px) {
    width: 65vw;
  }
`;
const StCircleWrap = styled.div<{ seq: string }>`
  width: 30px;
  height: 30px;
  position: absolute;
  left: ${({ seq }) => {
    switch (seq) {
      case "1":
        return "0%";
      case "2":
        return "50%";
      case "3":
        return "100%";
    }
  }};
  transform: translate(-50%, -50%);
`;
const StCircle = styled.div<{ bg: string }>`
  width: 30px;
  height: 30px;
  background-color: ${({ bg }) => bg};
  border: 1px solid #0b4fd4;
  border-radius: 50%;
`;
const StTxt = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25rem;
  color: var(--primary);
  font-weight: 500;
  white-space: nowrap;
`;
const StInfo = styled.div`
  margin: auto;
  padding-top: 5rem;
  font-size: 24px;
  color: var(--primary);
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  padding-bottom: 5rem;
`;
const StLightTxt = styled.div`
  font-weight: 400;
`;
const StInputContainer = styled.div`
  width: 100%;
  max-width: var(--inner-width-1440);
  padding: var(--inner-padding-1440);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
`;
const StNextBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  border: 0;
  background-color: var(--highlight);
  color: white;
  cursor: pointer;
  padding: 11px 2.5rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0 auto;
  margin-top: 5rem;

  > i,
  > svg {
    font-size: 1rem;
  }
`;
const StTitleDiv = styled.div`
  display: -webkit-flex;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #f1f1f1;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  padding: 20px 36px;
  font-weight: 700;
  font-size: 1.5rem;
`;
const StContentDiv = styled.div`
  border: solid 1px #f1f1f1;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  color: var(--primary);
  font-size: 1.125rem;
  display: flex;
  padding: 32px 36px;
`;
const StFinalPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;
const StEditBtn = styled.button`
  padding: 0;
  color: var(--highlight);
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 1.125rem;
  margin-left: 40px;

  &:focus {
    border: none;
  }
`;
