import React from "react";
import { StCommon } from "./Common";
import { styled } from "styled-components";
import { transferLanguageFresh } from "../../pages/product/technical/Fresh";
import { transferAtom } from "../../recoil/transferAtom";
import { useRecoilValue } from "recoil";

import img3 from "../../img/products/techapply/data5/56161.png";

import banana1 from "../../img/products/techapply/data5/banana1_kr.png";
import banana2 from "../../img/products/techapply/data5/banana2_kr.png";
import banana3 from "../../img/products/techapply/data5/banana3_kr.png";
import banana4 from "../../img/products/techapply/data5/banana4_kr.png";

import banana1_en from "../../img/products/techapply/data5/banana1_eng.png";
import banana2_en from "../../img/products/techapply/data5/banana2_eng.png";
import banana3_en from "../../img/products/techapply/data5/banana3_eng.png";
import banana4_en from "../../img/products/techapply/data5/banana4_eng.png";

import banana1_ind from "../../img/products/techapply/data5/banana1_ind.png";
import banana2_ind from "../../img/products/techapply/data5/banana2_ind.png";
import banana3_ind from "../../img/products/techapply/data5/banana3_ind.png";
import banana4_ind from "../../img/products/techapply/data5/banana4_ind.png";

import banana1_es from "../../img/products/techapply/data5/banana1_es.png";
import banana2_es from "../../img/products/techapply/data5/banana2_es.png";
import banana3_es from "../../img/products/techapply/data5/banana3_es.png";
import banana4_es from "../../img/products/techapply/data5/banana4_es.png";

const FreshSecond = () => {
  const language = useRecoilValue(transferAtom);
  return (
    <StCommon.Container>
      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguageFresh[language][11]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.DustBox mt="5rem">
          <StCommon.AirText style={{width: "100%", textAlign: "center"}}>{transferLanguageFresh[language][17]}</StCommon.AirText>
        </StCommon.DustBox>
        <StCommon.PyoBox>
          <StCommon.Tr gtc="6.25rem 1fr">
            <StCommon.Th bgcolor="#F1F1F1" bb="true" bt="true" btM="true" padding="16px 10px">
              {transferLanguageFresh[language][12]}
            </StCommon.Th>
            <StCommon.Td bgcolor="white" bb="true" bt="true" btM="true" jc="start">
             {transferLanguageFresh[language][13]}
            </StCommon.Td>
          </StCommon.Tr>
          <StCommon.Tr gtc="6.25rem 1fr">
            <StCommon.Th bgcolor="#F1F1F1" bb="true" padding="16px 10px">
              {transferLanguageFresh[language][14]}
            </StCommon.Th>
            <StCommon.Td bgcolor="white" bb="true" jc="start">
             {transferLanguageFresh[language][15]}
            </StCommon.Td>
          </StCommon.Tr>
          <StCommon.Tr gtc="6.25rem 1fr">
            <StCommon.Th bgcolor="#F1F1F1" bb="true" padding="16px 10px">
              {transferLanguageFresh[language][16]}
            </StCommon.Th>
            <StCommon.Td bgcolor="white" bb="true" jc="start">
             Intertek
            </StCommon.Td>
          </StCommon.Tr>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{/* 실제 적용 Test */}{transferLanguageFresh[language][18]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StFreshSecond.DustBox mt="5rem">
          <StCommon.AirText ta="center" style={{order: "2"}}>{transferLanguageFresh[language][22]}</StCommon.AirText>
          <StFreshSecond.BananaImgBoxWrap>
            <StFreshSecond.BananaImgBox>
              {language === 0 && <StFreshSecond.BananaImg src={banana1} alt="" />}
              {language === 1 && <StFreshSecond.BananaImg src={banana1_en} alt="" />}
              {language === 2 && <StFreshSecond.BananaImg src={banana1_ind} alt="" />}
              {language === 3 && <StFreshSecond.BananaImg src={banana1_es} alt="" />}
              
              {language === 0 && <StFreshSecond.BananaImg src={banana2} alt="" />}
              {language === 1 && <StFreshSecond.BananaImg src={banana2_en} alt="" />}
              {language === 2 && <StFreshSecond.BananaImg src={banana2_ind} alt="" />}
              {language === 3 && <StFreshSecond.BananaImg src={banana2_es} alt="" />}
              
              {language === 0 && <StFreshSecond.BananaImg src={banana3} alt="" />}
              {language === 1 && <StFreshSecond.BananaImg src={banana3_en} alt="" />}
              {language === 2 && <StFreshSecond.BananaImg src={banana3_ind} alt="" />}
              {language === 3 && <StFreshSecond.BananaImg src={banana3_es} alt="" />}
              
              {language === 0 && <StFreshSecond.BananaImg src={banana4} alt="" />}
              {language === 1 && <StFreshSecond.BananaImg src={banana4_en} alt="" />}
              {language === 2 && <StFreshSecond.BananaImg src={banana4_ind} alt="" />}
              {language === 3 && <StFreshSecond.BananaImg src={banana4_es} alt="" />}
            </StFreshSecond.BananaImgBox>
          </StFreshSecond.BananaImgBoxWrap>
        </StFreshSecond.DustBox>
      </StCommon.CoatingBox>
    </StCommon.Container>
  );
};

export default FreshSecond;

export const StFreshSecond = {
  ReportImg: styled.img`
    width: 360px;
    object-fit: contain;
    display: block;
    margin-left: auto;
    
    @media screen and (max-width:768px){
      margin: 0 auto;
    }
  `,
  DustBox: styled.div<{ gap?: string; mt?: string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${(props) => props.gap ?? "3.75rem 30px"};
    margin-top: ${(props) => props.mt ?? "0px"};

    > * {
      width: calc((100% - 30px) / 2);
    }

    @media screen and (max-width: 768px) {
      > * {
        width: 100%;
      }
    }
  `,
  BananaImgBoxWrap: styled.div<{ gap?: string; mt?: string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.gap ?? "30px"};
    margin-top: ${(props) => props.mt ?? "0px"};
    order: 1;

    >* {width: calc((100% - 30px)/2);}
    
    @media screen and (max-width:768px){
      flex-direction: column;

      >* {width: 100%;}
    }
  `,
  BananaImgBox: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;

    @media screen and (max-width:992px){
      flex-wrap: wrap;
    }
  `,
  BananaImg: styled.img`
    width: 23%;
    object-fit: cover;
    display: block;

    @media screen and (max-width:992px){
      width: 45%;
    }
  `,
  Explain: styled.p`
    font-weight: 300;
    font-size: 1.25rem;
    color: var(--primary);
    margin-top: 5rem;
  `,
};
