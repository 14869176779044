import React from "react";
import { StCommon } from "./Common";

import img4 from "../../img/products/techapply/data4/img4.png";
import { styled } from "styled-components";
import { transferAtom } from "../../recoil/transferAtom";
import { useRecoilValue } from "recoil";
import { transferLanguage_water } from "../../pages/product/technical/Water";

const WaterSecond = () => {
  const language = useRecoilValue(transferAtom);
  return (
    <StCommon.Container>
      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>
            {/* 총인 제거 성능 */}
            {transferLanguage_water[language][10]}
          </StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <StCommon.AirText ta="center">{transferLanguage_water[language][18]}</StCommon.AirText>
        </StCommon.AirBox>
        <StCommon.PyoBox>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="33%" flex="1 1 0" br="true">
              {transferLanguage_water[language][11]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="33%" flex="1 1 0" br="true">
              {transferLanguage_water[language][12]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="33%" flex="1 1 0">
              {transferLanguage_water[language][13]}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#f7f7f7" width="33%" flex="1 1 0" br="true">
              {transferLanguage_water[language][14]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#f7f7f7" width="33%" flex="1 1 0" br="true"></StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#f7f7f7" width="33%" flex="1 1 0">
              9.838
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" br="true">
              {transferLanguage_water[language][15]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0" br="true">
              {`60${transferLanguage_water[language][30]}`}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="33%" flex="1 1 0">
              6.437
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StWaterSecond.PyoRow columns="repeat(3, 1fr)">
            <StWaterSecond.PyoTitle
              bgcolor="rgba(76, 198, 215, 1)"
              bb="true"
              color="white"
              row="1 / span 4"
              style={{ borderRight: "1px solid #dfdfdf" }}
            >
              {transferLanguage_water[language][16]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white" br="true">
              {`10${transferLanguage_water[language][30]}`}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white">
              {`N.D(${transferLanguage_water[language][17]})`}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white" br="true">
              {`20${transferLanguage_water[language][30]}`}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white">
              {`N.D(${transferLanguage_water[language][17]})`}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white" br="true">
              {`30${transferLanguage_water[language][30]}`}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white">
              {`N.D(${transferLanguage_water[language][17]})`}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white" br="true">
              {`60${transferLanguage_water[language][30]}`}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" bb="true" color="white">
              {`N.D(${transferLanguage_water[language][17]})`}
            </StWaterSecond.PyoTitle>
          </StWaterSecond.PyoRow>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>
            {/* 유수분리 */}
            {transferLanguage_water[language][19]}
          </StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <StCommon.AirText>{transferLanguage_water[language][28]}</StCommon.AirText>
          <StCommon.AirImg src={img4} alt="유수분리 처리사진" />
        </StCommon.AirBox>
        <StCommon.PyoBox>
          <StWaterSecond.PyoRow columns="repeat(4, 1fr)">
            <StWaterSecond.PyoTitle bgcolor="#F1F1F1" br="true">
              {transferLanguage_water[language][11]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="#F1F1F1" br="true">
              {transferLanguage_water[language][20]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="#F1F1F1" br="true">
              {transferLanguage_water[language][21]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="#F1F1F1">
              {transferLanguage_water[language][22]}
            </StWaterSecond.PyoTitle>

            <StWaterSecond.PyoTitle bgcolor="#fff" br="true">
              {transferLanguage_water[language][14]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="#fff" br="true">
              {transferLanguage_water[language][23]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="#fff" br="true">
              {transferLanguage_water[language][24]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="#fff">
              {transferLanguage_water[language][25]}
            </StWaterSecond.PyoTitle>

            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" color="white" br="true">
              {transferLanguage_water[language][16]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" color="white" br="true">
              {transferLanguage_water[language][25]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" color="white" br="true">
              {transferLanguage_water[language][26]}
            </StWaterSecond.PyoTitle>
            <StWaterSecond.PyoTitle bgcolor="rgba(76, 198, 215, 1)" color="white">
              {transferLanguage_water[language][27]}
            </StWaterSecond.PyoTitle>
          </StWaterSecond.PyoRow>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          {/* 유수분리 Test 영상 */}
          <StCommon.Title>{transferLanguage_water[language][29]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StWaterSecond.VideoWrap>
          <StWaterSecond.VideoArea>
            {language === 0 && (
              <video
                controls
                width="1000"
                height="562.5"
                src="/assets/oilWaterVideo/oilWater_KO.mp4"
                typeof="video/mp4"
              />
            )}
            {language === 1 && (
              <video
                controls
                width="1000"
                height="562.5"
                src="/assets/oilWaterVideo/oilWater_EN.mp4"
                typeof="video/mp4"
              />
            )}
            {language === 2 && (
              <video
                controls
                width="1000"
                height="562.5"
                src="/assets/oilWaterVideo/oilWater_IN.mp4"
                typeof="video/mp4"
              />
            )}
            {language === 3 && (
              <video
                controls
                width="1000"
                height="562.5"
                src="/assets/oilWaterVideo/oilWater_SP.mp4"
                typeof="video/mp4"
              />
            )}
          </StWaterSecond.VideoArea>
        </StWaterSecond.VideoWrap>
      </StCommon.CoatingBox>
    </StCommon.Container>
  );
};

export default WaterSecond;

export const StWaterSecond = {
  Box1: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.75rem;
    p {
      width: 40%;
      padding: 0 2.5rem;
      font-weight: 600;
      font-size: 1.25rem;
    }
  `,
  Box2: styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 3.75rem;
  `,
  Box2Inner: styled.div`
    display: flex;
    width: 551px;
    flex-direction: column;
    gap: 30px;
    p {
      padding: 0 2.5rem;
      font-weight: 600;
      font-size: 1.25rem;
    }
  `,
  Img1: styled.img`
    width: 488px;
    height: 265px;
    object-fit: contain;
    display: block;
  `,
  Img2: styled.img`
    width: 424px;
    height: 265px;
    object-fit: contain;
    display: block;
  `,
  Img3: styled.img`
    width: 551px;
    height: 150px;
    object-fit: contain;
    display: block;
  `,
  PyoRow: styled.div<{ columns: string }>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
  `,
  PyoTitle: styled.div<{ bgcolor: string; bb?: string; color?: string; bt?: string; row?: string; br?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    border-bottom: ${(props) => (props.bb === "true" ? "1px solid #dfdfdf" : "")};
    border-top: ${(props) => (props.bt === "true" ? "1px solid #dfdfdf" : "")};
    border-right: ${(props) => (props.br === "true" ? "1px solid #dfdfdf" : "")};
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color ?? "var(--darkgray)"};
    grid-row: ${({ row }) => row ?? "unset"};
    text-align: center;
    
    @media screen and (max-width:480px){
      padding: 16px 10px;
    }
  `,
  VideoWrap: styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 5rem;
  `,
  VideoArea: styled.div`
    width: 100%;
    max-width: 1000px;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      display: block;
    }
  `,
};
