import React from "react";
import { useNavigate } from "react-router-dom";
import { StNoshine, StProduct } from "./StProduct";
import { StAbout, StArrow, StSelect } from "../aboutus/StAbout";

import img from "../../img/products/Redchec/test-tubes-g05cdb7e1e_1920.jpg";

import { transferAtom } from "../../recoil/transferAtom";
import { useRecoilValue } from "recoil";
import RedchecSecond from "../../components/product/RedchecSecond";
import { transferLanguage_head } from "../../components/SelectBox";
import { windowScrollTop } from "../../components/Footer";

export const transferLanguage_redchec = [
  [
    "Redchec®",
    "Redchec®는 광촉매의 성능에 대한 가시적인 검증을 하기위한 시약으로 광촉매의 적용",
    "감리 시 Redchec®를 이용하여 육안 관찰을 통해 적용 적합도 판정을 할 수 있습니다.",
    "제품 문의",
    "Redchec® 란?",
    "기존",
    "광촉매 적용/코팅",
    "육안 확인 불가능",
    "Redchec® 사용",
    "코팅여부 육안 확인 가능",
    "기존에는 광촉매를 적용하였을 때 육안으로 확인이 불가능하여 광촉매의 적용 여부를 확인하기 위해 복잡한 과정이 필요했지만 Redchec®는 간편하게 대상면에 스프레이 하여 적용여부를 확인할 수 있습니다.",
    "광촉매가 적용 된 곳에 Redchec®를 사용하면 적색의 지시약이 수초내에 무색으로 바뀌게 되어 광촉매 적용 여부를 확인할 수 있습니다.",
    "Redchec® 시연 영상",
  ],
  [
    "Redchec®",
    "Redchec® is a reagent for visual verification of the performance of photocatalysts,",
    "and when inspect the application of photocatalysts, the application suitability can be determined by visual observation using Redchec®",
    "Product inquiry",
    "What is Redchec®?",
    "Existing",
    "Photocatalyst apply/coating",
    "Unable to check visually",
    "Use Redchec®",
    "Can be visually checked for coating",
    "Previously, it was impossible to visually check when applicate photocatalyst, so a complicated process was needed to check whether photocatalyst is coated well or not, but Redchec® can easily spray on the target surface to check visually whether it is coated well or not",
    "With Redchec®, where photocatalyst is applied, the red reagent turns colorless within a few seconds, allowing you to check for application of photocatalyst.",
    "Redchec® Demonstration Video",
  ],
  [
    "Redchec®",
    "Redchec® adalah reagen untuk memverifikasi kinerja fotokatalis dan dapat menentukan kesesuaian aplikasi melalui",
    "pengamatan visual menggunakan Redchec® saat pengawasan penerapan fotokatalis.",
    "Pertanyaan produk",
    "Apa itu Redchec®?",
    "Keberadaan",
    "Penerapan/laminasi Fotokatalis",
    "Tidak mungkin untuk memeriksa secara visual",
    "Penerapan Redchec®",
    "Anda dapat memeriksa secara visual apakah itu dilapisi atau tidak.",
    "Sebelumnya, saat fotokatalis diterapkan, tidak mungkin untuk memverifikasi secara visual, sehingga proses yang rumit diperlukan untuk memverifikasi penerapan fotokatalis, namun Redchec® dapat dengan mudah disemprotkan ke permukaan target untuk memeriksa apakah itu diterapkan.",
    "Dengan Redchec® di mana fotokatalis berada, indikator merah akan berubah menjadi tidak berwarna dalam hitungan detik, sehingga Anda dapat melihat apakah fotokatalis diterapkan atau tidak.",
    "Redchec® video demonstrasi",
  ],
  [
    "Redchec®",
    "Redchec® es un reactivo para realizar una verificación visible del rendimiento del catalizador óptico,",
    "y al supervisar la aplicación del catalizador óptico, Redchec® puede determinar la idoneidad de la aplicación a través de la observación visualización.",
    "Consulta del product",
    "¿Qué es Redchec®",
    "lo existente",
    "Aplicación/recubrimiento de fotocatalizador",
    "Imposible de comprobar visualmente",
    "El uso de Redchec®",
    "Se puede verificar visualmente si está recubierto o no.",
    "En el pasado, cuando se aplicó el fotocatalítico, no era posible verificarlo visualmente, por lo que se necesitaba un proceso complejo para verificar la aplicación del fotocatalítico, Redchec® se puede rociar fácilmente sobre la superficie objetivo para confirmar la aplicación.",
    "Si se utiliza Redchec® donde se aplica el fotocatalítico, el indicador rojo se vuelve incoloro en cuestión de segundos, lo que permite comprobar si se aplica el fotocatalítico.",
    "Video de demostración de Redchec®",
  ],
];

const Redchec: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/product/${e.target.dataset.value}`);
  };
  const goContact = () => {
    navigate("/contact/stepOne");
    windowScrollTop();
  };
  return (
    <div
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/AdobeStock_286304472_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white">{transferLanguage_head[language][1]}</StAbout.Font20>
          <StAbout.FontBigBold color="white">{transferLanguage_redchec[language][0]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][1]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              Redchec®
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="noshine" onClick={detailClickHandler}>
                  {transferLanguage_head[language][4]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][5]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="redchec" onClick={detailClickHandler}>
                  Redchec®
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>

      <StProduct.Container>
        <StProduct.FlexCenter>
          <StAbout.FontBigBold>{transferLanguage_redchec[language][0]}</StAbout.FontBigBold>
        </StProduct.FlexCenter>
        <StProduct.Article>
          <StProduct.Gap60>
            <StAbout.Font20 ta="left">
              {transferLanguage_redchec[language][1]}
              <br />
              {transferLanguage_redchec[language][2]}
            </StAbout.Font20>
          </StProduct.Gap60>
          <StProduct.Img500 src={img} alt="사진" />
        </StProduct.Article>
        <StNoshine.Container bgcolor="white" pt="60px" pb="0px">
          <StArrow.Box type="button" bgcolor="#00AFC8" onClick={goContact}>
            {transferLanguage_redchec[language][3]} <div className="arrow"></div>
          </StArrow.Box>
        </StNoshine.Container>
      </StProduct.Container>
      <RedchecSecond />
    </div>
  );
};

export default Redchec;
