import styled from "styled-components";
import arrowWhite from "../../img/icon/arrow-right-long-white.png";
import arrowGray from "../../img/icon/arrow-right-long-gray.png";
export const StAbout = {
  MainImg: styled.div<{ src: string }>`
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: center;

    > .title-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    > .title-area::before {
      content: "";
      width: 4px;
      height: 24px;
      border-radius: 999px;
      display: block;
      background-color: var(--highlight);
      margin-bottom: 8px;
    }
  `,
  Font20: styled.p<{ ta?: string; color?: string }>`
    font-weight: 300;
    font-size: 1.25rem;
    color: ${({ color }) => color ?? "var(--primary)"};
    text-align: ${({ ta }) => ta ?? "left"};
    white-space: pre-line;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  Font24: styled.p<{ ta?: string; color?: string }>`
    font-family: "RIDIBatang";
    font-weight: 400;
    font-size: 1.5rem;
    color: ${({ color }) => color ?? "var(--primary)"};
    text-align: ${({ ta }) => ta ?? "left"};
    white-space: pre-line;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  FontBold: styled.p<{ ta?: string; color?: string }>`
    font-weight: 700;
    font-size: 1.5rem;
    color: ${({ color }) => color ?? "var(--primary)"};
    text-align: ${({ ta }) => ta ?? "left"};
    white-space: pre-line;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  FontMidium: styled.p<{ ta?: string; color?: string }>`
    font-weight: 500;
    font-size: 1.125rem;
    color: ${({ color }) => color ?? "var(--primary)"};
    text-align: ${({ ta }) => ta ?? "left"};
    white-space: pre-line;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  FontMidiumBold: styled.p<{ ta?: string; color?: string }>`
    font-weight: 700;
    font-size: 2rem;
    color: ${({ color }) => color ?? "var(--primary)"};
    text-align: ${({ ta }) => ta ?? "left"};
    white-space: pre-line;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  FontBigBold: styled.p<{ ta?: string; color?: string; inner?: string; }>`
    font-weight: 700;
    font-size: 3rem;
    color: ${({ color }) => color ?? "var(--darkgray)"};
    text-align: ${({ ta }) => ta ?? "left"};
    white-space: pre-line;
    padding: ${({ inner }) => inner ?? ""};

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  MB: styled.div<{ mb?: string }>`
    margin-bottom: ${({ mb }) => mb ?? "0px"};
  `,
};
export const StSelect = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  SelectBox: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    line-height: 1.2;

    > .inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    @media screen and (max-width: 992px) {
      > .inner {
        flex-wrap: wrap;
        padding: 0;
      }
    }
  `,
  SelectOption: styled.div`
    width: 330px;
    position: relative;
    color: var(--dark);

    @media screen and (max-width: 992px) {
      width: 50%;
      flex: 1 0 50%;
    }
  `,
  OptionValue: styled.div`
    width: calc(100% + 2px);
    margin: -1px;
    padding: 24px 40px;
    padding-right: 54px;
    font-weight: 600;
    font-size: 1.25rem;
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--border);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.715' height='8' viewBox='0 0 13.715 8'%3E%3Cpath id='select-down' d='M6.862,168a1.138,1.138,0,0,1-.808-.335L.34,161.951a1.143,1.143,0,0,1,1.616-1.616l4.906,4.908,4.907-4.907a1.143,1.143,0,1,1,1.616,1.616l-5.714,5.714A1.14,1.14,0,0,1,6.862,168Z' transform='translate(-0.005 -160)' fill='%23222222'/%3E%3C/svg%3E%0A"),
      linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center, center;
    background-size: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 992px) {
      padding: 16px 20px;
      padding-right: 54px;
    }

    @media screen and (max-width: 480px) {
      padding: 16px 10px;
      padding-right: 34px;
      background-position: calc(100% - 10px) center, center;
    }
  `,
  OptionBox: styled.div`
    width: calc(100% + 2px);
    margin: 0 -1px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid var(--border);
    z-index: 111;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  `,
  OptionOtherValue: styled.div`
    padding: 10px 2.5rem;
    font-weight: 600;
    font-size: 1.25rem;
    cursor: pointer;
    background-color: white;
    z-index: 20;

    &:first-child {
      padding-top: 24px;
    }
    &:last-child {
      padding-bottom: 24px;
    }

    @media screen and (max-width: 768px) {
      padding: 10px 20px;
    }
  `,
};
export const StBrand = {
  Container: styled.div`
    padding: 6.25rem 0 0;

    > .inner {
      display: -webkit-flex;
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }
    > .inner > * {
      flex: 0 1 50%;
    }

    @media screen and (max-width: 768px) {
      > .inner {
        flex-direction: column;
      }
    }
  `,
  Article: styled.article`
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    font-weight: 300;
    font-size: 1.25rem;
    white-space: pre-line;
  `,
  Img: styled.img`
    max-width: 500px;
    max-height: 500px;
    object-fit: cover;
    display: block;
    box-shadow: var(--box-shadow0014);
    border-radius: 20px;

    @media screen and (max-width: 768px) {
      max-width: 300px;
      max-height: 300px;
      margin: 0 auto;
      order: 1;
    }
  `,
  Logo: styled.img`
    height: 3.125rem;
    object-fit: contain;
    margin: 0 6px;
    vertical-align: baseline;
  `,
};
export const StRelationShip = {
  Combox: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  ImgBox: styled.div`
    width: 100%;
    height: 385px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: var(--box-shadow0014);
  `,
  LogoBox: styled.div`
    width: 258px;
    height: 139px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `,
  ComLogoMF: styled.img`
    width: 258px;
    height: 139px;
    object-fit: contain;
    display: block;
  `,
  ComLogoNex: styled.img`
    width: 258px;
    object-fit: contain;
    display: block;
  `,
  Img: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: all 1s ease;

    &:hover {
      transform: scale(1.1);
    }
  `,
  ImgStatic: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  `,
  Company: styled.div`
    width: 100%;
    max-width: var(--inner-width-1440);
    padding: var(--inner-padding-1440);
    padding-top: 6.25rem;
    padding-bottom: 9.375rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 100px;

    @media screen and (max-width: 1200px) {
      gap: 30px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
};
export const StPrice = {
  Container: styled.div`
    width: 100%;
    max-width: var(--inner-width-1440);
    padding: var(--inner-padding-1440);
    margin: 0 auto;
    height: 518px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  ComingImg: styled.img`
    width: 3.75rem;
    object-fit: contain;
    display: block;
  `,
  ComingText: styled.p`
    width: 176px;
    height: 27px;
    font-family: "GmarketSans";
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.2;
    color: var(--primary);
  `,
};
export const StEmpty = styled.div`
  height: var(--header-height);
`;
export const StArrow = {
  Box: styled.button<{ bgcolor?: string; border?: string; margin?: string }>`
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 11px 2.5rem;
    margin: ${({ margin }) => margin ?? "0 auto"};
    margin-top: 3.75rem;
    background-color: ${({ bgcolor }) => bgcolor};
    transition: all 300ms ease;
    border: ${({ border }) => border ?? "1px solid #00afc8"};
    border-radius: 4px;
    color: ${({ bgcolor }) => (bgcolor ? "white" : "#00afc8")};
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;

    img {
      margin-left: 16px;
      width: 16px;
      transition: all 300ms ease;
    }

    .arrow {
      position: relative;
      margin-left: 16px;
      width: 16px;
      height: 11px;
      transition: width var(--transition-duration-300) ease;
      background-image: url(${arrowWhite});
      background-repeat: no-repeat;
      background-position: right center;
    }
    .arrowGray {
      position: relative;
      margin-left: 16px;
      width: 16px;
      height: 11px;
      transition: width var(--transition-duration-300) ease;
      background-image: url(${arrowGray});
      background-repeat: no-repeat;
      background-position: right center;
    }
    &:hover {
      img {
        width: 46px;
      }

      .arrow {
        width: 46px;
      }
      .arrowGray {
        width: 46px;
      }
    }

    &:focus {
      border: ${({ border }) => border ?? "1px solid #00afc8"};
    }

    @media screen and (max-width: 768px) {
      margin: 0 auto;
      margin-top: 3.75rem;
    }
  `,
};
