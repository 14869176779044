import React from "react";
import { StAbout, StArrow, StSelect } from "../../aboutus/StAbout";
import arrow from "../../../img/icon/arrow-right-long-white.png";
import { StNoshine } from "../StProduct";
import { useNavigate } from "react-router-dom";
import { StMulti } from "./Multi";
import { StFresh } from "./Fresh";
import { styled } from "styled-components";
import AirSecond from "../../../components/product/AirSecond";

import img1 from "../../../img/products/techapply/data3/img1.jpg";
import img2 from "../../../img/products/techapply/data3/img2.jpg";
import check from "../../../img/products/techapply/data2/check-solid.svg";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../../recoil/transferAtom";
import { transferLanguage_head } from "../../../components/SelectBox";
import { windowScrollTop } from "../../../components/Footer";

export const transferLanguage_air = [
  [
    "상시 방역 & 공기질 개선 기술",
    "포름알데히드, VOCs등을 빠르게 분해·제거하여",
    "새집증후군, 새가구증후군이나 새차증후군을 예방하거나 바이러스 등의 감염을 예방할 수 있는 기술입니다.",
    "또한 일상 생활에서 지속적으로 발생 하는 음식물 냄새, 화장실 냄새 등 의 생활악취를 분해·제거하여 쾌적한 환경을 제공합니다.",
    "상시 방역 & 공기질 개선 기술 문의하기",
    "특징",
    "빛이 들지 않는 장소에서도 작용",
    "우수한 코팅력",
    "보장된 안전성",
    "용도",
    "건축 내장재 및 차량 내장재, 타일, 가구, 인테리어 소품, 시공 등",
    "실제 적용 성적", //11 :: AirSecond
    "시험 항목",
    "시험 결과",
    "시공 전",
    "WELTOUCH® 시공 후",
    "세종 정부청사에 행정지원센터에 WELTOUCH® 시공을 한 결과 HCHO, TVOC 과 같은 유해물질이 실내 공기질 관리기준에 비해 현저히 낮은 수치까지 하락하였습니다.",
    "VOCs 제거 성능",
    "시험편",
    "120분",
    "무처리",
    "처리",
    "저감율",
    "Toluene",
    "Styrene",
    "Formaldehyde",
    "자동차 부품 연구원에서 WELTOUCH®로 휘발성유기화합물(VOCs) 제거 Test를 진행한 결과 120분뒤 Styrene과 Formaldehyde가 미검출 되고 Toluene 또한 수치가 현저히 낮아졌습니다.",
    "실제 적용 성적", //27
    "구분",
    "부유 총 세균 평균",
    "단위 : 개",
    "WELTOUCH® 시공 전",
    "WELTOUCH® 시공 후",
    "식품공장",
    "어린이집",
    "단체급식소",
    "SYH(HACCP공장)할란실 부유 총 세균 경향",
    "부유 총 세균(개/5분)",
    "1월 2월 3월 4월 5월 6월 7월 8월 9월",
    "1회 시공으로 3개월 효과 지속",
    "한국의 대형 식품기업(F社)과 진행한 부유낙하균 제거 Test에서 뛰어난 효과 지속력으로 1회처리만으로 3개월 이상 지속되는 효과를 보였습니다.",
    "(3개월 이상의 측정 필요성이 없어 Test는 3개월까지만 진행됐습니다.)",
    "실제 적용 사례", //42
    "공공기관 기자실",
    "C병원 산부인과",
    "산부인과 내 수술실, 분만실, 신생아실, 인큐베이터실, 회복실 등 병원내 가장 민감한 공간에도 WELTOUCH®가 적용 되었습니다.",
    "G社 사옥",
    "C社호텔",
    "G社호텔",
    "‘환경올림픽’이라 불리는 국제환경회의 람사르총회본부에 WELTOUCH®가 적용 되었습니다.",
    "P社호텔", // 50
    "P社호텔 타워 증축현장에서 WELTOUCH®가 적용 되었습니다.",
    "실제 WELTOUCH® 시공 업체",
  ],
  [
    "Ordinary disease control & air quality improvement",
    "WELTOUCH® prevents sick house syndrome, sick furniture syndrome,",
    "or sick car syndrome, as well as infections, including viruses, by quickly decomposing and eliminating formaldehyde and VOCs.",
    "Moreover, WELTOUCH® provides a pleasant environment by decomposing and eliminating offensive odors in daily life, including odor from food or the toilet, which is continuously generated in daily life.",
    "Inquire about air quality improvement Technology",
    "Features",
    "Effective even in places without light",
    "Superior coating ability",
    "Secured safety",
    "Applications",
    "Including interior materials for buildings, vehicles, tiles, furniture, interior accessories, and construction materials",
    "Actual Application Report",
    "Test item",
    "Test results",
    "Before WELTOUCH® application",
    "After WELTOUCH® application",
    "In accordance with the application of WELTOUCH® in an Administrative Support Center in the Sejong Government Complex, harmful substances, including HCHO and TVOC were reduced to significantly lower levels compared to indoor air quality control standards.",
    "Elimination of VOCs",
    "Specimen",
    "120min",
    "Non treatment",
    "Treatment",
    "Reduction rate",
    "Toluene",
    "Styrene",
    "Formaldehyde",
    "In accordance with the test on WELTOUCH® eliminating VOCs (volatile organic compounds) in the Automobile Components Laboratory. Styrene and formaldehyde were not detected 120 minutes after application, and the toluene value was significantly reduced.",
    "Actual Application Report",
    "Classification",
    "Average of Total Floating Bacteria",
    "Unit : ea",
    "Before WELTOUCH® application",
    "After WELTOUCH® application",
    "Food plants",
    "Day care centers",
    "Meal service facilities",
    "SYH(HACCP plant) Total floating bacterias in egg-breaking room",
    "Total floating bacteria(ea/5min)",
    "Jan Feb Mar Apr May Jun July Agu Sep",
    "The effects last for 3months per application",
    "The test on eliminating floating and falling germs conducted with a big food manufacturer (F company) in Korea demonstrated that the superior effects lasted for over 3 months per application.",
    "(The test was conducted for 3 months because there was no reason to measure the effects after this time)",
    "Actual application cases", //42
    "Press room of a public organization",
    "Hospital C obstetrics and gynecology",
    "WELTOUCH® has been used in the most sensitive spaces in medical centers, including operating rooms, delivery rooms, newborn units, incubator units, and recovery rooms in the Dept. Obstetrics and Gynecology.",
    "Company G's office building",
    "Hotel C",
    "Hotel G",
    "WELTOUCH® was used in RAMSAR General Assembly Headquarters during an international environment convention called ‘Environment Olympics’.",
    "Hotel P",
    "WELTOUCH® was used in the expanded facilities of the Hotel P Tower.",
    "Company that actually construct WELTOUCH®",
  ],
  [
    "Teknologi pencegahan penyakit menular & peningkatan kualitas udara secara konstan",
    "Ini adalah teknologi yang dapat dengan cepat menguraikan dan menghilangkan formaldehida dan VOC untuk mencegah sindrom baru rumah, furnitur, atau mobil ,",
    "atau mencegah infeksi seperti virus.",
    "Selain itu, menyediakan lingkungan yang menyenangkan dengan menguraikan dan menghilangkan bau sehari-hari seperti bau makanan dan bau toilet yang terjadi terus menerus dalam kehidupan sehari-hari.",
    "Pertanyaan tentang Peningkatan kualitas udara",
    "Fitur",
    "Bekerja bahkan di tempat di mana tidak ada cahaya",
    "Kekuatan pelapisan yang sangat baik",
    "Keamanan terjamin",
    "Tujuan",
    "Bahan interior bangunan dan bahan interior kendaraan, ubin, furnitur, aksesori interior, konstruksi, dll",
    "Hasil aplikasi aktual",
    "Item tes",
    "Hasil tes",
    "Sebelum konstruksi",
    "Setelah konstruksi WELTOUCH®",
    "Sebagai hasil dari pembangunan WELTOUCH® di Kompleks Pemerintah Sejong, zat berbahaya seperti HCHO dan TVOC turun secara signifikan lebih rendah daripada standar manajemen kualitas udara dalam ruangan.",
    "Kinerja Penghapusan VOCs",
    "Spesifikasi Uji",
    "120 menit",
    "Tanpa pengolahan",
    "Pengolahan",
    "Tingkat Pengurangan",
    "Toluene",
    "Styrene",
    "Formaldehyde",
    "Setelah 120 menit, Styrene dan Formaldehyde tidak terdeteksi dan toluene juga secara signifikan lebih rendah sebagai hasil dari uji penghilangan VOC dengan WELTOUCH® di Korea Automotive Technology Institute.",
    "Hasil aplikasi aktual",
    "Klasifikasi",
    "Total bakteri di udara Rata-rata",
    "Unit : ea",
    "Sebelum konstruksi WELTOUCH®",
    "Setelah konstruksi WELTOUCH®",
    "Pabrik Makanan",
    "Pusat Penitipan anak",
    "Layanan makan kelompok",
    "Ruang pembelahan SYH (Pabrik HACCP) Tren total bakteri di udara ",
    "Total bakteri di udara (ea / 5 menit)",
    "Jan Feb Mar Apr May Jun July Agu Sep",
    "Tiga bulan efek dalam satu konstruksi.",
    "Dalam tes untuk menghilangkan bakteri di udara yang dilakukan dengan perusahaan makanan besar Korea (perusahaan F), efeknya bertahan selama lebih dari 3 bulan dengan efektivitas yang sangat baik.",
    "(Tes hanya bertahan hingga 3 bulan karena tidak perlu mengukur lebih dari 3 bulan.)",
    "Kasus aplikasi yang dilakukan", //42
    "ruang pers lembaga umum",
    "Dokter kandungan di rumah sakit C",
    "WELTOUCH® juga diterapkan pada ruang yang paling sensitif di rumah sakit, seperti ruang operasi, ruang bersalin, ruang neonatal, ruang inkubator, dan ruang pemulihan di obstetri dan ginekologi.",
    "Gedung perkantoran Company G",
    "Hotel C",
    "Hotel G",
    "Pembangunan Markas Besar Umum Ramsar dari Konferensi Internasional tentang Lingkungan",
    "Hotel P",
    "WELTOUCH® telah diterapkan di lokasi perluasan Hotel P Tower.",
    "Perusahaan yang dibangun WELTOUCH® yang sebenarnya",
  ],
  [
    "Tecnología de mejora constante de la calidad del aire y la cuarentena",
    "Es una tecnología que descompone y elimina rápidamente el formaldehído, los COV, etc.",
    "para prevenir el síndrome de la casa nueva, el síndrome del mueble nuevo o el síndrome del coche nuevo, o prevenir infecciones de virus.",
    "Además, proporciona un ambiente agradable al descomponer y eliminar olores como los de la comida y el olor que se producen constantemente en la vida diaria..",
    "Contacto",
    "Característica",
    "Funciona incluso en lugares donde no hay luz",
    "Excelente poder de recubrimiento",
    "Seguridad garantizada",
    "Uso",
    "Materiales de construcción e interiores de vehículos, azulejos, muebles, accesorios de interior, construcción, etc.",
    "Resultados de la aplicación real",
    "Elemento de prueba",
    "Resultado de prueba",
    "Antes de la obra",
    "Después de la obra con WELTOUCH®",
    "Como resultado de la obra con WELTOUCH® en el centro de apoyo administrativo del Complejo Gubernamental de Sejong, las sustancias nocivas como HCHO y TVOC fueron significativamente más bajas que los estándares de calidad del aire interior.",
    "Rendimiento de eliminación de VOCs",
    "Pieza de prueba",
    "120 minutos",
    "Sin tratamiento",
    "Tratamiento",
    "tasa de reducción",
    "Tolueno",
    "Styrene",
    "formaldehído",
    "Como resultado de realizar una prueba de eliminación de compuestos orgánicos volátiles (VOCs) con WELTOUCH® en el Instituto de Investigación de Partes de Automóviles, no se detectaron estireno ni formaldehído después de 120 minutos, y los niveles de tolueno también se redujeron significativamente.",
    "Prueba de aplicación real",
    "Clasificación",
    "Promedio de bacterias flotantes totales en el aire",
    "unidad : ea",
    "Antes de la obra",
    "Después de la obra con WELTOUCH®",
    "Fábrica de alimentos",
    "Guardería",
    "Centro de catering para grupo",
    "SYH (Planta HACCP) Tendencias bacterianas totales en el aire de lugar para huevo abierto",
    "Bacterias flotantes totales en el aire (unidades/5 minutos)",
    "Ene Feb Mar Abr May Jun Jul Ago Sep",
    "Duración de 3 meses con una sola aplicación",
    "En la prueba de eliminación de bacterias flotantes realizada con una gran empresa de alimentos coreana (Compañía F),",
    "muestra un efecto que dura más de 3 meses con un solo tratamiento con una excelente persistencia del efecto (no era necesario medir más de 3 meses, por lo que la prueba solo se realiza durante un máximo de 3 meses)",
    "Casos de aplicaciones reales", //42
    "sala de prensa de una institución pública",
    "Obstetricia y ginecología en el hospital C",
    "Se ha aplicado WELTOUCH® a los espacios más sensibles del hospital, como el quirófano, la sala de partos, la sala de recién nacido, la sala de incubadoras y la sala de recuperación en el departamento de obstetricia y ginecología.",
    "El edificio de la compañía G",
    "Hotel C",
    "Hotel G",
    "WELTOUCH® se aplicó a la sede de la Convención de Ramsar, una conferencia ambiental internacional denominada como “Olimpiadas Ambientales”.",
    "Hotel P",
    "WELTOUCH® se aplicó en la ampliación de la Torre del Hotel P.",
    "Empresa contratista real de WELTOUCH®",
  ],
];

const Air: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const [moredetailCategory, setMoreDetailCategory] = React.useState(false);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/product/${e.target.dataset.value}`);
  };
  const goContact = () => {
    navigate("/contact/stepOne");
    windowScrollTop();
  };
  return (
    <StMulti.Container
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
        setMoreDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/AdobeStock_220663994_2_tiny.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white"> {transferLanguage_head[language][1]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][5]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][1]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][5]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="noshine" onClick={detailClickHandler}>
                  {transferLanguage_head[language][4]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][5]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="redchec" onClick={detailClickHandler}>
                  Redchec®
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setMoreDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][8]}
            </StSelect.OptionValue>
            {moredetailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="technical/multi" onClick={detailClickHandler}>
                  {transferLanguage_head[language][6]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/fresh" onClick={detailClickHandler}>
                  {transferLanguage_head[language][7]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/air" onClick={detailClickHandler}>
                  {transferLanguage_head[language][8]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/water" onClick={detailClickHandler}>
                  {transferLanguage_head[language][9]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="technical/build" onClick={detailClickHandler}>
                  {transferLanguage_head[language][10]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <div className="inner">
        <StMulti.Title>{transferLanguage_air[language][0]}</StMulti.Title>
        <StMulti.TextBox>
          {transferLanguage_air[language][1]}
          <br />
          {transferLanguage_air[language][2]}
          <br />
          {transferLanguage_air[language][3]}
        </StMulti.TextBox>
        <StNoshine.Container bgcolor="white" pt="2.5rem">
          <StArrow.Box type="button" bgcolor="#00AFC8" onClick={goContact}>
            {transferLanguage_air[language][4]}
            <div className="arrow"></div>
          </StArrow.Box>
        </StNoshine.Container>

        <StMulti.BoxContainer>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_air[language][5]}</StMulti.Stitle>
            <StMulti.StextBox>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_air[language][6]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_air[language][7]}
              </StMulti.StextLine>
              <StMulti.StextLine>
                <img src={check} alt="체크" />
                {transferLanguage_air[language][8]}
              </StMulti.StextLine>
            </StMulti.StextBox>
          </StMulti.Box>
          <StMulti.Box>
            <StMulti.Stitle>{transferLanguage_air[language][9]}</StMulti.Stitle>
            <StMulti.StextBox>{transferLanguage_air[language][10]}</StMulti.StextBox>
          </StMulti.Box>
        </StMulti.BoxContainer>
      </div>
      <AirSecond />
    </StMulti.Container>
  );
};

export default Air;

export const StAir = {
  Img1: styled.img`
    width: 458px;
    height: 305px;
    object-fit: contain;
    display: block;
  `,
  Img2: styled.img`
    width: 518px;
    height: 305px;
    object-fit: contain;
    display: block;
  `,
};
