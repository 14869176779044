import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router";
import { styled } from "styled-components";
import Inquiry from "../../img/contact/coop/AdobeStock_442157068-scaled_tiny.jpg";
import { useRecoilState, useRecoilValue } from "recoil";
import { InquiryAtom } from "../../recoil/InquiryAtom";
import { StSelect } from "../../pages/aboutus/StAbout";
import { transferLanguage_head } from "../SelectBox";
import { transferAtom } from "../../recoil/transferAtom";

export const transferLanguage_contact = [
  [
    "신청인 정보",
    "검토 후 제출",
    "※ 샘플 적용 신청을 하시면 귀하의 제품에 샘플을 적용해 드립니다.",
    "(샘플 적용을 신청 하여 WELTOUCH®의 성능을 귀사의 제품에 적용해 직접 경험 해보세요.)",
    "관심 분야",
    "제목",
    "내용", //6
    "샘플적용 신청",
    "제안서 파일 첨부",
    "업체 정보",
    "구분", //10
    "공공기관",
    "개인",
    "기업",
    "법인",
    "회사(기관)명", //15
    "제안자명",
    "전화번호",
    "Email",
    "홈페이지 주소",
    "회사(기관) 소개서", //20
    "WELTOUCH®는 고객 상담을 위해 아래와 같이 개인정보를 수집·이용합니다.",
    "수집 항목: 이름, 이메일, 전화번호, 회사명",
    "수집 목적: 문의에 대한 상담",
    "보유 및 이용 기간: 상담 종료 시 까지",
    "개인정보 수집 및 이용 동의 [필수]",
    "*개인정보 수집 및 이용에 동의하지 않을 권리가 있으며 거부할 경우에는 상담이 불가능합니다.",
    "보내기",
    "다음",
    "첨부 파일",
    "신청",
    "미신청",
    "수정",
  ],
  [
    "Applicant Information",
    "Submit after Review",
    "※ When you request the sample coating service, WELTOUCH® will provide the coating service for your products.",
    "(Request the sample coating service and directly check the performance of WELTOUCH® technologies by applying the technologies to your products.)",
    "Interested Fields",
    "Subjects",
    "Contents",
    "Sample Application Request",
    "File Attachments",
    "Company information",
    "Classification",
    "Public Organization",
    "Individual",
    "Enterprise",
    "Corporate Body",
    "Company (Organization)",
    "Proposer Name",
    "TEL",
    "Email",
    "Website",
    "Company (Organization) Profile",
    "WELTOUCH® collects and uses personal information listed below for customer counseling.",
    "Information to collect: name, email, Tel, company name",
    "Purpose of collection of information: Counseling on inquiries",
    "Period to keep and use personal information: Until counseling is completed.",
    "I agree with the collection and use of personal information above.",
    "*You can reject the collection and use of personal information. In case of rejection of collection and use of personal information, we cannot continue counseling.",
    "send",
    "next",
    "Attached file",
    "Apply",
    "Not submitted",
    "Edit",
  ],
  [
    "Informasi Pemohon",
    "Kirim setelah Ditinjau",
    "※ Saat Anda meminta layanan pelapisan sampel, WEL TOUCH® akan menyediakan layanan pelapisan untuk produk Anda.",
    "(Permintaan layanan pelapisan sampel dan periksa langsung kinerja teknologi WEL TOUCH® dengan menerapkan teknologi ke produk Anda.)",
    "Bidang Peminatan",
    "Judul",
    "Isi",
    "Permohonan Contoh Pelapisan",
    "Lampiran Berkas Proposal",
    "Informasi Perusahaan",
    "Jenis",
    "Instansi Umum",
    "Perorangan",
    "Badan Usaha",
    "Korporasi",
    "Nama perusahaan (lembaga)",
    "Nama pengusul",
    "Nomor telepon",
    "Email",
    "Alamat beranda",
    "Pengenalan perusahaan (lembaga)",
    "WELTOUCH®mengumpulkan dan menggunakan informasi pribadi untuk konsultasi pelanggan sebagai berikut.",
    "Kumpulan data : Nama, email, Nomor telepon, Nama perusahaan",
    "Tujuan pengumpulan: Konsultasi tentang pertanyaan",
    "Periode Jangka Waktu dan Penggunaan: hingga akhir konsultasi",
    "Menyetujui pengumpulan dan penggunaan informasi pribadi di atas.",
    "*Anda berhak untuk tidak setuju dengan pengumpulan dan penggunaan informasi pribadi, dan jika anda menolak, konsultasi tidak dapat dilakukan.",
    "kirim",
    "next",
    "File terlampir",
    "Mendaftar",
    "Tidak mendaftar",
    "Ubah",
  ],
  [
    "Información del Solicitante",
    "Enviar después de la Revisión",
    "※ Si solicita una aplicación de muestra, aplicaremos la muestra a su producto.",
    "(Solicite una aplicación de muestra y experimente el rendimiento de WELTOUCH® directamente en su producto)",
    "Ámbito de Interés",
    "Título",
    "Contenidos",
    "Solicitud de Solicitud de Muestra",
    "Archivo de Propuesta Adjunto",
    "Plantilla:Ficha de empresa",
    "Clasificación",
    "Instituciones públicas",
    "Individuos",
    "Corporaciones",
    "Sociedades",
    "Nombre de la empresa (organización)",
    "Nombre del proponente",
    "Número de teléfono",
    "Correo electrónico",
    "Dirección de la página web",
    "Introducción de la empresa (organización)",
    "WELTOUCH® recopila y utiliza la siguiente información personal para la consulta del cliente.",
    "Elementos de recopilación: Nombre, correo electrónico, número de teléfono, nombre de la empresa",
    "Finalidad de la recopilación: Consulta para información",
    "Plazo de conservación y uso: Hasta el final de la consulta",
    "Acepto la recopilación y el uso de la información personal anterior.",
    "* Tiene derecho a no aceptar la recopilación y el uso de información personal, y si se niega, la consulta no es posible.",
    "Envío",
    "next",
    "Archivo adjunto",
    "Aplicar",
    "No enviado",
    "Editar",
  ],
];

const InquiryPageFirst: React.FC = () => {
  const [sample, setSample] = React.useState(false);
  const [inquiryAtom, setInquiryAtom] = useRecoilState(InquiryAtom);
  const language = useRecoilValue(transferAtom);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInquiryAtom((prev) => ({
      ...prev,
      [name]: value,
      sample: sample,
    }));
  };
  const saveHandler = () => {
    navigate("/contact/stepTwo");
  };
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);

  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };

  return (
    <div>
      <StStepOne.TitleBgImg src={Inquiry}>
        <div className="title-area">
          <StStepOne.TitleText>{transferLanguage_head[language][3]}</StStepOne.TitleText>
        </div>
      </StStepOne.TitleBgImg>
      <div
        onClick={() => {
          setBigCategory(false);
        }}
      >
        <StSelect.SelectBox>
          <div className="inner">
            <StSelect.SelectOption>
              <StSelect.OptionValue
                onClick={(e: any) => {
                  e.stopPropagation();
                  setBigCategory((prev) => !prev);
                }}
              >
                {transferLanguage_head[language][3]}
              </StSelect.OptionValue>
              {bigCategory && (
                <StSelect.OptionBox>
                  <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                    {transferLanguage_head[language][0]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                    {transferLanguage_head[language][1]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                    {transferLanguage_head[language][2]}
                  </StSelect.OptionOtherValue>
                  <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                    {transferLanguage_head[language][3]}
                  </StSelect.OptionOtherValue>
                </StSelect.OptionBox>
              )}
            </StSelect.SelectOption>
          </div>
        </StSelect.SelectBox>

        <StWholeDiv>
          <StSeqShapes>
            <StBar>
              <StCircleWrap seq="1">
                <StCircle bg="#0b4fd4;" />
                <StTxt>{transferLanguage_head[language][3]}</StTxt>
              </StCircleWrap>
              <StCircleWrap seq="2">
                <StCircle bg="white" />
                <StTxt>{transferLanguage_contact[language][0]}</StTxt>
              </StCircleWrap>
              <StCircleWrap seq="3">
                <StCircle bg="white" />
                <StTxt>{transferLanguage_contact[language][1]}</StTxt>
              </StCircleWrap>
            </StBar>
          </StSeqShapes>
          <StInfo>
            {transferLanguage_contact[language][2]}
            <StLightTxt>{transferLanguage_contact[language][3]}</StLightTxt>
          </StInfo>
          <StInputContainer>
            <StInterestField
              type="text"
              placeholder={transferLanguage_contact[language][4]}
              name="interestedField"
              value={inquiryAtom.interestedField}
              onChange={onChangeHandler}
            />
            <StDetail
              name="detail"
              placeholder={transferLanguage_contact[language][6]}
              value={inquiryAtom.detail}
              onChange={onChangeHandler}
            />

            <StFileArea>
              <StFileSelector
                type="file"
                name="file"
                id="file"
                placeholder={transferLanguage_contact[language][8]}
                value={inquiryAtom.file}
                onChange={onChangeHandler}
              />
              <StFilelabel htmlFor="file">Choose</StFilelabel>
              <StFileName
                type="text"
                id="file_name"
                placeholder={transferLanguage_contact[language][8]}
                value={inquiryAtom.file}
                readOnly
              />
            </StFileArea>

            <StAgreementInput type="checkbox" />
            {sample ? (
              <StChecked onClick={() => setSample(!sample)}>
                <FontAwesomeIcon icon={solid("square-check")} />
                {transferLanguage_contact[language][7]}
              </StChecked>
            ) : (
              <StUnChecked onClick={() => setSample(!sample)}>
                <FontAwesomeIcon icon={solid("square-check")} />
                {transferLanguage_contact[language][7]}
              </StUnChecked>
            )}

            <StNextBtn onClick={saveHandler} disabled={inquiryAtom.detail === ""}>
              {/* 내용이 비어있으면 다음버튼이 작동하지 않음 */}
              {transferLanguage_contact[language][28]}
              <FontAwesomeIcon icon={solid("angles-right")} />
            </StNextBtn>
          </StInputContainer>
        </StWholeDiv>
      </div>
    </div>
  );
};

export default InquiryPageFirst;
const StStepOne = {
  TitleBgImg: styled.div<{ src: string }>`
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: center;

    > .title-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    > .title-area::before {
      content: "";
      width: 4px;
      height: 24px;
      border-radius: 999px;
      display: block;
      background-color: var(--highlight);
      margin-bottom: 8px;
    }
  `,
  TitleText: styled.div`
    font-weight: 700;
    font-size: 3rem;
    color: #fff;
    text-align: center;
  `,
};
const StWholeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 9.375rem;
`;
const StSeqShapes = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 113px;
`;
const StBar = styled.div`
  width: 600px;
  position: relative;
  border-top: 4px solid var(--border);
  height: 30px;

  @media screen and (max-width: 768px) {
    width: 65vw;
  }
`;
const StCircleWrap = styled.div<{ seq: string }>`
  width: 30px;
  height: 30px;
  position: absolute;
  left: ${({ seq }) => {
    switch (seq) {
      case "1":
        return "0%";
      case "2":
        return "50%";
      case "3":
        return "100%";
    }
  }};
  transform: translate(-50%, -50%);
`;
const StCircle = styled.div<{ bg: string }>`
  width: 30px;
  height: 30px;
  background-color: ${({ bg }) => bg};
  border: 1px solid #0b4fd4;
  border-radius: 50%;
`;
const StTxt = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25rem;
  color: var(--primary);
  font-weight: 500;
  white-space: nowrap;
`;
const StInfo = styled.div`
  margin: auto;
  padding-top: 5rem;
  font-size: 24px;
  color: var(--primary);
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  padding-bottom: 5rem;
`;
const StLightTxt = styled.div`
  font-weight: 400;
`;
const StInterestField = styled.input`
  padding: 14px 0 14px 20px;
  border: var(--border-solid);
  height: 44px;
`;
const StInputContainer = styled.div`
  width: 100%;
  max-width: var(--inner-width-1440);
  padding: var(--inner-padding-1440);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  gap: 16px;
`;
const StDetail = styled.textarea`
  padding: 14px 0 14px 20px;
  border: var(--border-solid);
  height: 500px;
`;
const StFileArea = styled.div`
  width: 100%;
  display: -webkit-flex;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 16px;
`;
const StFileSelector = styled.input`
  padding: 14px 0 14px 20px;
  border: var(--border-solid);
  display: none;
`;
const StFilelabel = styled.label`
  min-width: 100px;
  height: 40px;
  padding: 10px 14px;
  background: var(--highlight);
  font-weight: 600;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  order: 2;
`;
const StFileName = styled.input`
  width: 100%;
  height: 40px;
  padding: 14px 0 14px 20px;
  background: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  order: 1;
`;
const StNextBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  border: 0;
  background-color: var(--highlight);
  color: white;
  cursor: pointer;
  padding: 11px 2.5rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0 auto;
  margin-top: 5rem;

  > i,
  > svg {
    font-size: 1rem;
  }
`;
const StAgreementInput = styled.input`
  display: none;
`;
const StChecked = styled.label`
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: var(--primary);
  cursor: pointer;

  > i,
  > svg {
    font-size: 1.125rem;
    color: var(--highlight);
  }
`;
const StUnChecked = styled.label`
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: var(--primary);
  cursor: pointer;

  > i,
  > svg {
    font-size: 1.125rem;
    color: #999999;
  }
`;
