import { styled } from "styled-components";

export const StProduct = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
  `,
  FlexCenter: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 5rem;
  `,
  Article: styled.article`
    width: 100%;
    max-width: var(--inner-width-1440);
    padding: var(--inner-padding-1440);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 30px;

    > * {
      width: calc((100% - 30px) / 2);
    }

    @media screen and (max-width: 768px) {
      align-items: center;
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  `,
  Img500: styled.img`
    width: 100%;
    max-width: 500px;
    object-fit: contain;
    display: block;
    border-radius: 20px;
    box-shadow: var(--box-shadow0014);
    margin-right: auto;

    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
  `,
  Gap60: styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  `,
};
export const StNoshine = {
  Container: styled.div<{ bgcolor: string; pt?: string; color?: string; pl?: string; pb?: string }>`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: ${({ pt }) => pt ?? "9.375rem"};
    padding-bottom: ${({ pb }) => pb ?? "9.375rem"};
    background-color: ${({ bgcolor }) => bgcolor};
    color: ${({ color }) => color};
    text-align: center;
  `,
  GenerationBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 76px;
    margin-top: 5rem;
    margin-bottom: 3.75rem;

    @media screen and (max-width: 992px) {
      gap: 30px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  Box: styled.div<{ bgcolor?: string; color?: string; width?: string }>`
    width: ${({ width }) => width};
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    color: ${({ color }) => color ?? "var(--primary)"};
    gap: 24px;
    background-color: ${({ bgcolor }) => bgcolor};
    box-shadow: var(--box-shadow0014);
    border-radius: 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  TextBox: styled.div`
    font-size: 1.125rem;
  `,
  Arrow: styled.span`
    font-size: 2.5rem;
    color: var(--primary);
    margin: auto;

    @media screen and (max-width: 768px) {
      transform: rotate(90deg);
    }
  `,
  GroupImg: styled.img`
    width: 100%;
    max-width: 998px;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    margin-top: 5rem;

    &.pc {display: block;}
    &.mobile {display: none;}

    @media screen and (max-width: 768px) {
      &.pc {display: none;}
      &.mobile {max-width: 311px; display: block;}
    }
  `,
  ContentArticle: styled.div`
    width: 100%;
    height: 400px;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      height: auto !important;
      flex-direction: column;
    }
  `,
  Explain: styled.div`
    width: 50%;
    height: 100%;
    padding: 60px 90px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 60px 36px;
      order: 2;
    }
  `,
  IconBox: styled.div`
    display: flex;
    gap: 20px;
    margin: 0 auto;
    img {
      width: 50px;
      height: 50px;
    }
  `,
  ImgBox: styled.div`
    width: 50%;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 300px;
    }
  `,
  ExplainImg: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  `,
};
