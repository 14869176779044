import React from "react";
import { useNavigate } from "react-router-dom";
import { StAbout, StRelationShip, StSelect } from "./StAbout";
import nexchem from "../../img/about/partner/NexCHEM.png";
import nexchemImg from "../../img/about/partner/AdobeStock_393656246_tiny.jpg";
import nexchemImg2 from "../../img/about/partner/istockphoto-994167852_tiny.jpg";
import mf2 from "../../img/about/partner/mfSquare.png";
import mf2Img from "../../img/about/partner/AdobeStock_375987102.jpg";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../recoil/transferAtom";
import { transferLanguage_head } from "../../components/SelectBox";

const transferLanguage = [
  [
    "넥스켐은 WELTOUCH®의 미래 기술을 책임지는 R&D 전문 회사입니다.",
    "우수한 연구자들이 항균, 항바이러스, 미세먼지 제거, VOCs 제거, 라돈제거, 방오 등의 연구에 매진하고 있습니다.",
    "가장 안정적이며 앞선 기술력을 통해 사람, 공간, 산업을 새롭게 연결할 WELTOUCH®의 미래를 준비하는 것이 넥스켐의 임무입니다.",
    "엠에프스퀘어(Multi functional filter)는 WELTOUCH®의 FILTER 전문 기업으로 분할되어 출범한 기업으로 21세기의 오염된 환경 및 인류의 문제를 해결할 수 있는 WELTOUCH®의 기술을 활용하여 휘발성 유기화합물, 바이러스, 박테리아, 각종 악취 및 미세먼지 제거 기능을 하는 D.O.P.A 필터를 생산, 공급하고 있습니다.",
    "저희 엠에프스퀘어는 앞으로도 다양한 소재의 필터 및 고기능성 필터를 꾸준히 연구․ 개발하여 소비자 신뢰도 제고를 위해 항상 노력하는 회사가 되겠습니다.",
  ],
  [
    "Nexchem is an R&D company responsible for WELTOUCH®'s future technology.",
    "Excellent researchers are focusing on research on antibacterial, antiviral, fine dust removal, VOCs removal, radon removal, and antisoil.",
    "Nexchem's mission is to prepare for the future of WELTOUCH®, which will newly connect people, space, and industry through the most stable and advanced technology.",
    "MF SQUARE(Multi functional filter) is a FILTER company of WELTOUCH® that produces and supplies WELTOUCH® coating filters that remove volatile organic compounds, viruses, bacteria, odors, and fine dust using WELTOUCH® technology to solve contaminated environments and human problems in the 21st century.",
    "We, MF SQUARE, will continue to research and develop filters of various materials and high-function filters to improve consumer confidence",
  ],
  [
    "Nexchem adalah perusahaan Riset dan Pengembangan (R & D) yang bertanggung jawab atas teknologi masa depan WELTOUCH®",
    "Peneliti yang sangat baik berfokus pada penelitian tentang antibakteri, antivirus, penghilangan debu halus, penghilangan VOC, penghilangan radon, dan antisoil.",
    "Misi Nexchem adalah mempersiapkan masa depan WELTOUCH®, yang akan menghubungkan manusia, ruang, dan industri baru melalui teknologi paling stabil dan canggih.",
    "MF Square(Filter Multifungsi) adalah perusahaan FILTER dari WELTOUCH® yang memproduksi dan memasok filter pelapis WELTOUCH® yang menghilangkan senyawa organik yang mudah menguap, virus, bakteri, bau, dan debu halus menggunakan teknologi WELTOUCH® untuk mengatasi lingkungan yang terkontaminasi dan masalah manusia di abad ke-21.",
    "Kami, MF Square, akan terus meneliti dan mengembangkan filter dari berbagai bahan dan filter yang berfungsi tinggi untuk meningkatkan kepercayaan konsumen.",
  ],
  [
    "Nexchem es una empresa de I+D responsable de la tecnología futura de WELTOUCH®.",
    "Excelentes investigadores están trabajando arduamente en la investigación sobre antibacterianos, antivirales, eliminación de polvo fino, eliminación de VOCs, eliminación de radón y antiincrustantes, etc.",
    "La misión de Nexchem es prepararse para el futuro de WELTOUCH®, que conecta a las personas, el espacio y la industria a través de la tecnología más estable y avanzada.",
    "MF SQUARE (filtro multifuncional) es una empresa que se lanzó como una empresa de filtros especializados de WELTOUCH® y utiliza la tecnología de WELTOUCH® que puede resolver los problemas humanos y ambientales contaminados del siglo XXI, y elimina compuestos orgánicos volátiles, virus y bacteria, produce y suministra filtros revestidos de WELTOUCH® que eliminan diversos olores y polvo fino.",
    "MF SQUARE continuará investigando y desarrollando filtros hechos de varios materiales y filtros de alta funcionalidad constantemente para convertirnos en una empresa que siempre se esfuerza por mejorar la fiabilidad del consumidor.",
  ],
];

const AboutRelationship: React.FC = () => {
  const language = useRecoilValue(transferAtom);
  const navigate = useNavigate();
  const [bigCategory, setBigCategory] = React.useState(false);
  const [detailCategory, setDetailCategory] = React.useState(false);
  const [img, setImg] = React.useState<string | undefined>(nexchemImg);
  const changeTimeout = React.useRef<any>(null);
  const clickHandler = (e: any) => {
    navigate(`/${e.target.dataset.value}`);
  };
  const detailClickHandler = (e: any) => {
    navigate(`/about/${e.target.dataset.value}`);
  };
  const changeImgHandler = () => {
    if (changeTimeout.current === null) {
      changeTimeout.current = setTimeout(() => {
        setImg((prev) => {
          if (prev === nexchemImg) {
            return nexchemImg2;
          } else {
            return nexchemImg;
          }
        });
        changeTimeout.current = null;
      }, 2000);
    }
  };

  return (
    <div
      onClick={() => {
        setBigCategory(false);
        setDetailCategory(false);
      }}
    >
      <StAbout.MainImg src="/assets/facade_.jpg">
        <div className="title-area">
          <StAbout.Font20 color="white">{transferLanguage_head[language][0]}</StAbout.Font20>
          <StAbout.FontBigBold color="white"> {transferLanguage_head[language][12]}</StAbout.FontBigBold>
        </div>
      </StAbout.MainImg>
      <StSelect.SelectBox>
        <div className="inner">
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setBigCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][0]}
            </StSelect.OptionValue>
            {bigCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="about/brand" onClick={clickHandler}>
                  {transferLanguage_head[language][0]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="product/noshine" onClick={clickHandler}>
                  {transferLanguage_head[language][1]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="media" onClick={clickHandler}>
                  {transferLanguage_head[language][2]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="contact/stepOne" onClick={clickHandler}>
                  {transferLanguage_head[language][3]}
                </StSelect.OptionOtherValue>
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
          <StSelect.SelectOption>
            <StSelect.OptionValue
              onClick={(e: any) => {
                e.stopPropagation();
                setDetailCategory((prev) => !prev);
              }}
            >
              {transferLanguage_head[language][12]}
            </StSelect.OptionValue>
            {detailCategory && (
              <StSelect.OptionBox>
                <StSelect.OptionOtherValue data-value="brand" onClick={detailClickHandler}>
                  {transferLanguage_head[language][11]}
                </StSelect.OptionOtherValue>
                <StSelect.OptionOtherValue data-value="relationship" onClick={detailClickHandler}>
                  {transferLanguage_head[language][12]}
                </StSelect.OptionOtherValue>
                {/* <StSelect.OptionOtherValue data-value="price" onClick={detailClickHandler}>
                  {transferLanguage_head[language][13]}
                </StSelect.OptionOtherValue> */}
              </StSelect.OptionBox>
            )}
          </StSelect.SelectOption>
        </div>
      </StSelect.SelectBox>
      <StRelationShip.Company>
        <StRelationShip.Combox>
          <StRelationShip.LogoBox>
            <StRelationShip.ComLogoNex src={nexchem} alt="nexchem" />
          </StRelationShip.LogoBox>
          <StRelationShip.ImgBox>
            <StRelationShip.Img src={img} alt="넥스켐" onMouseOver={changeImgHandler} />
          </StRelationShip.ImgBox>
          <StAbout.Font20 ta="center">{transferLanguage[language][0]}</StAbout.Font20>
          <StAbout.Font20 ta="center">{transferLanguage[language][1]}</StAbout.Font20>
          <StAbout.Font20 ta="center">{transferLanguage[language][2]}</StAbout.Font20>
        </StRelationShip.Combox>
        <StRelationShip.Combox>
          <StRelationShip.ComLogoMF src={mf2} alt="mf2" />
          <StRelationShip.ImgBox>
            <StRelationShip.ImgStatic src={mf2Img} alt="엠에프스퀘어" />
          </StRelationShip.ImgBox>
          <StAbout.Font20 ta="center">{transferLanguage[language][3]}</StAbout.Font20>
          <StAbout.Font20 ta="center">{transferLanguage[language][4]}</StAbout.Font20>
        </StRelationShip.Combox>
      </StRelationShip.Company>
    </div>
  );
};

export default AboutRelationship;
