import React from "react";
import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import FILTER1Ko from "../../img/products/techapply/data2/FILTER1_KR.png";
import FILTER1En from "../../img/products/techapply/data2/FILTER1_EN.png";
import FILTER1In from "../../img/products/techapply/data2/FILTER1_IND.png";
import FILTER1Sp from "../../img/products/techapply/data2/FILTER1_ES.png";
import FILTER2_KR from "../../img/products/techapply/data2/FILTER2_KR.png";
import FILTER2_EN from "../../img/products/techapply/data2/FILTER2_EN.png";
import FILTER2_IN from "../../img/products/techapply/data2/FILTER2_IND.png";
import FILTER2_SP from "../../img/products/techapply/data2/FILTER2_ES.png";
import FILTER3_KR from "../../img/products/techapply/data2/FILTER3_KR.png";
import FILTER3_EN from "../../img/products/techapply/data2/FILTER3_EN.png";
import FILTER3_IN from "../../img/products/techapply/data2/FILTER3_IND.png";
import FILTER3_SP from "../../img/products/techapply/data2/FILTER3_ES.png";
import before_KO from "../../img/products/techapply/data2/diagram_before_KR.png";
import before_EN from "../../img/products/techapply/data2/diagram_before_eng.png";
import before_IN from "../../img/products/techapply/data2/diagram_before_IND.png";
import before_SP from "../../img/products/techapply/data2/diagram_before_ES.png";

import after_KO from "../../img/products/techapply/data2/diagram_after_KR.png";
import after_EN from "../../img/products/techapply/data2/diagram_after_eng.png";
import after_IN from "../../img/products/techapply/data2/diagram_after_IND.png";
import after_SP from "../../img/products/techapply/data2/diagram_after_ES.png";

import bac_before from "../../img/products/techapply/data2/bacteria_before.png";
import bac_after from "../../img/products/techapply/data2/bacteria_after.png";
import chamber from "../../img/products/techapply/data2/chamber.png";
import report from "../../img/products/techapply/data2/test_report.png";
import Air from "../../img/products/techapply/data2/img_air.jpg";
import { transferLanguage_multi } from "../../pages/product/technical/Multi";
import { useRecoilValue } from "recoil";
import { transferAtom } from "../../recoil/transferAtom";

const Common = () => {
  const language = useRecoilValue(transferAtom);
  return (
    <StCommon.Container>
      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_multi[language][4]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        {language === 0 && <StCommon.CoatingImg src={FILTER1Ko} alt="FILTER1Ko" />}
        {language === 1 && <StCommon.CoatingImg src={FILTER1En} alt="FILTER1En" />}
        {language === 2 && <StCommon.CoatingImg src={FILTER1In} alt="FILTER1In" />}
        {language === 3 && <StCommon.CoatingImg src={FILTER1Sp} alt="FILTER1Sp" />}
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_multi[language][18]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.FilterImgBox>
          {language === 0 && (
            <>
              <StCommon.FilterImg src={FILTER2_KR} alt="FILTER2_KR" />
              <StCommon.FilterImg src={FILTER3_KR} alt="FILTER3_KR" />
            </>
          )}
          {language === 1 && (
            <>
              <StCommon.FilterImg src={FILTER2_EN} alt="FILTER2_EN" />
              <StCommon.FilterImg src={FILTER3_EN} alt="FILTER3_EN" />
            </>
          )}
          {language === 2 && (
            <>
              <StCommon.FilterImg src={FILTER2_IN} alt="FILTER2_IND" />
              <StCommon.FilterImg src={FILTER3_IN} alt="FILTER3_IN" />
            </>
          )}
          {language === 3 && (
            <>
              <StCommon.FilterImg src={FILTER2_SP} alt="FILTER2_SP" />{" "}
              <StCommon.FilterImg src={FILTER3_SP} alt="FILTER3_SP" />
            </>
          )}
        </StCommon.FilterImgBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_multi[language][37]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.AirBox>
          <StCommon.AirText>{transferLanguage_multi[language][38]}</StCommon.AirText>
          <StCommon.AirImg src={Air} alt="" />
        </StCommon.AirBox>
        <StCommon.PyoBox>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="25%" flex="1 1 0" br="true">
              {transferLanguage_multi[language][39]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="25%" flex="1 1 0">
              {transferLanguage_multi[language][40]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(204, 239, 244, 1)" width="50%" flex="2 1 0">
              {transferLanguage_multi[language][41]}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_multi[language][42]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              {+language === 0
                ? `100 ㎍/m³ ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 100 ㎍/m³`}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="50%" flex="2 1 0" bb="true" color="white">
              {+language === 0
                ? `40.1 ㎍/m³ ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 40.1 ㎍/m³`}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_multi[language][43]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              {+language === 0
                ? `100 ㎍/m³ ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 100 ㎍/m³`}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="50%" flex="2 1 0" bb="true" color="white">
              {+language === 0
                ? `36.0 ㎍/m³ ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 36.0 ㎍/m³`}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_multi[language][44]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              {+language === 0
                ? `100 ppm ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 100 ppm`}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="50%" flex="2 1 0" bb="true" color="white">
              {+language === 0
                ? `56.0 ppm ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 56.0 ppm`}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_multi[language][45]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              {+language === 0
                ? `800 CFU/m³ ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 800 CFU/m³`}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="50%" flex="2 1 0" bb="true" color="white">
              {+language === 0
                ? `38 CFU/m³ ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 38 CFU/m³`}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              {transferLanguage_multi[language][46]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              {+language === 0
                ? `10.0 ppm ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 10.0 ppm`}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(76, 198, 215, 1)" width="50%" flex="2 1 0" bb="true" color="white">
              {+language === 0
                ? `1.9 ppm ${transferLanguage_multi[language][47]}`
                : `${transferLanguage_multi[language][47]} 1.9 ppm`}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_multi[language][48]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.DustBox mt="5rem">
          <StCommon.AirText>{transferLanguage_multi[language][59]}</StCommon.AirText>
          <StCommon.DustImgBox gap="2.5rem">
            {language === 0 && <StCommon.DustImg src={before_KO} alt="before_KO" style={{ width: "206px" }} />}
            {language === 1 && <StCommon.DustImg src={before_EN} alt="before_EN" />}
            {language === 2 && <StCommon.DustImg src={before_IN} alt="before_IN" />}
            {language === 3 && <StCommon.DustImg src={before_SP} alt="before_SP" />}
            {language === 0 && <StCommon.DustImg src={after_KO} alt="after_KO" style={{ width: "206px" }} />}
            {language === 1 && <StCommon.DustImg src={after_EN} alt="after_EN" />}
            {language === 2 && <StCommon.DustImg src={after_IN} alt="after_IN" />}
            {language === 3 && <StCommon.DustImg src={after_SP} alt="after_SP" />}
          </StCommon.DustImgBox>
        </StCommon.DustBox>
        <StCommon.PyoBox>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="25%" flex="1 1 0" br="true">
              {transferLanguage_multi[language][55]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="25%" flex="1 1 0" br="true">
              {transferLanguage_multi[language][51]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#F1F1F1" width="25%" flex="1 1 0">
              {transferLanguage_multi[language][57]}
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="rgba(204, 239, 244, 1)" width="25%" flex="1 1 0">
              {transferLanguage_multi[language][58]}
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              0.3 μm
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              22
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              7
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#4CC6D7" width="25%" flex="1 1 0" bb="true" color="white">
              68.18%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              0.5 μm
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              218
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              20
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#4CC6D7" width="25%" flex="1 1 0" bb="true" color="white">
              90.83%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              1.0 μm
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              3,120
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              59
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#4CC6D7" width="25%" flex="1 1 0" bb="true" color="white">
              98.11%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              2.5 μm
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              29,917
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              56
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#4CC6D7" width="25%" flex="1 1 0" bb="true" color="white">
              99.81%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              5.0 μm
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              10,279
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              16
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#4CC6D7" width="25%" flex="1 1 0" bb="true" color="white">
              99.84%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
          <StCommon.PyoRow>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              10.0 μm
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true" br="true">
              94,606
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#fff" width="25%" flex="1 1 0" bb="true">
              73
            </StCommon.PyoTitle>
            <StCommon.PyoTitle bgcolor="#4CC6D7" width="25%" flex="1 1 0" bb="true" color="white">
              99.92%
            </StCommon.PyoTitle>
          </StCommon.PyoRow>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_multi[language][60]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.DustBox mt="5rem">
          <StCommon.AirText>{transferLanguage_multi[language][67]}</StCommon.AirText>
          <StCommon.DustImgBox gap="60px">
            <StCommon.BacImgBox>
              <StCommon.BacImgBox>
                <StCommon.BacImg src={bac_before} alt="" />
                <span>&lt;{transferLanguage_multi[language][56]}&gt;</span>
              </StCommon.BacImgBox>
              <StCommon.BacImgBox>
                <StCommon.BacImg src={bac_after} alt="" />
                <span>&lt;{transferLanguage_multi[language][57]}&gt;</span>
              </StCommon.BacImgBox>
            </StCommon.BacImgBox>
            <StCommon.BacImgBox>
              <StCommon.BacImg src={chamber} alt=""></StCommon.BacImg>
              <span>&lt;{transferLanguage_multi[language][61]}&gt;</span>
            </StCommon.BacImgBox>
          </StCommon.DustImgBox>
        </StCommon.DustBox>
        <StCommon.CircleContainer>
          <StCommon.CircleBox>
            <StCommon.Circle language={language}>
              <StCommon.CircleTitle>99.9%</StCommon.CircleTitle>
              {transferLanguage_multi[language][63]}
            </StCommon.Circle>
            <StCommon.CircleText>
              <StCommon.CircleTextTitle>{transferLanguage_multi[language][62]}</StCommon.CircleTextTitle>
              <StCommon.CircleTextRow>
                <StCommon.SSContainer>
                  <StCommon.SSBox>1.0 × 10⁴</StCommon.SSBox>
                  <StCommon.CircleTextRowS>
                    {transferLanguage_multi[language][64]}
                    <br />
                    (CF U/ml)
                  </StCommon.CircleTextRowS>
                </StCommon.SSContainer>
                <FontAwesomeIcon icon={solid("right-long")} />
                <div>
                  <StCommon.SSBox>&lt;10</StCommon.SSBox>
                  <StCommon.CircleTextRowS>
                    {transferLanguage_multi[language][65]}
                    <br />
                    (CF U/ml)
                  </StCommon.CircleTextRowS>
                </div>
              </StCommon.CircleTextRow>
            </StCommon.CircleText>
          </StCommon.CircleBox>
          <StCommon.CircleBox>
            <StCommon.Circle language={language}>
              <StCommon.CircleTitle>99.9%</StCommon.CircleTitle>
              {transferLanguage_multi[language][63]}
            </StCommon.Circle>
            <StCommon.CircleText>
              <StCommon.CircleTextTitle>{transferLanguage_multi[language][66]}</StCommon.CircleTextTitle>
              <StCommon.CircleTextRow>
                <StCommon.SSContainer>
                  <StCommon.SSBox>1.0 × 10⁴</StCommon.SSBox>
                  <StCommon.CircleTextRowS>
                    {transferLanguage_multi[language][64]}
                    <br />
                    (CF U/ml)
                  </StCommon.CircleTextRowS>
                </StCommon.SSContainer>
                <FontAwesomeIcon icon={solid("right-long")} />
                <div>
                  <StCommon.SSBox>&lt;10</StCommon.SSBox>
                  <StCommon.CircleTextRowS>
                    {transferLanguage_multi[language][65]}
                    <br />
                    (CF U/ml)
                  </StCommon.CircleTextRowS>
                </div>
              </StCommon.CircleTextRow>
            </StCommon.CircleText>
          </StCommon.CircleBox>
        </StCommon.CircleContainer>
      </StCommon.CoatingBox>

      <StCommon.CoatingBox>
        <StCommon.LineBox>
          <StCommon.Title>{transferLanguage_multi[language][68]}</StCommon.Title>
          <StCommon.Liney />
        </StCommon.LineBox>
        <StCommon.DustBox mt="5rem">
          <StCommon.AirText style={{width: "100%", textAlign: "center"}}>{transferLanguage_multi[language][80]}</StCommon.AirText>
        </StCommon.DustBox>
        <StCommon.PyoBox>
          <StCommon.Tr>
            <StCommon.Th bgcolor="#F1F1F1" bb="true" bt="true" btM="true" padding="16px 10px">
              {transferLanguage_multi[language][69]}
            </StCommon.Th>
            <StCommon.Td bgcolor="white" bb="true" bt="true" btM="true" jc="start">
              {transferLanguage_multi[language][70]}
            </StCommon.Td>
            <StCommon.Th bgcolor="#F1F1F1" bb="true" bt="true" padding="16px 10px">
              {transferLanguage_multi[language][71]}
            </StCommon.Th>
            <StCommon.Td bgcolor="white" bb="true" bt="true" jc="start">
              {transferLanguage_multi[language][72]}
            </StCommon.Td>
          </StCommon.Tr>
          <StCommon.Tr>
            <StCommon.Th 
              bgcolor="#F1F1F1" 
              bb="true" 
              db="true" 
              padding="16px 10px" 
              gc="1 / span 1" 
              gr="1 / span 2" 
              gcM="1 / span 1" 
              grM="1 / span 2"
            >
              {transferLanguage_multi[language][73]}
            </StCommon.Th>
            <StCommon.Td 
              bgcolor="white" 
              bb="true" 
              jc="start" 
              gc="2 / span 1" 
              gr="1 / span 1"
              gcM="2 / span 1" 
              grM="1 / span 1"
            >
              {transferLanguage_multi[language][74]} &nbsp;
              <StCommon.Tint> {transferLanguage_multi[language][75]}</StCommon.Tint>
            </StCommon.Td>
            <StCommon.Td 
              bgcolor="white" 
              bb="true" 
              jc="start" 
              gc="2 / span 1" 
              gr="2 / span 1"
              gcM="2 / span 1" 
              grM="2 / span 1"
            >
              {transferLanguage_multi[language][76]} &nbsp;
              <StCommon.Tint> {transferLanguage_multi[language][77]}</StCommon.Tint>
            </StCommon.Td>
            <StCommon.Th 
              bgcolor="#F1F1F1" 
              bb="true" 
              db="true" 
              padding="16px 10px" 
              gc="3 / span 1" 
              gr="1 / span 2"
              gcM="1 / span 1" 
              grM="3 / span 1"
            >
              {transferLanguage_multi[language][78]}
            </StCommon.Th>
            <StCommon.Td 
              bgcolor="white" 
              bb="true" 
              db="true" 
              jc="start" 
              gc="4 / span 1" 
              gr="1 / span 2"
              gcM="2 / span 1" 
              grM="3 / span 1"
            >
              {transferLanguage_multi[language][79]}
            </StCommon.Td>
          </StCommon.Tr>
        </StCommon.PyoBox>
      </StCommon.CoatingBox>
    </StCommon.Container>
  );
};

export default Common;
export const StCommon = {
  Container: styled.div`
    width: 100%;
    max-width: var(--inner-width-1440);
    padding: var(--inner-padding-1440);
    margin: 0 auto;
  `,
  Title: styled.span`
    padding: 0 100px;
    font-weight: 700;
    font-size: 3rem;
    color: var(--darkgray);
    text-align: center;
    background-color: white;

    @media screen and (max-width: 768px) {
      padding: 0 60px;
    }
  `,
  Liney: styled.div`
    width: 100vw;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    z-index: -20;
    background-image: linear-gradient(to right, #999999 53.85%, rgba(255, 255, 255, 0) 46.15%);
    background-position: bottom;
    background-size: 26px 2px;
    background-repeat: repeat-x;
  `,
  CoatingBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  CoatingImg: styled.img`
    width: 100%;
    max-width: 600px;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    margin-top: 5rem;
  `,
  FilterImgBox: styled.div`
    display: flex;
    gap: 30px;
    margin-top: 5rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  FilterImg: styled.img`
    width: 100%;
    max-width: 500px;
    height: 286px;
    object-fit: contain;
    display: block;
  `,
  AirBox: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 5rem;

    > * {
      width: calc((100% - 30px) / 2);
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  `,
  AirText: styled.p<{ ta?: string }>`
    font-weight: 300;
    font-size: 1.25rem;
    color: var(--primary);
    text-align: ${({ ta }) => ta ?? "left"};

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  AirImg: styled.img`
    width: 100%;
    max-width: 500px;
    object-fit: cover;
    display: block;
    border-radius: 20px;
    box-shadow: var(--box-shadow0014);
    margin-left: auto;

    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
  `,
  PyoBox: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    line-height: 1.2;
    margin-top: 3.75rem;

    > .caption {
      font-weight: 500;
      text-align: right;
      margin-left: auto;
    }
  `,
  PyoRow: styled.div`
    width: 100%;
    display: flex;

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  `,
  PyoTitle: styled.div<{
    bgcolor: string;
    width: string;
    bb?: string;
    color?: string;
    bt?: string;
    db?: string;
    flex: string;
    jc?: string;
    padding?: string;
    br?: string;
  }>`
    display: flex;
    justify-content: ${({ jc }) => jc ?? "center"};
    align-items: center;
    width: ${(props) => props.width};
    height: ${({ db }) => (db === "true" ? "100px" : "50px")};
    padding: ${({ padding }) => padding ?? "16px 20px"};
    border-bottom: ${(props) => (props.bb === "true" ? "1px solid #dfdfdf" : "")};
    border-top: ${(props) => (props.bt === "true" ? "1px solid #dfdfdf" : "")};
    border-right: ${(props) => (props.br === "true" ? "1px solid #dfdfdf" : "")};
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color ?? "var(--darkgray)"};
    flex: ${({ flex }) => flex};
    text-align: center;

    @media screen and (max-width: 992px) {
      height: auto;
    }
  `,
  Tr: styled.div<{gtc?: string;}>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ gtc }) => gtc ?? "repeat(2, 6.25rem 1fr)"};

    @media screen and (max-width: 480px) {
      grid-template-columns: 6.25rem 1fr;
    }
  `,
  Th: styled.div<{
    bgcolor: string;
    bb?: string;
    color?: string;
    bt?: string;
    btM?: string;
    db?: string;
    jc?: string;
    padding?: string;
    br?: string;
    gc?: string;
    gcM?: string;
    gr?: string;
    grM?: string;
  }>`
    display: flex;
    justify-content: ${({ jc }) => jc ?? "center"};
    align-items: center;
    height: ${({ db }) => (db === "true" ? "100px" : "50px")};
    padding: ${({ padding }) => padding ?? "16px 20px"};
    border-bottom: ${(props) => (props.bb === "true" ? "1px solid #dfdfdf" : "")};
    border-right: ${(props) => (props.br === "true" ? "1px solid #dfdfdf" : "")};
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color ?? "var(--darkgray)"};
    text-align: center;

    @media screen and (max-width: 992px) {
      height: auto;
    }

    @media screen and (min-width:481px){
      grid-column: ${({ gc }) => gc ?? ""};
      grid-row: ${({ gr }) => gr ?? ""};
      border-top: ${(props) => (props.bt === "true" ? "1px solid #dfdfdf" : "")};
    }

    @media screen and (max-width: 480px) {
      grid-column: ${({ gcM }) => gcM ?? ""};
      grid-row: ${({ grM }) => grM ?? ""};
      border-top: ${(props) => (props.btM === "true" ? "1px solid #dfdfdf" : "")};
    }
  `,
  Td: styled.div<{
    bgcolor: string;
    bb?: string;
    color?: string;
    bt?: string;
    btM?: string;
    db?: string;
    jc?: string;
    padding?: string;
    br?: string;
    gc?: string;
    gcM?: string;
    gr?: string;
    grM?: string;
  }>`
    display: flex;
    justify-content: ${({ jc }) => jc ?? "center"};
    align-items: center;
    height: ${({ db }) => (db === "true" ? "100px" : "50px")};
    padding: ${({ padding }) => padding ?? "16px 20px"};
    border-bottom: ${(props) => (props.bb === "true" ? "1px solid #dfdfdf" : "")};
    border-right: ${(props) => (props.br === "true" ? "1px solid #dfdfdf" : "")};
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color ?? "var(--darkgray)"};
    text-align: left;

    @media screen and (max-width: 992px) {
      height: auto;
    }
    
    @media screen and (min-width:481px){
      grid-column: ${({ gc }) => gc ?? ""};
      grid-row: ${({ gr }) => gr ?? ""};
      border-top: ${(props) => (props.bt === "true" ? "1px solid #dfdfdf" : "")};
    }
    
    @media screen and (max-width: 480px) {
      grid-column: ${({ gcM }) => gcM ?? ""};
      grid-row: ${({ grM }) => grM ?? ""};
      border-top: ${(props) => (props.btM === "true" ? "1px solid #dfdfdf" : "")};
    }
  `,
  DustBox: styled.div<{ gap?: string; mt?: string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.gap ?? "30px"};
    margin-top: ${(props) => props.mt ?? "0px"};

    > * {
      width: calc((100% - 30px) / 2);
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  `,
  DustImgBox: styled.div<{ gap?: string }>`
    max-width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: ${(props) => props.gap ?? "30px"};
    margin-left: auto;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 0 auto;
    }
  `,
  DustImg: styled.img`
    width: 284px;
    object-fit: cover;
    display: block;
  `,
  BacImgBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    > span {
      font-weight: 500;
      font-size: 1.125rem;
      text-align: center;
      white-space: nowrap;
    }
  `,
  BacImg: styled.img`
    width: 200px;
    height: 200px;
    border-radius: 20px;
    object-fit: cover;
    display: block;
    box-shadow: var(--box-shadow0014);
  `,
  Circle: styled.div<{ language?: number }>`
    width: ${({language})=> (language === 0 ? "10rem" : language === 1 ? "11rem" : language === 2 ? "11rem" : "11rem")};
    height: ${({language})=> (language === 0 ? "10rem" : language === 1 ? "11rem" : language === 2 ? "11rem" : "11rem")};
    border-radius: 50%;
    border: 6px solid #00afc8;
    padding: 18px;
    text-align: center;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: ${({ language }) => (language === 3 ? "0.9rem" : "1.125rem")};
    line-height: 1.2;
  `,
  Empty60: styled.div`
    height: 3.75rem;
  `,
  CircleTitle: styled.p`
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--darkgray);
    margin-bottom: 6px;
  `,
  CircleContainer: styled.div`
    display: flex;
    gap: 40px 100px;

    @media screen and (max-width: 1200px) {
      gap: 40px 30px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  CircleBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    margin-top: 3.75rem;
      
    @media screen and (max-width:480px){
      flex-direction: column;
    }
  `,
  CircleTextTitle: styled.p`
    background-color: var(--highlight);
    padding: 6px 12px;
    border-radius: 4px;
    color: white;
  `,
  CircleText: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    font-weight: 700;
    font-size: 1.5rem;
  `,
  CircleTextRow: styled.div`
    font-weight: 300;
    font-size: 1.5rem;
    letter-spacing: 0px;
    color: var(--primary);
    display: flex;
    align-items: center;
    gap: 24px;

    > i,
    > svg {
      font-size: 2.5rem;
      color: var(--darkgray);
    }
  `,
  SSContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  SSBox: styled.span`
    text-align: center;
  `,
  CircleTextRowS: styled.span`
    font-size: 1.125rem;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  CircleTextRowSS: styled.span`
    font-size: 0.75rem;
  `,
  LineBox: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 9.375rem;
  `,
  ReportImg: styled.img`
    width: 100%;
    max-width: 360px;
    object-fit: contain;
    display: block;
    margin-left: auto;

    @media screen and (max-width: 768px) {
      max-width: 300px;
      margin: 0 auto;
    }
  `,
  PyoFlexColumn: styled.div`
    display: flex;
    flex-direction: column;
    width: calc((100% - 12.5rem) / 2);

    @media screen and (max-width: 480px) {
      width: calc(100% - 6.25rem);
    }
  `,
  Tint: styled.span`
    color: var(--highlight);
  `,
};
